{
  "register": "Save",
  "logout": "Logout",
  "signin": "Sign in",
  "owner-header": "Owner area",
  "partner": "Become partner",
  "partner-link-title": "Become partner (New Window)",
  "identity-card": "Identity Card",
  "passport": "Passport",
  "permit": "French Residence Permit",
  "drivers-license": "Driver's license",
  "france-identite": "France Identité",
  "other": "Other",
  "cdi": "Permanent Contract",
  "cdi-trial": "Permanent Contract (Probation Period)",
  "cdd": "Fixed-term Contract",
  "alternation": "Apprenticeship",
  "internship": "Internship",
  "student": "Studies",
  "public": "Public Sector",
  "ctt": "Temporary Work Contract",
  "retired": "Retirement",
  "unemployed": "Unemployment",
  "independent": "Self-employed",
  "intermittent": "Temporary worker",
  "stay-at-home-parent": "Housewife/Houseman",
  "no-activity": "Workless",
  "artist": "Artist-writer",
  "my-name": "You have a tax notice in your name",
  "my-parents": "You are financially dependent on your parents",
  "less-than-year": "You have been in France for less than a year",
  "other-tax": "Other",
  "visale": "Visale guarantee",
  "other-guarantee": "Other guarantee",
  "save-success": "Save successful",
  "save-failed": "Error while saving",
  "save-failed-num-pages": "Maximum number of pages reached, please delete some",
  "delete-success": "Deletion successful",
  "delete-failed": "Error while deleting",
  "max-file-default": "Maximum number of files reached, please delete some",
  "max-file": "Maximum number of files reached (currently: {0} / maximum: {1}), please delete some",
  "error": "An error occurred",
  "unable-to-copy": "unable to copy",
  "strength-not-valid": "The password is too weak",
  "confirm-not-valid": "The password does not match",
  "email-not-valid": "Invalid email",
  "zipcode-not-valid": "Invalid postal code.",
  "only-alpha": "Only alphabetic characters, [ , -, '] are allowed",
  "same-email-not-valid": "The email cannot be the same as your address",
  "regex-not-valid": "Field not valid",
  "field-required": "This field is required",
  "select-is-empty": "Selection required",
  "identification-legal-person": "Legal Entity Identification",
  "identity-represent": "Representative Identification",
  "guarantee-provider-certificate": "Warranty Certificate",
  "identification": "Identity Document",
  "residency": "Proof of Residence",
  "professional": "Professional Status",
  "financial": "Proof of Income",
  "tax": "Tax Notice",
  "personal-information": "Provide my information",
  "last-update": "Last update on {0}",
  "my-document": "Upload my documents",
  "my-guarantor": "Provide my guarantor",
  "my-cotenant": "My co-tenant's documents",
  "my-cotenant-guarantor": "My co-tenant's guarantor",
  "validate-file": "Validate my file",
  "noDocument-social": "I cannot provide proof of receiving social benefits",
  "noDocument-salary": "I cannot provide my last three payslips",
  "noDocument-pension": "I cannot provide proof of receiving a pension",
  "noDocument-rent": "I cannot provide proof of receiving rent",
  "noDocument-scholarship": "I cannot provide proof of receiving a scholarship",
  "noDocument-social-service": "I cannot provide proof of receiving social benefits",
  "EMPTY": "Absent",
  "TO_PROCESS": "Processing",
  "VALIDATED": "Verified",
  "DECLINED": "Modification Requested",
  "INCOMPLETE": "Incomplete",
  "try-again": "An error occurred, please try again later.",
  "example-prefix": "E.g., ",
  "next-step": "Go to the next step",
  "add-new-documents": "Add new documents",
  "ALONE": "Single",
  "COUPLE": "Couple",
  "GROUP": "Flatshare",
  "dossier": {
    "TO_PROCESS": "Processing",
    "VALIDATED": "Verified",
    "DECLINED": "Modification Requested",
    "INCOMPLETE": "Incomplete",
    "ARCHIVED": "Archived",
    "warn-TO_PROCESS": "Processing",
    "warn-VALIDATED": "Verified",
    "warn-DECLINED": "Modification Requested",
    "warn-INCOMPLETE": "Incomplete",
    "warn-ARCHIVED": "Archived"
  },
  "documents": {
    "identity-card": "French Identity Card",
    "passport": "French Passport",
    "permit": "French Residence Permit",
    "drivers-license": "Driver's license",
    "france-identite": "France Identité",
    "other": "Other",
    "tenant": "Tenant",
    "owner": "Owner",
    "guest": "Free Accommodation",
    "guest-company": "Company's accommodation",
    "guest-organism": "Organism's accommodation (Emergency accommodation, directive home)",
    "short-term-rental": "Short Term rental (B&B)",
    "other-residency": "Homeless or in another situation",
    "cdi": "Permanent Contract",
    "cdi-trial": "Permanent Contract (Probation Period)",
    "cdd": "Fixed-term Contract",
    "alternation": "Apprenticeship",
    "internship": "Internship",
    "student": "Studies",
    "public": "Public Sector",
    "ctt": "Temporary Work Contract",
    "retired": "Retirement",
    "unemployed": "Unemployment",
    "independent": "Self-employed",
    "intermittent": "Temporary worker",
    "stay-at-home-parent": "Housewife/Houseman",
    "no-activity": "Workless",
    "artist": "Artist-writer",
    "salary": "Professional Income",
    "guarantor_salary": "Professional Income",
    "social-service": "Social Benefits",
    "rent": "Rents",
    "pension": "Pensions",
    "scholarship": "Scholarships",
    "no-income": "No Income",
    "status": {
      "TO_PROCESS": "Processing",
      "VALIDATED": "Verified",
      "DECLINED": "Modification Requested",
      "INCOMPLETE": "Incomplete",
      "EMPTY": "Missing Document"
    },
    "subcategory": {
      "SALARY": "Professional Income",
      "RENT": "Rents",
      "SOCIAL_SERVICE": "Social Benefits",
      "PENSION": "Pensions",
      "SCHOLARSHIP": "Scholarships",
      "NO_INCOME": "No Income"
    }
  },
  "explanation-text": {
    "tenant": {
      "professional": {
        "alternation": "Thank you for adding your current or upcoming <strong>apprenticeship or professionalization contract</strong> here. It must be <strong>complete</strong> and <strong>signed</strong> by all parties involved (you, your supervisor, the employer, etc.)",
        "cdd": "<p>Thank you for adding your <strong>employment contract</strong> here. It must be <strong>current or upcoming</strong> (completed fixed-term contracts are not accepted), <strong>complete</strong> (all pages), and <strong>signed</strong> by you and your employer.</p><p>Tips: Do not unnecessarily overload your file with additional documents; the complete contract is sufficient.</p>If you do not have your employment contract, you can add a <strong>employer's attestation</strong> issued within the last 3 months.",
        "cdi": "<p>Thank you for adding your current or upcoming <strong>employment contract</strong> here. It must be <strong>complete</strong> (all pages) and <strong>signed</strong> by you and your employer.</p><p>Tips: Do not unnecessarily overload your file with additional documents; the complete contract is sufficient.</p>If you do not have your employment contract, you can add a <strong>employer's attestation</strong> issued within the last 3 months.",
        "cdi-trial": "<p>Thank you for adding your current or upcoming <strong>employment contract</strong> here. It must be <strong>complete</strong> (all pages) and <strong>signed</strong> by you and your employer.</p><p>Tips: Do not unnecessarily overload your file with additional documents; the complete contract is sufficient.</p>If you do not have your employment contract, you can add a <strong>employer's attestation</strong> issued within the last 3 months.",
        "ctt": "<p>Thank you for adding your current or upcoming <strong>employment contract</strong> here. It must be <strong>complete</strong> (all pages) and <strong>signed</strong> by you and your employer.</p><p>If you do not have your employment contract, you can add an <strong>employer's attestation</strong> specifying the job position, proposed salary, expected start date, and duration of the contract.</p><p>Tips: You can also add your employment certificates from the last 3 months or more if you have them. This will help a landlord ensure the regularity and recurrence of your assignments.</p>",
        "other": "<p>If your current activity does not correspond to any of the choices on this list, you can add a <strong>sworn statement</strong> explaining your situation here.</p><p>Tips: You can use this <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>model sworn statement</a>.</p>",
        "independent": "<p>Thank you for adding one of the following documents here:</p><ul><li>your <strong>professional card</strong> (liberal profession)</li><li>a <strong>K extract or K bis extract⁽¹⁾</strong> issued within the last 3 months</li><li>a <strong>SIRENE directory situation certificate⁽²⁾</strong> issued within the last 3 months</li><li>your <strong>account statement⁽³⁾</strong> (for self-employed individuals)</li><li>a <strong>registration certificate⁽⁴⁾</strong> in the National Business Register</li><li>a <strong>income certificate</strong> for the current year issued by an accountant</li></ul><p>⁽¹⁾ Learn how to obtain a <a href='https://entreprendre.service-public.fr/vosdroits/F21000#:~:text=Comment%20l%27obtenir%20%3F,son%20K%20ou%20Kbis%20numérique.' target='_blank' rel='noopener'>K extract or K bis extract</a><br>⁽²⁾ Learn how to obtain a <a href='https://entreprendre.service-public.fr/vosdroits/R17969' target='_blank' rel='noopener'>SIRENE directory situation certificate</a><br>⁽³⁾ Learn how to download your <a href='https://www.autoentrepreneur.urssaf.fr/portail/accueil/une-question/toutes-les-fiches-pratiques/telecharger-mes-attestations.html' target='_blank' rel='noopener'>account statement</a><br>⁽⁴⁾ Learn how to obtain a <a href='https://entreprendre.service-public.fr/vosdroits/R19859' target='_blank' rel='noopener'>registration certificate</a><br></p><p>Note: These procedures are completely free of charge.</p>",
        "internship": "<p>Thank you for adding your current or upcoming <strong>internship agreement</strong> here. It must be <strong>complete</strong> (all pages) and <strong>signed</strong> by all parties involved (you, your supervisor, the employer, etc.)</p><p>Tips: Do not unnecessarily overload your file with additional documents; the complete agreement is sufficient.</p>",
        "public": "<p>Thank you for adding your current or upcoming <strong>appointment order</strong> here.</p><p>If you do not have your appointment order, you can add an <strong>employer's attestation</strong> issued within the last 3 months.</p>",
        "retired": "<p>Thank you for adding one of the following documents here:</p><li>a <strong>pension statement</strong> or a <strong>pension payment attestation</strong> issued within the last 2 years</li><li>a <strong>pension entitlement attestation</strong></li><li>your <strong>retirement pension certificate</strong></li><li>your most recent or penultimate <strong>complete income tax notice</strong> (all pages)</li></ul><p>Tips: The pension statement is the most suitable document here. Only add your income tax notice if you have no other document from the list.</p>",
        "salary": "salary",
        "scholarship": "<p>Thank you for adding one of the following documents here:</p><ul><li>your <strong>student card</strong></li><li>a <strong>certificate of enrollment</strong></li></ul><p>The document must include your name, the name of the institution, and the current academic year.</p>",
        "unemployed": "<p>Thank you for adding one of the following documents:</p><ul><li>An unemployment situation notice (Pôle emploi⁽¹⁾) issued within the last 3 months</li><li>A certificate of eligibility for unemployment benefits (ARE)</li></ul><p>⁽¹⁾ Learn <a href='https://www.pole-emploi.fr/candidat/vos-services-en-ligne/les-pas-a-pas-les-videos-qui-vou/comment-obtenir-une-attestation.html' target='_blank' rel='noopener'>how to obtain your unemployment situation notice</a> on the Pôle emploi website.</p>",
        "student": "<p>Thank you for adding one of the following documents:</p><ul><li>Your student card</li><li>A certificate of enrollment</li><li>The ParcoursSup registration certificate</li></ul><p>The document must include your name, the name of the institution, and the current academic year.</p>",
        "no-activity": "<p>If you are disabled and receive the disabled adults' allowance (AAH), please add here <strong>a disability pension payment attestation</strong>⁽¹⁾ from the CAF less than 3 months old.</p><p>⁽¹⁾ find out how to obtain a <a href='https://www.ameli.fr/roubaix-tourcoing/assure/adresses-et-contacts/l-obtention-d-un-document/obtenir-une-attestation/obtenir-une-attestation-de-paiement-de-pension-d-invalidite-ou-de-rente' target='_blank' rel='noopener'>payment certificate from the CAF</a>.</p><p>If you're unemployed and don't receive any income, you can add a <strong>sworn statement</strong> explaining your situation here.</p><p>Tip: you can use this <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>model of sworn statement</a></p>"
      },
      "guarantor_salary": "I add the three latest salary slips from my guarantor, an employer's attestation, proof of payment of internship allowances, a financial statement, or a tax assessment in their name, complete and issued within the last 2 years.",
      "guest": "Please add <strong>a solemn declaration</strong> of free accommodation (we recommend using the attestation template available on the <a target='_blank' rel='noopener' title='site to generate an attestation' href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement'>service-public.fr</a> website).",
      "guest-company": "<p>Please add here <b>a certificate from your employer</b> indicating that you have company-provided housing.<br>If you don't have one, you can upload a payslip showing the company housing or a sworn employment contract explaining your situation (we recommend using the certificate template available on the <a href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement' target='_blank' rel='noopener'>service-public.fr</a> website).</p>",
      "guest-organism": "<p>Please add here a <a href='https://www.service-public.fr/simulateur/calcul/16030' target='_blank' rel='noopener'>proof of domicile election</a> less than 3 months old, dated and signed by the hosting organization (emergency accommodation, placement...)</p>",
      "identity-card": "Please add your ID card <b>front and back</b>.",
      "passport": "Please add <b>pages 2 and 3</b> of your passport, as the signature must be visible.",
      "permit": "Please add your permit <b>front and back</b>.",
      "other": "",
      "less-than-year": "I have declared that I have been in France for less than a year.",
      "my-name": "<p>Please add here <strong>all pages of your most recent tax notice</strong>, whether you are taxable or not.</p><p>Note: previous years' notices can also be added if you wish, but they are not sufficient.</p>",
      "my-parents": "I have declared that I am financially dependent on my parents' household.",
      "no-income": "I have no income.",
      "organism-guarantor": "I add a certificate or a visa issued by the organization guaranteeing for me.",
      "other-tax": "To improve my application, I explain below why I do not receive a tax assessment. My explanation will be added to my application:",
      "owner": "I add a <b>property tax notice issued within the last year.</b>",
      "pension": "I add a pension slip, a pension attestation, or a tax assessment showing the names and income from the pension.",
      "rent": "I add proof of rent payment or a tax assessment showing the names and income from the rent.",
      "salary": "",
      "scholarship": "",
      "social-service": "I add <strong>my last three payment proofs</strong> for social benefits (unemployment benefits, family allowances...), proof of eligibility, or a screenshot of housing assistance simulation (APL).",
      "tenant": "I add my <b>rent receipts</b> from the <b>last three months</b>."
    },
    "cotenant": {
      "alternation": "I add their current and signed apprenticeship contract.",
      "cdd": "I add their current and signed employment contract.\nAnd if they don't have an employment contract? You can attach a signed and dated attestation from their employer issued within the last 3 months.",
      "cdi": "I add their current and signed employment contract.\nAnd if they don't have an employment contract? You can attach a signed and dated attestation from their employer issued within the last 3 months.",
      "cdi-trial": "I add their current and signed employment contract.\nAnd if they don't have an employment contract? You can attach a signed and dated attestation from their employer issued within the last 3 months.",
      "ctt": "I add their current and signed employment contract.\nAnd if they don't have an employment contract? You can attach a signed and dated attestation from their employer issued within the last 3 months.",
      "guarantor_salary": "I add the three latest salary slips from their guarantor, an employer's attestation, proof of payment of their internship allowances, a financial statement, or a tax assessment in their name, complete and issued within the last 2 years.",
      "guest": "Please add <strong>a solemn declaration</strong> of free accommodation (we recommend using the attestation template available on the <a target='_blank' rel='noopener' title='site to generate an attestation' href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement'>service-public.fr</a> website).",
      "guest-company": "<p>Please add here <b>a certificate from your employer</b> indicating that you have company-provided housing.<br>If you don't have one, you can upload a payslip showing the company housing or a sworn employment contract explaining your situation (we recommend using the certificate template available on the <a href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement' target='_blank' rel='noopener'>service-public.fr</a> website).</p>",
      "guest-organism": "<p>Please add here a <a href='https://www.service-public.fr/simulateur/calcul/16030' target='_blank' rel='noopener'>proof of domicile election</a> less than 3 months old, dated and signed by the hosting organization (emergency accommodation, placement...)</p>",
      "identity-card": "Please make sure to add their ID card <b>front and back</b>.",
      "passport": "Please make sure to add <b>pages 2 and 3</b> of their passport, as the signature must be visible.",
      "permit": "Please make sure to add their permit <b>front and back</b>.",
      "other": "",
      "independent": "I add their proof of independent professional activity according to their type of activity.",
      "internship": "I add the complete and signed internship agreement from their guarantor.",
      "less-than-year": "I have declared that they have been in France for less than a year.",
      "my-name": "I add a tax assessment in their name.",
      "my-parents": "They have declared that they are financially dependent on my parents' household.",
      "no-income": "They have no income.",
      "organism-guarantor": "I add a certificate or a visa issued by the organization guaranteeing for my partner.",
      "other-tax": "To improve their application, I explain below why they do not receive a tax assessment. Their explanation will be added to their application:",
      "owner": "I add a <b>property tax notice issued within the last year.</b>",
      "pension": "I add a pension slip, a pension attestation, or a tax assessment showing the names and income from the pension.",
      "public": "I add an appointment decree.",
      "rent": "I add proof of rent payment or a tax assessment showing the names and income from the rent.",
      "retired": "I add a retirement pension slip. And if they don't have one? You can attach their latest complete tax assessment issued within the last 2 years.",
      "salary": "I add their last three salary slips, or proof of their internship allowances, or their last two financial statements (self-employed).",
      "scholarship": "I add a scholarship award letter.",
      "social-service": "I add proof of payment of social benefits (unemployment benefits, CAF, Crous, etc.) from their guarantor issued within the last 3 months, proof of entitlement, or a simulation certificate for housing assistance.",
      "student": "I add a copy of their student ID card or a certificate of enrollment for the current academic year.",
      "tenant": "I add their <b>rent receipts for the last three months.</b>",
      "unemployed": "I add a proof of entitlement to unemployment benefits (ARE) or a Pôle Emploi situation statement issued within the last 3 months."
    },
    "guarantor": {
      "alternation": "I add a salary slip from my guarantor issued within the last 3 months, or their complete and signed apprenticeship contract.",
      "cdd": "I add a salary slip from my guarantor issued within the last 3 months, their complete and signed employment contract, and an employer's attestation issued within the last 3 months.",
      "cdi": "I add a salary slip from my guarantor issued within the last 3 months, their complete and signed employment contract, and an employer's attestation issued within the last 3 months.",
      "cdi-trial": "I add a salary slip from my guarantor issued within the last 3 months, their complete and signed employment contract, and an employer's attestation issued within the last 3 months.",
      "ctt": "I add a salary slip from my guarantor issued within the last 3 months, their complete and signed employment contract, and an employer's attestation issued within the last 3 months.",
      "guarantor_salary": "I add the three latest salary slips from my guarantor, an employer's attestation, proof of payment of their internship allowances, a financial statement, or a tax assessment in their name, complete and issued within the last 2 years.",
      "guest": "Please add <strong>a solemn declaration</strong> of free accommodation (we recommend using the attestation template available on the <a target='_blank' rel='noopener' title='site to generate an attestation' href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement'>service-public.fr</a> website).",
      "identity-card": "Please make sure to add their ID card <b>front and back</b>.",
      "passport": "Please make sure to add <b>pages 2 and 3</b> of their passport, as the signature must be visible.",
      "permit": "Please make sure to add their permit <b>front and back</b>.",
      "other": "",
      "independent": "I add a proof of independent professional activity from my guarantor.",
      "internship": "I add the complete and signed internship agreement from my guarantor.",
      "less-than-year": "I have provided all the required documents for my guarantor.",
      "my-name": "I add a <b>tax assessment issued within the last two years</b> in the name of my guarantor. The fiscal number and the income must be legible.",
      "other-tax": "To improve my application, I explain below why I don't have a tax assessment in the name of my guarantor. This explanation will be added to my application:",
      "owner": "I add a <b>proof of address of my guarantor</b> issued within the <b>last three months</b>.",
      "pension": "I add a pension slip or attestation from my guarantor, or a tax assessment in their name, complete and issued within the last 2 years.",
      "public": "I add a salary slip from my guarantor issued within the last 3 months, an employer's attestation issued within the last 3 months, and their appointment decree.",
      "rent": "I add proof of rent payment from my guarantor, or a tax assessment in their name, complete and issued within the last 2 years.",
      "retired": "I add a pension slip, attestation, or retirement pension document from my guarantor, or their complete tax assessment issued within the last 2 years.",
      "salary": "I add a salary slip from my guarantor issued within the last 3 months, an employer's attestation, proof of payment of their internship allowances, a financial statement, or a tax assessment in their name, complete and issued within the last 2 years.",
      "scholarship": "I add a scholarship award letter from my guarantor.",
      "social-service": "I add proof of payment of social benefits (unemployment benefits, CAF, Crous, etc.) from my guarantor issued within the last 3 months, proof of entitlement, or a simulation certificate for housing assistance.",
      "student": "I add a copy of my guarantor's student ID card or a certificate of enrollment for the current academic year.",
      "tenant": "I add a <b>proof of address of my guarantor</b> issued within the <b>last three months</b>.",
      "unemployed": "I add proof of entitlement to unemployment benefits (ARE) from my guarantor or their Pôle Emploi situation statement issued within the last 3 months.",
      "noDocument-social": "I cannot provide proof of payment of social benefits for my guarantor.",
      "noDocument-salary": "I cannot provide the last three salary slips or a financial statement for my guarantor.",
      "noDocument-pension": "I cannot provide proof of pension payment for my guarantor.",
      "noDocument-rent": "I cannot provide proof of rent payment for my guarantor.",
      "noDocument-scholarship": "I cannot provide proof of scholarship award for my guarantor.",
      "customText-social": "To improve my application, I explain why I cannot provide proof of payment of social benefits for my guarantor:",
      "customText-salary": "To improve my application, I explain why I cannot provide the last three salary slips or a financial statement for my guarantor:",
      "customText-pension": "To improve my application, I explain why I cannot provide proof of pension payment for my guarantor:",
      "customText-rent": "To improve my application, I explain why I cannot provide proof of rent payment for my guarantor:",
      "customText-scholarship": "To improve my application, I explain why I cannot provide proof of scholarship award for my guarantor:",
      "will-delete-files": "Attention, a change in the situation will result in the deletion of the supporting documents. You will need to upload the appropriate documents corresponding to your guarantor's situation."
    },
    "cotenant-guarantor": {
      "alternation": "I add a salary slip from their guarantor issued within the last 3 months, or their complete and signed apprenticeship contract.",
      "cdd": "I add a salary slip from their guarantor issued within the last 3 months, their complete and signed employment contract, and an employer's attestation issued within the last 3 months.",
      "cdi": "I add a salary slip from their guarantor issued within the last 3 months, their complete and signed employment contract, and an employer's attestation issued within the last 3 months.",
      "cdi-trial": "I add a salary slip from their guarantor issued within the last 3 months, their complete and signed employment contract, and an employer's attestation issued within the last 3 months.",
      "ctt": "I add a salary slip from their guarantor issued within the last 3 months, their complete and signed employment contract, and an employer's attestation issued within the last 3 months.",
      "guarantor_salary": "I add the 3 latest salary slips from their guarantor, an employer's attestation, proof of payment of their internship allowances, a financial statement, or a tax assessment in their name, complete and issued within the last 2 years.",
      "guest": "I add a <strong>solemn declaration</strong> of free accommodation from their guarantor (we recommend using the attestation template available on the <a target='_blank' rel='noopener' title='site to generate an attestation' href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement'>service-public.fr</a> website)",
      "identity-card": "Please make sure to add their ID card <b>front and back</b>.",
      "passport": "Please make sure to add <b>pages 2 and 3</b> of their passport, as the signature must be visible.",
      "permit": "Please make sure to add their permit <b>front and back</b>.",
      "other": "",
      "independent": "I add a proof of independent professional activity from their guarantor.",
      "internship": "I add the complete and signed internship agreement from their guarantor.",
      "less-than-year": "I have provided all the required documents for their guarantor.",
      "my-name": "I add a <b>tax assessment issued within the last two years</b> in the name of their guarantor. The fiscal number and the income must be legible.",
      "other-tax": "To improve my application, I explain below why I don't have a tax assessment in the name of their guarantor. This explanation will be added to my application:",
      "owner": "I add a <b>proof of address of their guarantor</b> issued within the <b>last three months</b>.",
      "pension": "I add a pension slip or attestation from their guarantor, or a tax assessment in their name, complete and issued within the last 2 years.",
      "public": "I add a salary slip from their guarantor issued within the last 3 months, an employer's attestation issued within the last 3 months, and their appointment decree.",
      "rent": "I add proof of rent payment from their guarantor, or a tax assessment in their name, complete and issued within the last 2 years.",
      "retired": "I add a retirement pension bulletin, certificate, or title from their guarantor, or their complete tax assessment from less than 2 years ago.",
      "salary": "I add a payslip from their guarantor dated less than 3 months ago, an employer's attestation, proof of internship allowance payment, a financial statement, or a tax assessment in their name, complete and from less than 2 years ago.",
      "scholarship": "I add a scholarship award notice from their guarantor.",
      "social-service": "I add proof of social benefits payment (such as unemployment benefits, family benefits, or student benefits from CROUS) from their guarantor dated less than 3 months ago, proof of benefit entitlement, or a simulation certificate for housing assistance.",
      "student": "I add a copy of their guarantor's student card or their current school certificate for the current year.",
      "tenant": "I add a <b>proof of residence from their guarantor</b> dated <b>less than three months ago</b>.",
      "unemployed": "I add proof of their guarantor's entitlement to unemployment benefits or their Pôle Emploi situation report dated less than 3 months ago.",
      "noDocument-social": "I cannot provide proof of social benefits payment.",
      "noDocument-salary": "I cannot provide the last three payslips or a financial statement from their guarantor.",
      "noDocument-pension": "I cannot provide proof of pension payment.",
      "noDocument-rent": "I cannot provide proof of rent payment.",
      "noDocument-scholarship": "I cannot provide proof of scholarship award.",
      "customText-social": "In order to improve my application, I explain why I cannot provide proof from their guarantor:",
      "customText-salary": "In order to improve my application, I explain why I cannot provide the last three payslips or a financial statement from their guarantor:",
      "customText-pension": "In order to improve my application, I explain why I cannot provide proof from their guarantor:",
      "customText-rent": "In order to improve my application, I explain why I cannot provide proof from their guarantor:",
      "customText-scholarship": "In order to improve my application, I explain why I cannot provide proof from their guarantor:",
      "will-delete-files": "Attention, a change in situation will result in the deletion of the documents. You will need to upload the corresponding documents again based on the guarantor's situation."
    }
  },
  "account": {
    "title": {
      "dashboard": "Dashboard"
    },
    "last-update": "Last update of the file on {0}",
    "file-update-title": "Update your file",
    "file-update-text": "You have updated your file, last updated on {0}.<br> To keep it convincing, it is important to keep your supporting documents up to date.",
    "update-file-btn": "Update my documents",
    "copy-link": "Copy my file link",
    "share-by-mail": "Share by email",
    "identification": "Identification document",
    "residency": "Proof of residency",
    "professional": "Proof of professional situation",
    "financial": "Proof of income",
    "tax": "Tax notice",
    "denied": "Unverified documents",
    "download-not-validated-title": "Download my unverified documents",
    "download-not-validated-text": "In order to apply during the verification of your file, you can download your unverified and watermarked documents.",
    "download-zip": "Download",
    "TO_PROCESS": "is being processed",
    "VALIDATED": "is validated",
    "DECLINED": "requires modifications",
    "INCOMPLETE": "is incomplete",
    "delete-bloc": {
      "title": "Account deletion",
      "content": "You can delete your DossierFacile account at any time. This will automatically delete all you documents.",
      "button": "Delete my account"
    },
    "opinion-bloc": {
      "title": "Tell us about your experience with DossierFacile",
      "content": "We are constantly looking to improve the quality of our service. As part of this improvement process, we seek your feedback. By clicking on the button below, you will be able to rate DossierFacile. Your feedback is valuable to us! Thank you."
    },
    "share-file": "Share my file",
    "share-file-description": "Copy your file link to share it! It's up to you to send it to the landlords or property owners of your choice (by email, SMS, etc.)",
    "share-file-button": "Share your file",
    "share-file-view-button": "View your file",
    "file-resume": "Share my summary file <br>(without supporting documents)",
    "file-full": "Share my complete file<br>(with supporting documents)",
    "copy": "Copy",
    "field-required": "This field is required",
    "CDI": "permanent contract",
    "CDI_TRIAL": "permanent contract (trial period)",
    "CDD": "fixed-term contract",
    "ALTERNATION": "apprenticeship",
    "INTERNSHIP": "internship",
    "STUDENT": "student",
    "PUBLIC": "in the public sector",
    "CTT": "temporary contract",
    "RETIRED": "retired",
    "UNEMPLOYED": "unemployed",
    "INDEPENDENT": "self-employed",
    "OTHER": "Other",
    "no-income": "have no income",
    "income": "have a monthly net income of {0}€",
    "copied": "Copied!",
    "ALONE": "alone",
    "couple-with": "coupled with {0}",
    "group-with": "sharing with {0}",
    "group-with-someone": "sharing with someone",
    "someone": "someone",
    "amendment-required-title": "Requested modifications",
    "amendment-required-text": "After reviewing your file, modifications are requested. <br>Check your messages for details.",
    "messaging": "Check my messages",
    "congratulations-title": "Your DossierFacile is available",
    "congratulations-text-1": "Go to the <a href=\"/applications\">Share your file</a> page to send your DossierFacile by email or link, with or without supporting documents.",
    "congratulations-text-2": "For more information, visit the <a href=\"https://aide.dossierfacile.logement.gouv.fr/fr/article/comment-visualiser-et-partager-son-dossier-valide-1orckmm/\">View and share your validated file</a> page in our <a href=\"https://aide.dossierfacile.logement.gouv.fr\">online help</a>.",
    "full-link-copied": "The link to my complete file is copied!",
    "public-link-copied": "The link to my summary file is copied!",
    "dossier": {
      "status": {
        "TO_PROCESS": "Being processed",
        "VALIDATED": "Verified",
        "DECLINED": "Modification requested",
        "INCOMPLETE": "Incomplete"
      }
    },
    "processing-bloc": {
      "title": "Processing Delay",
      "text": "Your Files are currently in process. You will receive an e-ameil after processing.",
      "badge": "Estimated time : From {0}h to {1}h",
      "badge-undefined": "Estimated time not available",
      "delay": "Yours Files will be process {0} between {1} and {2}",
      "delay-on-2days": "Yours Files will be process from {0} to {1}",
      "delay-undefined": "Estimated time computation is currently in progress. Files are typically processed within 24 hours on average.",
      "last-update": "Last files update : {0}"
    }
  },
  "app": {
    "home": "Accueil"
  },
  "backnext": {
    "back": "Back",
    "back-aria-label": "Go back to previous step",
    "continue": "Continue",
    "continue-aria-label": "Save and continue"
  },
  "changepasswordpage": {
    "error": "Error",
    "password-update": "The password has been updated",
    "token-expired": "The link has expired, please start the forgot password procedure"
  },
  "confirmaccount": {
    "action-confirmed": "Your action has been taken account.",
    "token-err": "An error occurred, if you have already confirmed your account you can log in, otherwise you can start the <a href='/forgotten-password'>password reset procedure</a>"
  },
  "corporationidentification": {
    "organism-name": "Legal entity name",
    "organism-name-placeholder": "Legal entity name",
    "kbis-label": "I add an extract from the company's K bis, or any other document proving the legal existence of the entity.",
    "kbis": "Original K bis extract of the company",
    "status": "Articles of association of the legal entity",
    "all-accepted": "Any other document proving the legal existence of the entity, demonstrating that a declaration has been made to an administration, a court, or a professional organization.",
    "balance-sheet": "Balance sheet",
    "urssaf": "Urssaf contribution certificate",
    "all-other": "Any other document",
    "register": "Register the document",
    "field-required": "This field is required"
  },
  "cotenantdocument": {
    "warning-need-residency-documents": "Are you sure you have submitted all the necessary documents here?",
    "list1": "Proof of accommodation",
    "list2": "Copy of the ID document of the host",
    "list3": "Proof of address of the host dated within the last 3 months"
  },
  "cotenantfinancialform": {
    "title": "Proof of Income",
    "tenant": "Tenant",
    "owner": "Owner",
    "guest": "Free Accommodation",
    "guest-parents": "Living with Parents",
    "files": "Documents",
    "will-delete-files": "Attention, a change in situation will result in the deletion of your proofs. You will need to upload the proofs corresponding to the new situation.",
    "salary": "Professional Income",
    "social-service": "Social Benefits",
    "rent": "Rentals",
    "pension": "Pensions",
    "scholarship": "Scholarships",
    "no-income": "No Income",
    "monthlySum": "Amount in Euros",
    "monthlySum-label": "I indicate the amount of their monthly net income to be paid (before withholding tax)",
    "noDocument-social-service": "I cannot provide proof of social benefit payments",
    "noDocument-salary": "I cannot provide their last three payslips",
    "noDocument-pension": "I cannot provide proof of pension payments",
    "noDocument-rent": "I cannot provide proof of rental payments",
    "noDocument-scholarship": "I cannot provide proof of scholarship allocation",
    "customText-social-service": "To improve my application, I explain why I cannot provide my partner's proof of benefits:",
    "customText-salary": "To improve my application, I explain why I cannot provide my partner's last three payslips or financial statements:",
    "customText-pension": "To improve my application, I explain why I cannot provide my partner's proof of pension payments:",
    "customText-rent": "To improve my application, I explain why I cannot provide my partner's proof of rental payments:",
    "customText-scholarship": "To improve my application, I explain why I cannot provide my partner's proof of scholarships:",
    "customText-undefined": "To improve my application, I explain why I cannot provide the proofs:",
    "custom-text": "To improve your application, you can add an explanation:",
    "high-salary": "You have entered a salary greater than €10,000. Are you sure you have entered the monthly salary?",
    "low-salary": "You have entered a salary equal to €0. Are you sure you have entered the monthly salary?",
    "has-no-income": "You have indicated no income",
    "number-not-valid": "Incorrect value - enter a number without commas",
    "delete-financial": "Delete this income",
    "field-required": "This field is required",
    "register": "Save",
    "missing-file": "You must add files to save this income.",
    "warning-no-income-and-file": "You cannot have files and indicate that you cannot provide files. Please uncheck the box or delete your files."
  },
  "cotenantfinanciallist": {
    "delete-financial": "Delete this income",
    "register": "Save",
    "i-have-no-income": "I have no income",
    "title": "Co-tenant Income Summary",
    "subtitle": "Here is the list of incomes you have declared. You can add new incomes at any time if necessary.",
    "monthly": " monthly net ",
    "net-monthly": "Net monthly pay",
    "add-income": "Add a new income?"
  },
  "cotenantidentification": {
    "title": "My Co-tenant's Identification Document",
    "description": "Please add the front and back.",
    "identity-card": "French ID Card",
    "passport": "French Passport",
    "permit": "French Residence Permit",
    "other": "Other"
  },
  "cotenantname": {
    "lastname": "Last Name",
    "firstname": "First Name",
    "preferredname": "Preferred Name",
    "email": "Email",
    "email-not-valid": "Invalid email",
    "lastname-placeholder": "e.g., Dupont",
    "firstname-placeholder": "e.g., Jean",
    "field-required": "This field is required",
    "title": "Provide Personal Information of My Co-tenant",
    "subtitle": "Please provide the information of the person whose name will appear on the lease agreement.",
    "franceconnect-account": "This account is identified with FranceConnect, and the names cannot be edited."
  },
  "cotenantprofessional": {
    "title": "Your Co-tenant's Current Employment Situation",
    "cdi": "Permanent Contract",
    "cdi-trial": "Permanent Contract (Trial Period)",
    "cdd": "Fixed-Term Contract",
    "alternation": "Apprenticeship",
    "internship": "Internship",
    "student": "Studies",
    "public": "Public Service",
    "ctt": "Temporary Work Contract",
    "retired": "Retired",
    "unemployed": "Unemployed",
    "independent": "Self-Employed",
    "other": "Other"
  },
  "cotenantresidency": {
    "title": "Your Co-tenant's Current Housing Situation",
    "tenant": "Tenant",
    "owner": "Owner",
    "guest": "Staying for free",
    "guest-parents": "Staying with parents"
  },
  "cotenanttax": {
    "title": "My Co-tenant's Tax Notice",
    "my-name": "I have a tax notice in my co-tenant's name",
    "my-parents": "My co-tenant is financially dependent on their parents",
    "less-than-year": "My co-tenant has been in France for less than a year",
    "other-tax": "Other",
    "accept-verification": "I agree that DossierFacile may perform an automated verification of my guarantor's tax document with the tax authorities",
    "custom-text": "To improve your application, please explain below why you don't receive a tax notice. Your explanation will be added to your application:",
    "situation": "What is their tax situation?",
    "warning-no-accepted-doc": "Attention, the declarative statement of tax situation is not accepted.",
    "goto-documentation": "View documentation",
    "custom-text-required": "Please fill in the explanation field"
  },
  "coupleinformationhelp": {
    "paragraph1": "By providing your co-tenant's email address, they will be invited to contribute to the creation of your joint application. With their own email and password, they will have the ability to log into your joint application and add, modify, or delete any document, both their personal documents and yours.",
    "paragraph2": "Attention! Your personal documents and their personal documents will be processed separately by DossierFacile. You can submit and have them validated independently. However, your joint application will only be validated as a whole once both your documents and their documents have been validated by DossierFacile. You will then have a single shared link for your entire joint application.",
    "paragraph3": "In practice, your co-tenant will receive an invitation by email, which they must validate within 48 hours. They simply need to click the link, set their password, and they will be connected to your joint application."
  },
  "coupleinformation": {
    "spouseFirstName": "First Name",
    "spouseLastName": "Last Name",
    "spouseEmail": "Your Co-tenant's Email Address (optional)",
    "acceptAuthor": "I agree that my partner will have access to my documents and those of my guarantor, if applicable, once both of our applications have been validated",
    "field-required": "This field is required",
    "partner-name-title": "Who will be your co-tenant?",
    "partner-email-title": "Invite them to fill out their application?",
    "add-a-spouse": "Add your co-tenant",
    "email-exists": "You cannot associate two accounts with the same email address! <br>Please provide a different email address.",
    "more-information": "How does it work?"
  },
  "deleteaccount": {
    "validate": "Delete my account",
    "validate-mobile": "Delete",
    "cancel": "Cancel",
    "title": "Account Deletion",
    "confirm-delete": "Please confirm the complete deletion of the account",
    "try-again": "An error occurred, please try again later."
  },
  "documentdownloader": {
    "files": "Documents",
    "will-delete-files": "Warning, a change in your situation will delete the supporting documents. You will need to upload the documents again.",
    "register": "Save Document",
    "validate": "Validate",
    "cancel": "Cancel",
    "field-required": "This field is required",
    "warning-no-document-and-files": "You cannot have files selected and indicate that you cannot provide all the files. Please uncheck the box or remove your files.",
    "noDocument-default": "I cannot provide the requested documents"
  },
  "documenthelp": {
    "title": "Help",
    "paragraph1": "If your document is too large, we recommend scanning it (using a free app for smartphones, for example).<br>If that doesn't work, contact us by email at <a href='mailto:contact{'@'}dossierfacile.logement.gouv.fr'>contact{'@'}dossierfacile.logement.gouv.fr</a>, and we'll do our best to respond as quickly as possible!"
  },
  "documentinsert": {},
  "fileerrors": {
    "IDENTIFICATION": "Proof of Identity",
    "RESIDENCY": "Proof of Residence",
    "PROFESSIONAL": "Proof of Employment",
    "FINANCIAL": "Proof of Income",
    "TAX": "Tax Notice",
    "file-not-valid": "Your application is not valid. Please complete the missing documents or modify the rejected documents to submit your application",
    "error-title": "Invalid Application",
    "error-title-guarantor": "Invalid Guarantor Application",
    "his-missing-documents": "Their invalid documents:",
    "update": "Update",
    "organism-cert": "Guarantee Certificate",
    "representative-identification": "Identity of the Legal Representative",
    "corporation-identification": "Identity of the Legal Entity",
    "my-invalid-document": "My invalid documents:",
    "my-invalid-names": " My missing information",
    "my-invalid-document-guarantor": "My guarantor's invalid documents:",
    "my-invalid-names-guarantor": "My guarantor's missing name or first name:",
    "tenant-invalid-document": "My co-tenant's invalid documents:",
    "tenant-invalid-names": "My co-tenant's missing name or first name:",
    "tenant-invalid-document-guarantor": "My co-tenant's guarantor's invalid documents:",
    "tenant-invalid-names-guarantor": "My co-tenant's guarantor's information is invalid:",
    "user-names": "Personal Information"
  },
  "fakeannouncement": {
    "fake-announce-title": "Report a fake announcement!",
    "content": "If you believe you are dealing with a classified ad scam, {nodocument}, and report it on {masecurite}, the website of the Ministry of the Interior.",
    "nodocument": "do not send any documents",
    "masecurite": "My Security",
    "link-title": "Go to masecurite.fr website (new window)",
    "signal-button": "Report an announcement"
  },
  "file": {
    "title": "{0}'s Tenant File",
    "description": "{0} {1}",
    "guarant": "Guarantor's document(s)",
    "personnal-file": "Applicant's document(s)",
    "identification": "Identification document",
    "residency": "Proof of address",
    "professional": "Proof of employment",
    "financial": "Proof of income",
    "tax": "Tax return",
    "tax-verified": "Tax income certified by the tax authorities",
    "download-all": "Download complete file (.pdf)",
    "download-all-inprogress": "Download in progress...",
    "ALONE": "Alone",
    "COUPLE": "Couple",
    "GROUP": "Shared accommodation",
    "no-income": "without income",
    "income": "with a monthly net income of {0}€",
    "organism": "Organization's certificate",
    "identification-legal-person": "Legal entity identification",
    "and": " and ",
    "download-failed": "Download failed. Please try again in a few minutes.",
    "download-disabled-title": "The file will be available for download once it has been fully verified by DossierFacile.",
    "not-found": "The file was not found. Please check the provided link."
  },
  "filereinsurance": {
    "documents-VALIDATED": "The file contains the required documents",
    "documents-TO_PROCESS": "The documents in the file are being verified",
    "documents-DECLINED": "The file does not yet contain the required documents",
    "documents-INCOMPLETE": "The file does not yet contain the required documents",
    "tax-to_process": "The tax return is being verified",
    "tax-nok": "The tax return could not be verified",
    "file-VALIDATED": "The documents are consistent and have been manually reviewed by our agents",
    "file-TO_PROCESS": "Our agents are ensuring the consistency of all supporting documents",
    "file-DECLINED": "The documents will be manually reviewed by our agents once the file is completed",
    "file-INCOMPLETE": "The documents will be manually reviewed by our agents once the file is completed",
    "title-dossier-VALIDATED": "This file has been verified by DossierFacile!",
    "title-dossier-DECLINED": "This file is currently declined!",
    "title-dossier-TO_PROCESS": "This file is being verified by DossierFacile!",
    "title-dossier-INCOMPLETE": "This file is currently incomplete!",
    "know-more": "Learn more about DossierFacile",
    "france-connect-user": "The candidate's identity has been certified through FranceConnect authentication.",
    "france-connect-user-all": "The candidates' identities have been certified through FranceConnect authentication.",
    "france-connect-user-partial": "The identity of {0} candidate(s) has been certified through FranceConnect authentication.",
    "tax-checked": "Tax income certified by the tax authorities"
  },
  "filerowlistitem": {
    "edit": "Update",
    "see": "See"
  },
  "fileupload": {
    "drag-and-drop-files": "Drag and drop your documents",
    "files-format": "JPG, PNG, heic or PDF",
    "or": "or",
    "browse-files": "Browse files",
    "uploading-files": "Uploading files...",
    "send-problem": "Sending problem.",
    "file-too-big": "The file size must not exceed {0}MB",
    "pages": "Maximum {0} pages",
    "size": " Maximum {0}MB per file"
  },
  "financialdocumentform": {
    "financial": "Proof of income",
    "will-delete-files": "Attention, a change in your situation will result in the deletion of your documents. You will need to upload the documents corresponding to your new situation again.",
    "monthlySum-label": "I indicate the amount of my monthly net income to be paid (before withholding tax)",
    "monthlySum": "Amount in euros",
    "delete-financial": "Delete this income",
    "custom-text": "To improve your file, you can add an explanation:",
    "customText-social-service": "To improve my file, I explain why I cannot provide my documents:",
    "customText-salary": "To improve my file, I explain why I cannot provide my three most recent payslips:",
    "customText-pension": "To improve my file, I explain why I cannot provide my documents:",
    "customText-rent": "To improve my file, I explain why I cannot provide my documents:",
    "customText-scholarship": "To improve my file, I explain why I cannot provide my documents:",
    "income-zero": "If you don't have any income, please select 'No income'",
    "noDocument-social": "I cannot provide proof of social benefits payment",
    "noDocument-salary": "I cannot provide my three most recent payslips",
    "noDocument-pension": "I cannot provide proof of pension payment",
    "noDocument-rent": "I cannot provide proof of rent payment",
    "noDocument-scholarship": "I cannot provide proof of scholarship award",
    "missing-file": "You must add files to save this income.",
    "warning-no-income-and-file": "You cannot have files and indicate that you cannot provide all the files. Please uncheck the box or delete your files.",
    "high-salary": "You have entered a salary greater than €10,000. Are you sure you have entered your monthly salary?",
    "low-salary": "You have entered a salary equal to €0. Are you sure you have entered your monthly salary?",
    "has-no-income": "You indicated that you have no income",
    "number-not-valid": "Incorrect value - enter a number without a comma"
  },
  "financial-page": {
    "delete-financial": "Delete this income",
    "register": "Register",
    "i-have-no-income": "I have no income",
    "title": "Summary of your income",
    "subtitle": "Here is the list of income you have declared. You can add new income at any time if necessary.",
    "monthly": " monthly net €",
    "net-monthly": "Net monthly",
    "add-income": "Add new income?"
  },
  "forgottenpasswordpage": {
    "email-not-found": "Email not found",
    "mail-sent": "An email has been sent to the provided address.",
    "clic-to-confirm": "Please click on the sent link to confirm your email address and continue with the password change."
  },
  "guarantorchoicehelp": {
    "paragraph1": "A guarantor is a natural or legal person who undertakes to pay the tenant's rental debts to the landlord.<br> More information at <a target='_blank' href='https://www.service-public.fr/particuliers/vosdroits/F31267'>https://www.service-public.fr/particuliers/vosdroits/F31267</a>",
    "paragraph2": "Some organizations can also provide a guarantee. Obtain a free Visale guarantee at <a target='_blank' href='https://www.visale.fr'>www.visale.fr</a>!",
    "paragraph3": "Attention: <b>you are not required to add a guarantor.</b> However, some landlords may require one."
  },
  "guarantorchoice": {
    "add-guarantor": "Add a guarantor?",
    "identification": "Identification Document",
    "residency": "Proof of Residence",
    "professional": "Proof of Professional Status",
    "financial": "Proof of Income",
    "tax": "Tax Notice",
    "representative-identification": "Identity of the Legal Entity",
    "corporation-identification": "Identity of the Legal Entity Representative",
    "guarantor": "Guarantor",
    "validate": "Validate",
    "will-delete-guarantor": "Do you really want to change the type of guarantor?",
    "validate-file": "Next Step - Validate Application",
    "natural-person": {
      "label": "A person",
      "description": "Example : a parent, a relative"
    },
    "organism": {
      "label": "An Organizational guarantor",
      "description": "Example : Visale"
    },
    "legal-person": {
      "label": "A Legal guarantor",
      "description": "Example : a company"
    },
    "no-guarantor": {
      "tenant": "I don't have a guarantor",
      "cotenant": "My partner doesn't have a guarantor"
    },
    "ask-guarantor": "Your guarantor is:",
    "optional-guarantor": "Adding a guarantor is not mandatory. If you don't have a guarantor, you can select <em>no guarantor</em> at the bottom of the page to proceed to the next step.",
    "two-guarantors-warning": "If you have 2 guarantors, add them one after the other. <strong>The documents of your 2 guarantors should not be mixed</strong>.",
    "type-required": "Please select an option",
    "visale-title": "Do you know about Visale?",
    "visale-text": "Visale is the guarantor for your future accommodation if you are between 18 and 30 years old OR if you are over 30 years old and employed (subject to conditions).",
    "visale-btn": "Discover Visale"
  },
  "guarantordocuments": {
    "identification": "Identification Document",
    "residency": "Proof of Residence",
    "professional": "Proof of Professional Status",
    "financial": "Proof of Income",
    "tax": "Tax Notice",
    "guarantor": "Guarantor",
    "validate": "Validate",
    "will-delete-guarantor": "Do you really want to change the type of guarantor?",
    "validate-file": "Next Step - Validate Application",
    "warning-need-residency-documents": "Are you sure you have provided all the necessary documents here?",
    "list1": "Proof of accommodation",
    "list2": "Copy of the host's identity document",
    "list3": "Proof of residence of the host, dated within the last 3 months"
  },
  "guarantordocumentspage": {},
  "guarantorfinancialdocumentform": {
    "financial": "Proof of Income",
    "monthlySum": "Amount in Euros",
    "monthlySum-label": "I indicate the amount of their monthly net income to be paid (before withholding tax)",
    "high-salary": "You have entered a salary greater than €10,000. Are you sure you have entered the monthly salary?",
    "low-salary": "You have entered a salary equal to €0. Are you sure you have entered the monthly salary?",
    "number-not-valid": "Incorrect value - enter a number without a comma",
    "delete-financial": "Delete this income",
    "field-required": "This field is required",
    "register": "Register",
    "select-label": "Please provide only the guarantor's income.",
    "missing-file": "You must add files to save this income.",
    "warning-no-income-and-file": "You cannot have files and indicate that you cannot provide all the files. Please uncheck the box or delete your files."
  },
  "guarantorfinancial": {
    "delete-financial": "Delete this income",
    "register": "Register",
    "select-label": "Please provide only your own income.",
    "i-have-no-income": "I have no income",
    "warning-no-income-and-file": "You cannot have files and indicate that you cannot provide all the files. Please uncheck the box or delete your files.",
    "title": "Summary of Guarantor's Income",
    "subtitle": "Here is the list of income you have declared. You can add new income at any time if necessary.",
    "monthly": " monthly net €",
    "net-monthly": "Net Monthly",
    "add-income": "Add new income?"
  },
  "guarantorfooter": {},
  "guarantoridentification": {
    "identity-card": "French Identity Card",
    "passport": "French Passport",
    "permit": "French Residence Permit",
    "other": "Other",
    "files": "Documents",
    "will-delete-files": "Attention, a change in your situation will result in the deletion of the documents. You will need to upload the documents again.",
    "register": "Save Document",
    "title": "I am adding the valid identification document of my guarantor",
    "title-cotenant": "I am adding the valid identification document of their guarantor",
    "select-label": "Please add the front and back!",
    "validate": "Validate",
    "cancel": "Cancel",
    "field-required": "This field is required"
  },
  "guarantorlistpage": {
    "my-guarantor": "My Guarantors",
    "add-new-guarantor": "Add a New Guarantor?",
    "guarantor": {
      "NATURAL_PERSON": "My Guarantor",
      "LEGAL_PERSON": "My Guarantor Company",
      "ORGANISM": "My Guarantor Organization"
    },
    "remove-guarantor": "Are you sure you want to remove this guarantor?"
  },
  "guarantorname": {
    "lastname": "Last Name",
    "firstname": "First Name",
    "lastname-placeholder": "e.g. Dupont",
    "firstname-placeholder": "e.g. Jean",
    "field-required": "This field is required",
    "title": "Guarantor's Identity",
    "subtitle": "I provide the last name and first name of my guarantor"
  },
  "guarantorprofessional": {
    "title": "Proof of Professional Status",
    "title-cotenant": "Proof of Professional Status",
    "cdi": "Permanent Contract",
    "cdi-trial": "Permanent Contract (Trial Period)",
    "cdd": "Fixed-Term Contract",
    "alternation": "Apprenticeship/Training",
    "internship": "Internship",
    "student": "Student",
    "public": "Public Service",
    "ctt": "Temporary Work Contract",
    "retired": "Retired",
    "unemployed": "Unemployed",
    "independent": "Self-employed",
    "other": "Other",
    "will-delete-files": "Attention, a change in your situation will result in the deletion of your documents. You will need to upload the corresponding documents again.",
    "register": "Save",
    "select-label": "Current professional status of my guarantor:",
    "select-label-cotenant": "Current professional status of their guarantor:"
  },
  "guarantorresidency": {
    "tenant": "Tenant",
    "owner": "Owner",
    "guest": "Free Accommodation",
    "guest-parents": "Living with Parents",
    "files": "Documents",
    "will-delete-files": "Attention, a change in your situation will result in the deletion of your documents. You will need to upload the corresponding documents again.",
    "register": "Save",
    "select-label": "Current housing situation of your guarantor:"
  },
  "guarantorssection": {
    "guarantors-information": "Guarantor's Documents",
    "my-guarantor": "Documents of",
    "identification-legal-person": "Legal Entity Identification",
    "organism-identification": "Organization Certificate",
    "delete-guarantor": "Delete this guarantor",
    "confirm-delete-guarantor": "Are you sure you want to delete this guarantor?",
    "add-guarantor": "Add a Guarantor?",
    "visale-title": "Do you know about Visale?",
    "visale-text": "Visale is the guarantor for your future accommodation if you are between 18 and 30 years old OR if you are over 30 years old and employed (subject to conditions).",
    "go-to-visale": "Go to the 'Visale' website (new window)",
    "identification": "Identification Document",
    "residency": "Proof of Residence",
    "professional": "Proof of Professional Status",
    "financial": "Proof of Income",
    "tax": "Tax Notice",
    "TO_PROCESS": "is being processed",
    "VALIDATED": "is verified",
    "DECLINED": "requires modification",
    "INCOMPLETE": "is incomplete"
  },
  "guarantortax": {
    "custom-text": "To improve your application, please explain below why you do not receive a tax notice. Your explanation will be added to your application:",
    "files": "Documents",
    "register": "Save",
    "field-required": "This field is required",
    "will-delete-files": "Attention, a change in your situation will result in the deletion of your documents. You will need to upload the corresponding documents again.",
    "title": "Tax Notice of my Guarantor",
    "title-cotenant": "Tax Notice of their Guarantor",
    "situation": "What is their tax situation?",
    "warning-no-accepted-doc": "Attention, the declaration of tax situation is not accepted.",
    "goto-documentation": "View documentation",
    "my-name-cotenant": "I have a tax notice in the guarantor's name",
    "less-than-year-cotenant": "The guarantor has been in France for less than a year",
    "other-tax-cotenant": "Other"
  },
  "identification-page": {
    "files": "Documents",
    "will-delete-files": "Attention, a change in your situation will result in the deletion of your documents. You will need to upload the corresponding documents again.",
    "select-label": "Upload a valid identification document",
    "validate": "Validate",
    "cancel": "Cancel"
  },
  "joincouple": {
    "error": "Error",
    "password-update": "The password has been updated",
    "title": "Create Password",
    "already-logged": "You are already in connected mode. Do you want to log out to create this account?",
    "token-expired": "The link has expired. Please initiate the forgot password procedure again."
  },
  "joingroup": {
    "error": "Error",
    "password-update": "The password has been updated",
    "title": "Create Password",
    "already-logged": "You are already in connected mode. Do you want to log out to create this account?",
    "bad-request": "Provided token in URL sounds wrong",
    "token-expired": "The link has expired. Please initiate the forgot password procedure again."
  },
  "listitem": {
    "FRENCH_IDENTITY_CARD": "French Identity Card",
    "FRENCH_PASSPORT": "French Passport",
    "FRENCH_RESIDENCE_PERMIT": "French Residence Permit",
    "OTHER_IDENTIFICATION": "Other",
    "remove": "Remove Document",
    "show": "Show Document",
    "see": "See",
    "delete": "Delete",
    "mb": "MB",
    "kb": "KB",
    "will-delete-file": "Are you sure you want to delete this file?"
  },
  "menu": {
    "help": "Help",
    "file": "Your File",
    "applications": "Your applications",
    "partners": "Your shared data",
    "account": "My Account",
    "messaging": "Messaging",
    "blog": "Blog",
    "information": "About Us",
    "contact-us": "Contact Us",
    "deleteAccount": "Delete My Account"
  },
  "messagespanel": {
    "identification-legal-person": "Legal Entity Identification",
    "identity-represent": "Representative Identity",
    "organism": "Organization"
  },
  "nameinformationform": {
    "confirm": "Confirm",
    "firstname": "First Name",
    "lastname": "Last Name",
    "preferredname": "Preferred Name",
    "add-preferredname": "Add a Preferred Name",
    "delete-preferredname": "Delete Preferred Name",
    "zipcode": "Postal Code (only if you reside in France)",
    "abroad-residency-checkbox": "You are currently residing abroad.",
    "field-required": "This field is required",
    "title": "Let's start with your personal identity information.",
    "subtitle": "Please provide the information of the person whose name will appear on the lease agreement.",
    "unlink-fc-link": "Modify your information?",
    "unlink-fc-content": "In order to make changes to your name and first name, you need to unlink your FranceConnect and DossierFacile accounts.",
    "unlink-fc-content-password-exists": "You can then use the password you previously set.",
    "unlink-fc-content-password-undefined": "You will then be redirected to the password update page.",
    "unlink-account-btn": "Unlink My Accounts"
  },
  "nameinformationhelp": {
    "info": "If you are filling out the form on behalf of someone else, such as your child, please provide their information instead of yours.",
    "info-fc": "The form must be in the name of the prospective tenant. If you are filling out the form on behalf of someone else, such as your child, you need to ",
    "unlink-fc": "update this information"
  },
  "nameinformation": {
    "title": "Your Rental File in 5 Documents!",
    "join-title": "You're just a few steps away from joining {0}'s rental file!",
    "roommate": "your roommate",
    "spouse": "your spouse",
    "prepare-documents": "Prepare your supporting documents:",
    "identification": "Identification Document",
    "residency": "Proof of Address",
    "professional": "Proof of Employment",
    "financial": "Proof of Financial Resources",
    "tax": "Tax Notice",
    "same-for-guarantors": "And the same list for your guarantors!",
    "see-documentation": "See our documentation"
  },
  "ownerbanner": {
    "title1": "Property Owner,",
    "title2": "Find Your Perfect Tenant!",
    "text1": "100% free space",
    "text2": "Centralize your applications",
    "text3": "All files are complete!",
    "btn": "Create my owner account"
  },
  "ownershare": {
    "title": "Apply for the housing located at {0}",
    "subtitle": "DossierFacile is a government startup that allows you to create your smart rental file and share it with property owners",
    "login-success": "Login successful",
    "login-error": "Login problem",
    "accept-owner": "I accept that {0}, the owner located at {1}, has access to the information in my file and that of my spouse or roommates, if applicable. In accordance with the GDPR, I can revoke this authorization at any time.",
    "field-required": "This field is required",
    "connect-owner": "Let's get started!",
    "no-account-1": "If you don't have an account, you can <a href=\"/signup\">create one</a> and come back to this page later.",
    "no-account-2": "Our file is easy to fill out (less than 3 minutes, promised) and it complies with the law.",
    "no-account-3": "And reusable for all your other visits!",
    "connection-success": "Your file has been successfully shared with the owner",
    "join-account": "As a guest account, you cannot link your file to an owner account. Please ask the account that invited you to do so.",
    "login": "Log in"
  },
  "partnerssection": {
    "partners-services-section": "Do you know the following services?",
    "mds-title": "Are you eligible?",
    "mds-text": "Discover in a few clicks the social benefits you can apply for",
    "mds-btn": "Simulate my social rights",
    "anil-title": "Housing question?",
    "anil-text": "Practical information about your rights and obligations? Your ADIL advises you for free",
    "anil-btn": "Contact my ADIL",
    "go-to-anil": "Go to ANIL website (new window)",
    "go-to-mds": "Go to \"mes droits sociaux\" website (new window)",
    "go-to-signal": "Go to \"ma securité\" website (new window)",
    "signal-title": "Report a fake listing!",
    "link-title": "Go to masecurite.fr website (new window)",
    "signal-text": "If you think you are dealing with a scam in a classified ad, {nodocument}, and report it on {masecurite}, the website of the Ministry of the Interior.",
    "nodocument": "do not send any document",
    "masecurite": "Ma Sécurité",
    "signal-btn": "Report a listing"
  },
  "professional-page": {
    "cdi": "Permanent contract",
    "cdi-trial": "Permanent contract (trial period)",
    "cdd": "Fixed-term contract",
    "alternation": "Apprenticeship",
    "internship": "Internship",
    "student": "Studies",
    "public": "Public service",
    "ctt": "Temporary work",
    "retired": "Retirement",
    "unemployed": "Unemployment",
    "independent": "Self-employed",
    "other": "Other",
    "will-delete-files": "Attention, changing your situation will delete your supporting documents. You will need to upload the documents corresponding to your new situation again.",
    "select-label": "Your current employment situation:"
  },
  "profilefooter": {
    "back": "Back",
    "continue": "Continue"
  },
  "publicfile": {
    "title": "{0}'s Tenant File",
    "description": "{0} {1}",
    "guarant": "Guarantor's Documents",
    "personnal-file": "Applicant's Documents",
    "identification": "Identification Document",
    "residency": "Proof of Address",
    "professional": "Proof of Employment",
    "financial": "Proof of Financial Resources",
    "tax": "Tax Notice",
    "tax-verified": "Certified income tax with tax authorities",
    "ALONE": "Alone",
    "COUPLE": "Couple",
    "GROUP": "Roommates",
    "no-income": "without income",
    "income": "with a net monthly income of {0}€",
    "organism": "Organization Certificate",
    "identification-legal-person": "Legal Entity Identification",
    "and": " and "
  },
  "representativeidentification": {
    "organism-name": "Name of the representative of the legal entity",
    "organism-name-placeholder": "Name",
    "identity-card": "French Identity Card",
    "passport": "French Passport",
    "permit": "French Residence Permit",
    "other": "Other"
  },
  "residency-page": {
    "will-delete-files": "Attention, changing your situation will delete your supporting documents. You will need to upload the documents corresponding to your new situation again.",
    "select-label": "Describe your current housing situation",
    "warning-other-residency": "If you are a tenant or you are accommodated free of charge, please select the relevent option, otherwise your file won't be validated.",
    "warning-incomplete": "Incomplete files (those that do not include these 3 documents) will not be validated.",
    "warning-only-rent-receipt": "Proof of residence (housing tax, electricity bill, telephone bill, etc.) are not accepted here. Only your receipts (or a certificate of good payment of rents) allow a landlord to ensure that you pay your rents.",
    "warning-tax": "The tax notice is not an accepted document here."
  },
  "roommatesinformationhelp": {
    "paragraph1": "By providing your roommate's email address, they will be able to create their own file with their documents. Your two files will be linked, and your shared file link will be common.",
    "paragraph2": "Concretely, your roommate will receive an invitation that they will need to validate within 48 hours.",
    "paragraph3": "In case your roommate couldn't confirm their registration within 48 hours, you will need to start over!"
  },
  "roommatesinformation": {
    "roommateEmail": "Add a roommate's email address",
    "acceptAuthor": "I accept that other members of my shared housing have access to my documents and those of my guarantor, if applicable, once all the shared files have been validated",
    "delete": "Delete",
    "field-required": "This field is required",
    "title": "Who will be your roommates?",
    "add-a-roommate": "Invite this roommate",
    "invite-waiting": "Invitation waiting to be sent",
    "invite-sent": "Invitation sent",
    "my-roommates": "My roommates",
    "email-exists": "You cannot associate two accounts with the same email address! <br>Please provide a different email address.",
    "email-exists-2": "This email address is already used on DossierFacile. <br>Please provide a different email address.",
    "more-information": "Why is this information useful to us?",
    "require-accept": "You must accept the declaration"
  },
  "sharefile": {
    "title": "Share your file",
    "desc1": "Enter the recipient's email address below to share your file. Each recipient receives a unique link that you can disable whenever you wish.",
    "desc2": "If you do not have the recipient's email address, you can send <a href=\"{0}\">the link to your file</a> via messaging or SMS, for example.",
    "desc3": "Reminder: DossierFacile does not offer housing.",
    "lastvisit": "Last visit :",
    "share-btn-email": "Share",
    "share-btn-copy": "Copy",
    "bytype-label": "Share your file",
    "full": "With supporting documents",
    "resume": "Without supporting documents",
    "by-mail": "By email",
    "by-link": "By link",
    "email-label": "Landlord's Email:",
    "email-placeholder": "landlord{'@'}example.com",
    "sent-success": "Your file has been successfully shared",
    "view": {
      "title": "See your file",
      "text": "See your DossierFacile before to share it ?",
      "button": "See your file"
    },
  },
  "showdoc": {
    "preview": "Document preview loading…",
    "error": "Error in preview",
    "number-of-pages": "Preview page 1 / {0}",
    "loading": "Loading"
  },
  "signuppage": {
    "connect": "Already registered? Log in",
    "mail-sent": "An email has been sent to the provided address.",
    "clic-to-confirm": "Please click on the link sent to confirm your email address and proceed with your registration.",
    "duplicate-email": "This email is already in use",
    "register-error": "An error occurred",
    "my-file": "My rental file with ",
    "my-file-subtitle": "You are about to complete your rental file",
    "my-file-subtitle2": "verified by the government!",
    "help-you": "helps you:"
  },
  "simulationcaf": {
    "btn-caf": "Simulate",
    "simulation-caf-title": "Are you eligible?",
    "simulation-caf-text": "Discover the estimation of housing assistance you could be eligible for."
  },
  "taxhelp": {
    "title": "Help",
    "paragraph1": "You can now <b>download a tax notice from <a target='_blank' href='https://www.impots.gouv.fr'>www.impots.gouv.fr</a></b>",
    "paragraph2": "If your document is too large, we recommend scanning it (using a free smartphone application, for example).<br> If that doesn't work, contact us by email at <a href='mailto:contact{'@'}dossierfacile.logement.gouv.fr'>contact{'@'}dossierfacile.logement.gouv.fr</a>, and we will do our best to respond as quickly as possible!"
  },
  "tax-page": {
    "my-name": "You have a tax notice in your name",
    "my-parents": "You are tax dependant on your parents",
    "less-than-year": "You have been in France for less than a year",
    "other-tax": "Other",
    "accept-verification": "I agree that DossierFacile performs an automated verification of my tax notice with the tax authorities",
    "custom-text": "To improve your file, please explain below why you don't receive a tax notice. Your explanation will be added to your file:",
    "files": "Documents",
    "register": "Register",
    "field-required": "This field is required",
    "will-delete-files": "Attention, changing your situation will delete your supporting documents. You will need to upload the documents corresponding to your new situation again.",
    "title": "My tax notice",
    "warning-no-accepted-doc": "Warning, the declarative situation notice is not accepted.",
    "goto-documentation": "View documentation",
    "avis-detected": "Declarative Situation Notice Detected",
    "avis-text1": "You have provided a declarative statement notice (see document title). This document is not valid. Please replace it with your tax assessment notice.",
    "avis-btn": "Submit a valid document",
    "avis-link-to-doc": "Need help ? Go to documentation"
  },
  "tenantdocument": {},
  "tenantguarantorchoice": {
    "cotenant-guarantor": "His/her guarantor",
    "identification": "Identification Document",
    "residency": "Proof of Residence",
    "professional": "Proof of Professional Situation",
    "financial": "Proof of Income",
    "tax": "Tax Notice",
    "representative-identification": "Legal Entity Identity",
    "corporation-identification": "Legal Entity Representative Identity",
    "guarantor": "Guarantor",
    "validate": "Validate",
    "will-delete-guarantor": "Are you sure you want to change the guarantor type?",
    "validate-file": "Next Step - Validate the file",
    "ask-guarantor": "Their guarantor is:",
    "optional-guarantor": "Adding a guarantor is not mandatory. If your partner doesn't have a guarantor, you can select <em>no guarantor</em> at the bottom of the page to proceed to the next step.",
    "two-guarantors-warning": "If your partner has 2 guarantors, add them one after the other. <strong>The documents of the 2 guarantors must not be mixed</strong>.",
    "type-required": "Please select an option",
    "visale-title": "Are you familiar with Visale?",
    "visale-text": "Visale is the guarantor for your future accommodation if you are between 18 and 30 years old OR if you are over 30 and employed (subject to conditions).",
    "visale-btn": "Discover Visale"
  },
  "tenantguarantordocuments": {
    "identification": "Identification Document",
    "residency": "Proof of Residence",
    "professional": "Proof of Professional Situation",
    "financial": "Proof of Income",
    "tax": "Tax Notice",
    "guarantor": "Guarantor",
    "validate": "Validate",
    "will-delete-guarantor": "Are you sure you want to change the guarantor type?",
    "validate-file": "Next Step - Validate the file"
  },
  "tenantguarantordocumentspage": {},
  "tenantguarantorlist": {
    "my-guarantor": "My Spouse's Guarantors",
    "add-new-guarantor": "Add a New Guarantor?",
    "guarantor": {
      "NATURAL_PERSON": "Their Guarantor",
      "LEGAL_PERSON": "Their Guarantor Company",
      "ORGANISM": "Their Guarantor Organization"
    },
    "EMPTY": "Absent",
    "TO_PROCESS": "In Progress",
    "VALIDATED": "Verified",
    "DECLINED": "Modification Requested",
    "INCOMPLETE": "Incomplete",
    "remove-guarantor": "Are you sure you want to remove this guarantor?"
  },
  "tenantguarantorname": {
    "lastname": "Last Name",
    "firstname": "First Name",
    "lastname-placeholder": "e.g., Dupont",
    "firstname-placeholder": "e.g., Jean",
    "field-required": "This field is required",
    "title": "Spouse's Guarantor Identity",
    "subtitle": "Please provide the last name and first name of the guarantor"
  },
  "tenantguarantorspage": {},
  "tenantinformationform": {
    "title": "Create a Rental Application to Live:",
    "confirm": "Confirm",
    "firstname": "Tenant's First Name",
    "lastname": "Tenant's Last Name",
    "zipcode": "Postal Code",
    "tenantPresentation": "The tenant will be {firstname} {lastname}. You want to rent a property:",
    "alone": "Alone",
    "couple": "As a Couple",
    "roommate": "With Roommates",
    "remove-roommates": "Attention, this will dissociate your application from your roommates",
    "remove-couple": "Attention, this will dissociate your application from your spouse",
    "error": "An error occurred",
    "acceptAuthorSpouse": "I accept that my partner has access to my documents as well as those of my guarantor, if applicable, once both our applications are validated",
    "acceptAuthorCoTenant": "I accept that the other members of my co-tenancy have access to my documents as well as those of my guarantor, if applicable, once all co-tenancy applications are validated",
    "validate": "Validate",
    "roommates-saved": "Invitation sent to your roommates. Your roommates have been successfully\nadded, and an account creation invitation has been sent to them.",
    "couple-saved": "Your spouse has been successfully added.\nIf an email has been provided, an account creation invitation has been sent to them.",
    "email-exists": "This email address is already in use on DossierFacile.\nPlease provide a different email address.",
    "roommate-email-required": "You must enter the email address of at least one roommate.",
    "couple-email-required": "You must enter the email address of your spouse.",
    "will-delete-couple": "This action will delete your spouse's application",
    "will-delete-roommates": "This action will remove the association with your roommates' applications",
    "require-accept": "You must accept the declaration"
  },
  "tenantpanel": {
    "title": "Hello {0}, Your {1} Application!",
    "last-update": "Last update to the application on {0}",
    "file-update-title": "Update Your Application",
    "file-update-text": "You have updated your application, last updated on {0}.<br>To keep it compelling, it's important to keep your supporting documents up to date.",
    "update-file-btn": "Update My Documents",
    "copy-link": "Copy My Application Link",
    "share-by-mail": "Share by Email",
    "my-file": "My Rental Application",
    "my-files": "Documents of ",
    "clarification-title": "Clarification",
    "identification": "ID Document",
    "residency": "Proof of Address",
    "professional": "Proof of Employment",
    "financial": "Proof of Income",
    "tax": "Tax Assessment",
    "TO_PROCESS": "is being processed",
    "VALIDATED": "is verified",
    "DECLINED": "requires modification",
    "INCOMPLETE": "is incomplete",
    "delete": "Delete My Account",
    "opinion": "Tell us about your DossierFacile.fr experience",
    "delete-account": "Delete My Account",
    "share-file": "Share My Application",
    "share-file-description": "Copy your application link to share it! You can send it to landlords or property owners of your choice (via email, SMS, etc.)",
    "file-resume": "Share My Summary Application<br>(without supporting documents)",
    "file-full": "Share My Complete Application<br>(with supporting documents)",
    "copy": "Copy",
    "field-required": "This field is required",
    "CDI": "permanent contract",
    "CDI_TRIAL": "permanent contract (trial period)",
    "CDD": "fixed-term contract",
    "ALTERNATION": "apprenticeship",
    "INTERNSHIP": "internship",
    "STUDENT": "student",
    "PUBLIC": "in public service",
    "CTT": "temporary work",
    "RETIRED": "retired",
    "UNEMPLOYED": "unemployed",
    "INDEPENDENT": "self-employed",
    "OTHER": "Other",
    "no-income": "not to have any income",
    "income": "to have a monthly net income of {0}€",
    "copied": "Copied!",
    "ALONE": "alone",
    "couple-with": "as a couple with {0}",
    "group-with": "in a colocation with {0}",
    "group-with-someone": "in a colocation",
    "someone": " someone",
    "spouse-cannot-copy-link": "Your link is inactive because your spouse's application is not yet validated",
    "cotenant-cannot-copy-link": "Your link is inactive because the applications of your roommate(s) are not yet validated",
    "amendment-required-title": "Modification Required",
    "amendment-required-text": "After reviewing your application, modifications are required. <br>Check your messages for details.",
    "messaging": "Check My Messages",
    "congratulations-title": "🎉 Congratulations! Your DossierFacile is Ready!",
    "congratulations-text-1": "To apply for your dream accommodation, share your application with a landlord of your choice, with or without supporting documents. You can do this by email, by providing their email address in the \"Share My Application\" section below, or by sending your DossierFacile link via email, SMS, etc. to the landlords or property owners of your choice.",
    "congratulations-text-2": "Please note that DossierFacile does not provide accommodation.",
    "full-link-copied": "My Complete Application Link is Copied!",
    "public-link-copied": "My Summary Application Link is Copied!"
  },
  "lefteditmenu": {
    "ALONE": "Alone",
    "COUPLE": "Couple",
    "GROUP": "Group",
    "identity": "Identity",
    "file-type": "File Type"
  },
  "topeditmenu": {
    "i-add": "I Add {0}",
    "my-guarantor": "My Guarantor"
  },
  "updatecomponent": {
    "invalid": "Invalid document",
    "force-message": "You think the automatic check is making an error. Provide the details that will clarify your situation"
  },
  "uploaddocuments": {
    "warning-need-residency-documents": "Are you sure you have submitted all the necessary documents here?<ul><li>Proof of residence</li><li>Copy of your host's identity document</li><li>Proof of address (less than 3 months old) of your host</li></ul>",
    "accept-warning": "Add new documents",
    "ignore-warning": "Proceed to the next step",
    "warning-need-residency-documents-tenant": "<p>Are you sure you have properly submitted your last 3 rent receipts (or a proof of good payment of rent)? A rent receipt indicates to a landlord that you are paying your rent on time. <strong>A document that only mentions your address (such as an EDF bill) is not sufficient in this case.</strong></p><p>This is a very common mistake, and your application cannot be approved without these 3 rent receipts.</p>"
  },
  "validatefile": {
    "title": "I Validate My File",
    "read-no-guarantor": "I read and check the following box to validate my file",
    "declaration": "I declare that I am aware of Article 441-1 of the Penal Code, which penalizes forgery and the use of forgery with three years of imprisonment and a fine of 45,000 euros.",
    "precision": "If I wish, I can provide specific important information about my situation to my future landlords. My text will be added at the beginning of my file:",
    "placeholder": "Enter your comment here",
    "validate": "Validate my file",
    "read": "I read and check the following boxes to validate my file",
    "declaration2": "I declare that I have obtained the consent of the individuals (spouse, roommates, or guarantors) to use their personal documents and associate them with my file.",
    "declaration2-plural": "I solemnly declare that I have obtained the consent of my guarantors for their data to be processed within the rental process.",
    "require-accept": "You must accept the declaration",
    "validation-error-title": "You're almost there!",
    "validation-error-description": "Limit modifications and make sure to validate your file today."
  },
  "modal": {
    "close": "Close",
    "close-alt": "Close modal window"
  },
  "sharing-page": {
    "title": "Your applications",
    "file-not-ready": {
      "title": "Your file {0}",
      "status": {
        "TO_PROCESS": "is being processed",
        "DECLINED": "requires modifications",
        "INCOMPLETE": "is incomplete",
        "VALIDATED": "is not fully validated"
      },
      "explanation": "As soon as it is validated, you will be able to share it and to manage the shared files below."
    },
    "shared-links": {
      "title": "Shared files by email",
      "date": "Date",
      "contact": "Contact",
      "last-visit": "Last visit",
      "application-type": "Application type",
      "link-status": "Status",
      "action": "Action",
      "enabled": "Enabled",
      "disabled": "Disabled",
      "delete": "Delete",
      "never": "Never",
      "no-shared-file": "No shared files yet",
      "resend": "Resend",
      "resend-success": "Resend succeed.",
      "resend-failed": "resend Failed! Try again later"
    }
  },
  "partners-page": {
    "title": "My shared data",
    "accesses": {
      "title": "Partners with access to my data",
      "date": "Date",
      "since-date": "Since ",
      "default-date": "Since 2023",
      "partner": "Partner",
      "action": "Action",
      "revoke": "Revoke",
      "no-access": "You didn't give access to any partner"
    }
  },
  "404": {
    "title": "Page not found",
    "subtitle": "Error 404",
    "caption": "This is not the web page you are lookingfor.",
    "content": {
      "line1": "If you typed the web address in the browser, make sure it is correct. The page may no longer be available.",
      "line2": "In this case, to continue your visit, you can check out our homepage.",
      "line3": "Otherwise, contact us so that we can redirect you to the right information."
    },
    "homepage": "Homepage",
    "contact-us": "Contact us"
  },
  "contact": {
    "title": "How can we help you?"
  },
  "declined-messages": {
    "header": "Message from our validation team:"
  }
}
