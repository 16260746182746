{
  "register": "Enregistrer",
  "logout": "Se déconnecter",
  "signin": "Se connecter",
  "owner-header": "Espace propriétaire",
  "partner": "Devenir partenaire",
  "partner-link-title": "Devenir partenaire (Nouvelle fenêtre)",
  "identity-card": "Carte d’identité",
  "passport": "Passeport",
  "permit": "Titre de séjour français",
  "drivers-license": "Permis de conduire",
  "france-identite": "France Identité",
  "other": "Autre",
  "cdi": "CDI",
  "cdi-trial": "CDI (période d’essai)",
  "cdd": "CDD",
  "alternation": "Alternance",
  "internship": "Stage",
  "student": "Études",
  "public": "Fonction publique",
  "ctt": "CTT (intérimaire)",
  "retired": "Retraite",
  "unemployed": "Chômage",
  "independent": "Indépendant",
  "intermittent": "Intermittence",
  "stay-at-home-parent": "Parent au foyer",
  "no-activity": "Sans emploi",
  "artist": "Artiste-auteur",
  "my-name": "Vous avez un avis d’imposition à votre nom",
  "my-parents": "Vous êtes rattaché fiscalement à vos parents",
  "less-than-year": "Vous êtes en France depuis moins d’un an",
  "other-tax": "Autre situation",
  "visale": "Garantie Visale",
  "other-guarantee": "Autre organisme",
  "save-success": "Enregistrement réussi",
  "save-failed": "Erreur lors de l'enregistrement",
  "save-failed-num-pages": "Nombre de pages maximum atteint, veuillez en supprimer",
  "delete-success": "Suppression réussie",
  "delete-failed": "Erreur lors de la suppression",
  "max-file-default": "Nombre de fichier maximum atteint, veuillez en supprimer",
  "max-file": "Nombre de fichiers maximum atteint (actuellement : {0} / maximum : {1}), veuillez en supprimer",
  "error": "Une erreur est survenue",
  "unable-to-copy": "Impossible de copier",
  "strength-not-valid": "Le mot de passe est trop simple",
  "confirm-not-valid": "Le mot de passe ne correspond pas",
  "email-not-valid": "Email non valide",
  "zipcode-not-valid": "Code postal non valide.",
  "only-alpha": "Seuls les caractères alphabétiques ainsi que [ , -, '] sont autorisés",
  "same-email-not-valid": "L'email ne peut pas être la même que votre adresse",
  "regex-not-valid": "Champ non valide",
  "field-required": "Ce champ est requis",
  "select-is-empty": "Sélection requise",
  "identification-legal-person": "Identité personne morale",
  "identity-represent": "Identité représentant",
  "guarantee-provider-certificate": "Certificat de garantie",
  "identification": "Pièce d'identité",
  "residency": "Situation d'hébergement",
  "professional": "Situation professionnelle",
  "financial": "Justificatif de ressources",
  "tax": "Avis d'imposition",
  "personal-information": "Je renseigne mes informations",
  "last-update": "Dernière mise à jour le {0}",
  "my-document": "Je joins mes documents",
  "my-guarantor": "J'ajoute mes éventuels garants",
  "my-cotenant": "Les documents de mon conjoint",
  "my-cotenant-guarantor": "Le(s) garant(s) de mon conjoint",
  "validate-file": "Je valide mon dossier",
  "noDocument-social": "Je ne peux pas fournir de justificatifs de versement de prestations sociales",
  "noDocument-salary": "Je ne peux pas fournir de document permettant de justifier ces revenus",
  "noDocument-pension": "Je ne peux pas fournir de justificatifs de versement de pension",
  "noDocument-rent": "Je ne peux pas fournir de justificatifs de versement de rente",
  "noDocument-scholarship": "Je ne peux pas fournir de justificatifs d'attribution de bourse",
  "noDocument-social-service": "Je ne peux pas fournir de justificatifs de versement de prestations sociales",
  "EMPTY": "Absent",
  "TO_PROCESS": "En cours de traitement",
  "VALIDATED": "Vérifié",
  "DECLINED": "Modification demandée",
  "INCOMPLETE": "Non terminé",
  "try-again": "Une erreur est survenue, veuillez réessayer plus tard.",
  "example-prefix": "Ex : ",
  "next-step": "Passer à l'étape suivante",
  "add-new-documents": "Ajouter de nouveaux documents",
  "ALONE": "Seul·e",
  "COUPLE": "Couple",
  "GROUP": "Colocation",
  "dossier": {
    "TO_PROCESS": "En cours de traitement",
    "VALIDATED": "Vérifié",
    "DECLINED": "Modification demandée",
    "INCOMPLETE": "Incomplet",
    "ARCHIVED": "Archivé",
    "warn-TO_PROCESS": "En cours de traitement",
    "warn-VALIDATED": "VALIDÉ",
    "warn-DECLINED": "REFUSÉ",
    "warn-INCOMPLETE": "INCOMPLET",
    "warn-ARCHIVED": "Archivé"
  },
  "documents": {
    "identity-card": "Carte d’identité",
    "passport": "Passeport",
    "permit": "Titre de séjour français",
    "drivers-license": "Permis de conduire",
    "france-identite": "France Identité",
    "other": "Autre",
    "tenant": "Locataire",
    "owner": "Propriétaire",
    "guest": "Hébergé par vos parents, par un ami, par un proche",
    "guest-company": "Logement de fonction",
    "guest-organism": "Hébergé par un organisme (hébergement d’urgence, placement)",
    "short-term-rental": "À l’hôtel, au camping ou en location de tourisme (B&B)",
    "other-residency": "Dans une autre situation (sans-abri, etc.)",
    "cdi": "CDI",
    "cdi-trial": "CDI (période d’essai)",
    "cdd": "CDD",
    "alternation": "Alternance",
    "internship": "Stage",
    "student": "Études",
    "public": "Fonction publique",
    "ctt": "CTT (intérimaire)",
    "retired": "Retraite",
    "unemployed": "Chômage",
    "independent": "Indépendant",
    "intermittent": "Intermittence",
    "stay-at-home-parent": "Parent au foyer",
    "no-activity": "Sans emploi",
    "artist": "Artiste-auteur",
    "salary": "Revenus professionnels",
    "guarantor_salary": "Revenus professionnels",
    "social-service": "Prestations sociales",
    "rent": "Rentes",
    "pension": "Pensions",
    "scholarship": "Bourses",
    "no-income": "Pas de revenus",
    "status": {
      "TO_PROCESS": "En cours de traitement",
      "VALIDATED": "Vérifié",
      "DECLINED": "Modification demandée",
      "INCOMPLETE": "Non terminé",
      "EMPTY": "Document manquant"
    },
    "subcategory": {
      "SALARY": "Revenus professionnels",
      "RENT": "Rentes",
      "SOCIAL_SERVICE": "Prestations sociales",
      "PENSION": "Pensions",
      "SCHOLARSHIP": "Bourses",
      "NO_INCOME": "Pas de revenus"
    }
  },
  "explanation-text": {
    "tenant": {
      "professional": {
        "alternation": "Merci d’ajouter ici votre <strong>contrat d'apprentissage ou de professionnalisation</strong> en cours ou à venir. Il doit être <strong>complet</strong> et <strong>signé</strong> par toutes les parties (vous, votre tuteur, l’employeur etc.)",
        "cdd": "<p>Merci d’ajouter ici votre <strong>contrat de travail.</strong> Il doit être en <strong>cours ou à venir</strong> (les CDD terminés ne sont pas acceptés), <strong>complet</strong> (toutes les pages) et <strong>signé</strong> par vous et votre employeur.</p><p>Conseil : n’alourdissez pas inutilement votre dossier avec des documents annexes, le contrat complet suffit.</p></p>Si vous ne disposez pas de votre contrat de travail, vous pouvez ajouter une <strong>attestation de votre employeur de moins de 3 mois.</strong>",
        "cdi": "<p>Merci d’ajouter ici votre <strong>contrat de travail</strong> en cours ou à venir. Il doit être <strong>complet</strong> (toutes les pages) et <strong>signé</strong> par vous et votre employeur.</p><p>Conseil : n’alourdissez pas inutilement votre dossier avec des documents annexes, le contrat complet suffit.</p></p>Si vous ne disposez pas de votre contrat de travail, vous pouvez ajouter une <strong>attestation de votre employeur de moins de 3 mois.</strong>",
        "cdi-trial": "<p>Merci d’ajouter ici votre <strong>contrat de travail</strong> en cours ou à venir. Il doit être <strong>complet</strong> (toutes les pages) et <strong>signé</strong> par vous et votre employeur.</p><p>Conseil : n’alourdissez pas inutilement votre dossier avec des documents annexes, le contrat complet suffit.</p></p>Si vous ne disposez pas de votre contrat de travail, vous pouvez ajouter une <strong>attestation de votre employeur de moins de 3 mois.</strong>",
        "ctt": "<p>Merci d’ajouter ici votre <strong>contrat de travail</strong> en cours ou à venir. Il doit être <strong>complet</strong> (toutes les pages) et <strong>signé</strong> par vous et votre employeur.</p><p>Si vous ne disposez pas de votre contrat de travail, vous pouvez ajouter une <strong>attestation de votre employeur</strong> précisant l’emploi et la rémunération proposée, la date d’entrée en fonction envisagée et la durée du contrat prévue.</p><p>Conseil : vous pouvez également ajouter ici vos certificats de travail de ces 3 derniers mois ou plus si vous en disposez. Cela permettra à un propriétaire de s’assurer que vos missions sont régulières et récurrentes.</p>",
        "other": "<p>Si votre activité actuelle ne correspond à aucun des choix de cette liste, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique votre situation.</p><p>Conseil : vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
        "independent": "<p>Merci d’ajouter ici l’un des documents suivants :</p><ul><li>votre <strong>carte professionnelle</strong> (profession libérale)</li><li>un <strong>extrait K ou K bis⁽¹⁾</strong> de moins de 3 mois</li><li>un <strong>avis de situation au répertoire SIRENE⁽²⁾</strong> de moins de 3 mois</li><li>votre <strong>relevé de situation comptable⁽³⁾</strong> (auto-entrepreneur)</li><li>une <strong>fiche d'immatriculation⁽⁴⁾</strong> au Registre National des Entreprises</li><li>une <strong>attestation de ressources</strong> pour l’année en cours délivré par un comptable</li></ul><p>⁽¹⁾ découvrez comment obtenir un <a href='https://entreprendre.service-public.fr/vosdroits/F21000#:~:text=Comment%20l%27obtenir%20%3F,son%20K%20ou%20Kbis%20numérique.' target='_blank' rel='noopener'>extrait K ou K bis</a><br>⁽²⁾ découvrez comment obtenir un <a href='https://entreprendre.service-public.fr/vosdroits/R17969' target='_blank' rel='noopener'>avis de situation au répertoire SIRENE</a><br>⁽³⁾ découvrez comment télécharger votre <a href='https://www.autoentrepreneur.urssaf.fr/portail/accueil/une-question/toutes-les-fiches-pratiques/telecharger-mes-attestations.html' target='_blank' rel='noopener'>relevé de situation comptable</a><br>⁽⁴⁾ découvrez comment obtenir une <a href='https://entreprendre.service-public.fr/vosdroits/R19859' target='_blank' rel='noopener'>fiche d’immatriculation</a><br></p><p>Note : ces démarches sont totalement gratuites.</p>",
        "internship": "<p>Merci d’ajouter ici votre <strong>convention de stage</strong> en cours ou à venir. Elle doit être <strong>complète</strong> (toutes les pages) et <strong>signée</strong> par toutes les parties (vous, votre tuteur, l’employeur etc.)</p><p>Conseil : n’alourdissez pas inutilement votre dossier avec des documents annexes, la convention complète suffit.</p>",
        "public": "<p>Merci d’ajouter ici votre <strong>arrêté de nomination</strong> en cours ou à venir.</p><p>Si vous ne disposez pas de votre arrêté de nomination, vous pouvez ajouter une <strong>attestation de votre employeur</strong> de moins de 3 mois.</p>",
        "retired": "<p>Merci d’ajouter ici l’un des documents suivants :</p><ul><li>un <strong>bulletin de pension</strong> ou une <strong>attestation de paiement de bulletin de pension</strong> de moins de 2 ans</li><li>votre <strong>titre de pension de retraite</strong></li><li>votre <strong>dernier ou avant-dernier avis d'imposition</strong> complet (toutes les pages)</li></ul><p>Conseil : le bulletin de pension est le document le plus adapté ici. N’ajoutez votre avis d’imposition que si vous n’avez aucun autre document de la liste.</p>",
        "unemployed": "<p>Merci d’ajouter ici l’un des documents suivants :</p><ul><li>un <strong>avis de situation Pôle emploi</strong>⁽¹⁾ de moins de 3 mois</li><li>une attestation d’ouverture de droits à l’ARE</li></ul><p>⁽¹⁾ découvrez <a href='https://www.pole-emploi.fr/candidat/vos-services-en-ligne/les-pas-a-pas-les-videos-qui-vou/comment-obtenir-une-attestation.html' target='_blank' rel='noopener'>comment obtenir votre avis de situation</a> sur le site de Pôle emploi.</p>",
        "student": "<p>Merci d’ajouter ici l’un des documents suivants :</p><ul><li>votre <strong>carte d’étudiant·e</strong></li><li>votre <strong>certificat de scolarité</strong></li><li>une <strong>attestation d’inscription Parcoursup</strong></li></ul><p>Le document doit mentionner votre nom, le nom de l’établissement et l’année scolaire en cours ou à venir.</p>",
        "intermittent": "<p>Merci d’ajouter ici un <strong>avis de situation Pôle emploi</strong>⁽¹⁾ de moins de 3 mois, ainsi que votre <strong>contrat de travail en cours</strong> si vous en avez un.</p><p>⁽¹⁾ découvrez <a href='https://www.pole-emploi.fr/candidat/vos-services-en-ligne/les-pas-a-pas-les-videos-qui-vou/comment-obtenir-une-attestation.html' target='_blank' rel='noopener'>comment obtenir votre avis de situation</a> sur le site de Pôle emploi.</p><p>Conseil : vous pouvez également ajouter ici vos contrats de ces 3 derniers mois. Cela permettra à un propriétaire de s’assurer que vos missions sont régulières et récurrentes. Attention toutefois à ne pas surcharger votre dossier : si vous avez 30 contrats d’un jour ou deux, il vaut mieux ajouter un document récapitulatif plutôt que ces 30 contrats.</p>",
        "stay-at-home-parent": "<p>Si vous touchez le RSA, merci d’ajouter ici une <strong>attestation de paiement</strong>⁽¹⁾ de la CAF de moins de 3 mois.</p><p>⁽¹⁾ découvrez comment obtenir une <a href='https://www.caf.fr/allocataires/caf-de-la-haute-garonne/actualites-departementales/comment-telecharger-son-attestation-de-paiement-en-2-clics' target='_blank' rel='noopener'>attestation de paiement</a> auprès de la CAF.</p><p>Si vous n’avez pas de revenus, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique votre situation de père·mère au foyer.</p><p>Conseil : vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
        "no-activity": "<p>Si vous êtes en situation de handicap et que vous touchez l'allocation aux adultes handicapés (AAH), merci d'ajouter ici <strong>une attestation de paiement de pension d'invalidité</strong>⁽¹⁾ de la CAF de moins de 3 mois.</p><p>⁽¹⁾ découvrez comment obtenir une <a href='https://www.ameli.fr/roubaix-tourcoing/assure/adresses-et-contacts/l-obtention-d-un-document/obtenir-une-attestation/obtenir-une-attestation-de-paiement-de-pension-d-invalidite-ou-de-rente' target='_blank' rel='noopener'>attestation de paiement auprès de la CAF</a>.</p><p>Si vous êtes sans emploi et ne touchez aucun revenus, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique votre situation.</p><p>Conseil : vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
        "artist": "<p>Merci d’ajouter ici l’un des documents suivants : </p><ul><li>un <strong>avis de situation au répertoire SIRENE</strong>⁽¹⁾ de moins de 3 mois</li><li>une <strong>attestation d’affiliation</strong></li></ul><p>⁽¹⁾ découvrez comment obtenir un <a href='https://entreprendre.service-public.fr/vosdroits/R17969' target='_blank' rel='noopener'>avis de situation au répertoire SIRENE</a>.</p>"
      },
      "guarantor_salary": "J’ajoute les <strong>3 derniers bulletins de salaire</strong> de mon garant, une attestation de son employeur, un justificatif de versement de ses indemnités de stage, un bilan comptable ou un avis d’imposition à son nom, complet et de moins de 2 ans.",
      "guest": "Merci d'ajouter ici <strong>une attestation sur l'honneur</strong> d'hébergement à titre gratuit de moins de 3 mois (nous vous recommandons d'utiliser le modèle d'attestation disponible sur le site <a target='_blank' rel='noopener' title='site pour générer une attestation' href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement'>service-public.fr</a>).",
      "guest-company": "<p>Merci d'ajouter ici <b>une attestation de votre employeur</b> indiquant que vous bénéficiez d’un logement de fonction.<br>Si vous n’en avez pas, vous pouvez ajouter un bulletin de salaire sur lequel le logement de fonction est visible ou un contrat de travail qui mentionne votre logement de fonction, ainsi qu’une attestation sur l’honneur expliquant votre situation (nous vous recommandons d'utiliser le modèle d'attestation disponible sur le site <a href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement' target='_blank' rel='noopener'>service-public.fr</a>).</p>",
      "guest-organism": "<p>Merci d’ajouter ici une <a href='https://www.service-public.fr/simulateur/calcul/16030' target='_blank' rel='noopener'>attestation d’élection de domicile</a> de moins de 3 mois datée et signée par l’organisme d’hébergement (hébergement d’urgence, placement...)</p>",
      "short-term-rental": "Merci d’ajouter ici les <b>factures réglées</b> (de l’organisme, de l’hôtel, du camping etc.) de ces 3 derniers mois.",
      "other-residency": "",
      "identity-card": "Veillez à ajouter votre pièce <b>recto-verso</b>.",
      "passport": "Veillez à ajouter les <b>pages 2 et 3</b> de votre passeport, car la signature doit apparaître.",
      "permit": "Veillez à ajouter votre pièce <b>recto-verso</b>.",
      "drivers-license": "Veillez à ajouter votre permis de conduire <b>recto-verso</b>.",
      "france-identite": "Merci d'ajouter ici un justificatif d'identité en cours de validité généré via le service <a href='https://france-identite.gouv.fr/' target='_blank' rel='noopener'>France Identité</a>.",
      "other": "",
      "less-than-year": "J’ai déclaré être en France depuis moins d’un an.",
      "my-name": "<p>Merci d’ajouter ici <strong>toutes les pages de votre avis d’imposition le plus récent</strong>⁽¹⁾, que vous soyez imposable ou non.</p><p>⁽¹⁾ les avis d’imposition sont disponibles dans l’espace particulier du site <a href='https://www.impots.gouv.fr/accueil' target='_blank' rel='noopener'>impots.gouv.fr</a>. N’hésitez pas à consulter <a href='https://www.impots.gouv.fr/particulier/questions/jai-perdu-mon-avis-dimpot-sur-le-revenu-puis-je-en-obtenir-une-copie' target='_blank' rel='noopener'>cette page</a> pour plus d’informations.</p><p>Note :  les avis des années précédentes peuvent également être ajoutés si vous le souhaitez, mais ils ne sont pas suffisants.</p>",
      "my-parents": "J’ai déclaré être rattaché·e au domicile fiscal de mes parents.",
      "no-income": "Je n'ai pas de revenu",
      "organism-guarantor": "J'ajoute un certificat ou un visa délivré par l'organisme qui se porte garant pour moi.",
      "other-tax": "Afin d’améliorer mon dossier, j’explique ci-dessous pourquoi je ne reçois pas d’avis d’imposition. Mon explication sera ajoutée à mon dossier :",
      "owner": "J’ajoute un <b>avis de taxe foncière de moins d’un an.</b>",
      "pension": "<p>Merci d’ajouter ici un <strong>bulletin de pension</strong> de moins de 2 ans, une <strong>attestation de pension</strong> ou votre <strong>dernier avis d’imposition</strong>.</p><p>Conseil : si vous n’avez pas de justificatif à fournir ici, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique votre situation. Vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "rent": "<p>Merci d’ajouter ici un justificatif de paiement de rente ou votre dernier avis d’imposition mentionnant clairement le montant de la rente. S’il s’agit d’une rente de type immobilière, vous pouvez ajouter ici la ou les dernières quittances de vos locataires.</p><p>Conseil : si vous n’avez pas de justificatif à fournir ici, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique votre situation. Vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "salary": "<p>Merci d’ajouter ici, en fonction de votre situation :</p><ul><li>salarié·e de plus de 3 mois : vos <strong>3 derniers bulletins de salaire</strong></li><li>salarié·e de 1 à 3 mois : les <strong>bulletins de salaire dont vous disposez</strong> pour le moment</li><li>futur·e salarié·e : la <strong>page de votre contrat</strong> qui mentionne votre rémunération à venir</li><li>indépendant·e : vos <strong>3 dernières déclarations mensuelles de recettes</strong>, un <strong>relevé de situation comptable</strong> ou une <strong>attestation de déclaration de chiffres d’affaires</strong> de moins de 3 mois </li><li>intermittent·e, CTT : <strong>tous vos bulletins de salaire</strong> de ces 3 derniers mois <strong>dans ce seul emplacement</strong> (le montant de vos revenus correspond à la moyenne de ces bulletins de salaire)</li><li>artiste auteur ou autrice : votre <strong>dernier avis d’imposition</strong></li></ul>",
      "scholarship": "<p>Merci d’ajouter ici votre <strong>dernier avis d’attribution de bourse</strong>.</p><p>Conseil : si vous n’avez pas de justificatif à fournir ici, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique votre situation. Vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "social-service": "<p>Merci d’ajouter ici, en fonction de votre situation :</p><ul><li>vos <strong>3 derniers justificatifs de versement de prestations sociales</strong> (ARE, CAF...)</li><li>un <strong>justificatif d'ouverture de vos droits</strong> si vous n’avez pas encore touché de prestation sociale</li><li>une <strong>copie d'écran</strong> de simulation d'aide au logement (APL)</li></ul>",
      "tenant": "J’ajoute <strong>mes 3 dernières quittances de loyer</strong> ou <strong>une attestation de bon paiement des loyers</strong> de moins de 3 mois."
    },
    "cotenant": {
      "alternation": "J’ajoute son contrat d’alternance en cours, complet et signé.",
      "cdd": "J’ajoute son contrat de travail en cours, complet et signé.\nEt si iel n'a pas de contrat de travail ? Vous pouvez joindre une attestation de son employeur signée et datée de moins de 3 mois.",
      "cdi": "J’ajoute son contrat de travail en cours, complet et signé.\nEt si iel n'a pas de contrat de travail ? Vous pouvez joindre une attestation de son employeur signée et datée de moins de 3 mois.",
      "cdi-trial": "J’ajoute son contrat de travail en cours, complet et signé.\nEt si iel n’a pas de contrat de travail ? Vous pouvez joindre une attestation de son employeur signée et datée de moins de 3 mois.",
      "ctt": "J’ajoute son contrat de travail en cours, complet et signé.\nEt si iel n'a pas de contrat de travail ? Vous pouvez joindre une attestation de son employeur signée et datée de moins de 3 mois.",
      "guarantor_salary": "J’ajoute les <strong>3 derniers bulletins de salaire</strong> de son garant, une attestation de son employeur, un justificatif de versement de ses indemnités de stage, un bilan comptable ou un avis d’imposition à son nom, complet et de moins de 2 ans.",
      "guest": "Merci d'ajouter ici <strong>une attestation sur l'honneur</strong> d'hébergement à titre gratuit de moins de 3 mois (nous vous recommandons d'utiliser le modèle d'attestation disponible sur le site <a target='_blank' rel='noopener' title='site pour générer une attestation' href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement'>service-public.fr</a>).",
      "guest-company": "<p>Merci d'ajouter ici <b>une attestation de votre employeur</b> indiquant que vous bénéficiez d’un logement de fonction.<br>Si vous n’en avez pas, vous pouvez ajouter un bulletin de salaire sur lequel le logement de fonction est visible ou un contrat de travail qui mentionne votre logement de fonction, ainsi qu’une attestation sur l’honneur expliquant votre situation (nous vous recommandons d'utiliser le modèle d'attestation disponible sur le site <a href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement' target='_blank' rel='noopener'>service-public.fr</a>).</p>",
      "guest-organism": "<p>Merci d’ajouter ici une <a href='https://www.service-public.fr/simulateur/calcul/16030' target='_blank' rel='noopener'>attestation d’élection de domicile</a> de moins de 3 mois datée et signée par l’organisme d’hébergement (hébergement d’urgence, placement...)</p>",
      "short-term-rental": "Merci d’ajouter ici les <b>factures réglées</b> (de l’organisme, de l’hôtel, du camping etc.) de ces 3 derniers mois.",
      "other-residency": "",
      "identity-card": "Veillez à ajouter sa pièce <b>recto-verso</b>.",
      "passport": "Veillez à ajouter les <b>pages 2 et 3</b> de son passeport, car la signature doit apparaître.",
      "permit": "Veillez à ajouter sa pièce <b>recto-verso</b>.",
      "drivers-license": "Veillez à ajouter son permis de conduire <b>recto-verso</b>.",
      "france-identite": "Merci d'ajouter ici un justificatif d'identité en cours de validité généré via le service <a href='https://france-identite.gouv.fr/' target='_blank' rel='noopener'>France Identité</a>.",
      "other": "",
      "independent": "J’ajoute son justificatif d’activité professionnelle indépendante en fonction de son type d’activité.",
      "internship": "J’ajoute la convention de stage complète et signée de son garant.",
      "less-than-year": "J’ai déclaré être en France depuis moins d’un an.",
      "my-name": "<p>Merci d’ajouter ici <strong>l’avis d’imposition le plus récent</strong>⁽¹⁾ de votre conjoint, qu'il soit imposable ou non. Attention à bien fournir toutes les pages du document.</p><p>⁽¹⁾ les avis d’imposition sont disponibles dans l’espace particulier du site <a href='https://www.impots.gouv.fr/accueil' target='_blank' rel='noopener'>impots.gouv.fr</a>. N’hésitez pas à consulter <a href='https://www.impots.gouv.fr/particulier/questions/jai-perdu-mon-avis-dimpot-sur-le-revenu-puis-je-en-obtenir-une-copie' target='_blank' rel='noopener'>cette page</a> pour plus d’informations.</p><p>Note :  les avis des années précédentes peuvent également être ajoutés si vous le souhaitez, mais ils ne sont pas suffisants.</p>",
      "my-parents": "Iel a déclaré être rattaché·e au domicile fiscal de mes parents.",
      "no-income": "Iel n'a pas de revenu",
      "organism-guarantor": "J'ajoute un certificat ou un visa délivré par l'organisme qui se porte garant pour mon conjoint.",
      "other-tax": "Afin d’améliorer son dossier, j’explique ci-dessous pourquoi je ne reçois pas d’avis d’imposition. Son explication sera ajoutée à mon dossier :",
      "owner": "J’ajoute un <b>avis de taxe foncière de moins d’un an.</b>",
      "pension": "<p>Merci d’ajouter ici un <strong>bulletin de pension</strong> de moins de 2 ans, une <strong>attestation de pension</strong> ou son <strong>dernier avis d’imposition</strong>.</p><p>Conseil : si vous n’avez pas de justificatif à fournir ici, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique sa situation. Vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "public": "J’ajoute un arrêté de nomination.",
      "rent": "<p>Merci d’ajouter ici un justificatif de paiement de rente ou son dernier avis d’imposition mentionnant clairement le montant de la rente. S’il s’agit d’une rente de type immobilière, vous pouvez ajouter ici la ou les dernières quittances de ses locataires.</p><p>Conseil : si vous n’avez pas de justificatif à fournir ici, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique sa situation. Vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "retired": "J’ajoute un bulletin de pension retraite. Et si iel n’en a pas? Vous pouvez joindre son otre dernier avis d’imposition complet de moins de 2 ans.",
      "salary": "<p>Merci d’ajouter ici, en fonction de sa situation :</p><ul><li>salarié·e de plus de 3 mois : ses <strong>3 derniers bulletins de salaire</strong></li><li>salarié·e de 1 à 3 mois : les <strong>bulletins de salaire dont votre conjoint dispose</strong> pour le moment</li><li>futur·e salarié·e : la <strong>page de son contrat</strong> qui mentionne sa rémunération à venir</li><li>indépendant·e : ses <strong>3 dernières déclarations mensuelles de recettes</strong>, un <strong>relevé de situation comptable</strong> ou une <strong>attestation de déclaration de chiffres d’affaires</strong> de moins de 3 mois </li><li>intermittent·e, CTT : <strong>tous ses bulletins de salaire</strong> de ces 3 derniers mois <strong>dans ce seul emplacement</strong> (le montant de ses revenus correspond à la moyenne de ces bulletins de salaire)</li><li>artiste auteur ou autrice : son <strong>dernier avis d’imposition</strong></li></ul>",
      "scholarship": "<p>Merci d’ajouter ici son <strong>dernier avis d’attribution de bourse</strong>.</p><p>Conseil : si vous n’avez pas de justificatif à fournir ici, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique sa situation. Vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "social-service": "<p>Merci d’ajouter ici, en fonction de sa situation :</p><ul><li>ses <strong>3 derniers justificatifs de versement de prestations sociales</strong> (ARE, CAF...)</li><li>un <strong>justificatif d'ouverture de ses droits</strong> si votre conjoint n’a pas encore touché de prestation sociale</li><li>une <strong>copie d'écran</strong> de simulation d'aide au logement (APL)</li></ul>",
      "student": "J’ajoute une copie de sa carte d’étudiant ou un certificat de scolarité pour l’année en cours.",
      "tenant": "J’ajoute ses <b>quittances de loyer pour les trois derniers mois.</b>",
      "unemployed": "J’ajoute une attestation d’ouverture de droits à l’ARE ou un avis de situation Pôle Emploi de moins de 3 mois.",
      "intermittent": "<p>Merci d’ajouter ici un <strong>avis de situation Pôle emploi</strong>⁽¹⁾ de moins de 3 mois, ainsi que son <strong>contrat de travail en cours</strong> si votre conjoint en a un.</p><p>⁽¹⁾ découvrez <a href='https://www.pole-emploi.fr/candidat/vos-services-en-ligne/les-pas-a-pas-les-videos-qui-vou/comment-obtenir-une-attestation.html' target='_blank' rel='noopener'>comment obtenir son avis de situation</a> sur le site de Pôle emploi.</p><p>Conseil : vous pouvez également ajouter ici ses contrats de ces 3 derniers mois. Cela permettra à un propriétaire de s’assurer que ses missions sont régulières et récurrentes. Attention toutefois à ne pas surcharger votre dossier : si votre conjoint a 30 contrats d’un jour ou deux, il vaut mieux ajouter un document récapitulatif plutôt que ces 30 contrats.</p>",
      "stay-at-home-parent": "<p>Si votre conjoint touche le RSA, merci d’ajouter ici une <strong>attestation de paiement</strong>⁽¹⁾ de la CAF de moins de 3 mois.</p><p>⁽¹⁾ découvrez comment obtenir une <a href='https://www.caf.fr/allocataires/caf-de-la-haute-garonne/actualites-departementales/comment-telecharger-son-attestation-de-paiement-en-2-clics' target='_blank' rel='noopener'>attestation de paiement</a> auprès de la CAF.</p><p>Si votre conjoint n’a pas de revenus, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique sa situation de père·mère au foyer.</p><p>Conseil : vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "no-activity": "<p>Si votre conjoint est en situation de handicap, vous pouvez ajouter ici l’un des documents suivants :</p><ul><li>une <strong>attestation de paiement de pension d'invalidité</strong>⁽¹⁾ de moins de 3 mois</li><li>une <strong>notification de décision MDPH</strong></li><li>sa <strong>carte CMI</strong></li></ul><p>⁽¹⁾ découvrez comment obtenir une <a href='https://www.ameli.fr/roubaix-tourcoing/assure/adresses-et-contacts/l-obtention-d-un-document/obtenir-une-attestation/obtenir-une-attestation-de-paiement-de-pension-d-invalidite-ou-de-rente' target='_blank' rel='noopener'>attestation de paiement de pension d’invalidité</a>.</p><p>Si votre conjoint est sans emploi et ne touche aucun revenus, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique sa situation.</p><p>Conseil : vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "artist": "<p>Merci d’ajouter ici l’un des documents suivants : </p><ul><li>un <strong>avis de situation au répertoire SIRENE</strong>⁽¹⁾ de moins de 3 mois</li><li>une <strong>attestation d’affiliation</strong></li></ul><p>⁽¹⁾ découvrez comment obtenir un <a href='https://entreprendre.service-public.fr/vosdroits/R17969' target='_blank' rel='noopener'>avis de situation au répertoire SIRENE</a>.</p>"
    },
    "guarantor": {
      "professional": {
        "alternation": "Merci d’ajouter ici son <strong>contrat d'apprentissage ou de professionnalisation</strong> en cours ou à venir. Il doit être <strong>complet</strong> et <strong>signé</strong> par toutes les parties (le garant, le tuteur, l’employeur etc.)",
        "cdd": "<p>Merci d’ajouter ici <strong>l’un des documents suivants, au choix</strong> :</p><ul><li>un <strong>bulletin de salaire</strong> de moins de 3 mois</li><li>ou une <strong>attestation employeur</strong> de moins de 3 mois</li><li>ou son <strong>contrat de travail</strong> complet et signé</li></ul><p>Conseil : si vous disposez de plusieurs documents de votre garant (bulletins de salaire et contrat de travail par exemple), répartissez-les entre cette rubrique <i>Situation professionnelle</i> et la suivante <i>Justificatif de ressources</i> afin de ne pas surcharger votre dossier.</p>",
        "cdi": "<p>Merci d’ajouter ici <strong>l’un des documents suivants, au choix</strong> :</p><ul><li>un <strong>bulletin de salaire</strong> de moins de 3 mois</li><li>ou une <strong>attestation employeur</strong> de moins de 3 mois</li><li>ou son <strong>contrat de travail</strong> complet et signé</li></ul><p>Conseil : si vous disposez de plusieurs documents de votre garant (bulletins de salaire et contrat de travail par exemple), répartissez-les entre cette rubrique <i>Situation professionnelle</i> et la suivante <i>Justificatif de ressources</i> afin de ne pas surcharger votre dossier.</p>",
        "cdi-trial": "<p>Merci d’ajouter ici <strong>l’un des documents suivants, au choix</strong> :</p><ul><li>un <strong>bulletin de salaire</strong> de moins de 3 mois</li><li>ou une <strong>attestation employeur</strong> de moins de 3 mois</li><li>ou son <strong>contrat de travail</strong> complet et signé</li></ul><p>Conseil : si vous disposez de plusieurs documents de votre garant (bulletins de salaire et contrat de travail par exemple), répartissez-les entre cette rubrique <i>Situation professionnelle</i> et la suivante <i>Justificatif de ressources</i> afin de ne pas surcharger votre dossier.</p>",
        "ctt": "<p>Merci d’ajouter ici son <strong>contrat de travail</strong> en cours ou à venir. Il doit être <strong>complet</strong> (toutes les pages) et <strong>signé</strong>.</p><p>Conseil : vous pouvez également ajouter ici ses certificats de travail de ces 3 derniers mois ou plus si vous en disposez. Cela permettra à un propriétaire de s’assurer que ses missions sont régulières et récurrentes.</p>",
        "other": "<p>Si l'activité actuelle de votre garant ne correspond à aucun des choix de cette liste, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique sa situation.</p><p>Conseil : vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
        "independent": "<p>Merci d’ajouter ici <strong>l’un des documents suivants, au choix</strong> :</p><ul><li>une <strong>carte professionnelle</strong> (profession libérale)</li><li>ou un <strong>extrait K ou K bis⁽¹⁾</strong> de moins de 3 mois</li><li>ou un <strong>avis de situation au répertoire SIRENE⁽²⁾</strong> de moins de 3 mois</li><li>ou un <strong>relevé de situation comptable⁽³⁾</strong> (auto-entrepreneur)</li><li>ou une <strong>fiche d'immatriculation⁽⁴⁾</strong> au Registre National des Entreprises</li><li>ou une <strong>attestation de ressources</strong> pour l’année en cours délivré par un comptable</li></ul><p>⁽¹⁾ découvrez comment obtenir un <a href='https://entreprendre.service-public.fr/vosdroits/F21000#:~:text=Comment%20l%27obtenir%20%3F,son%20K%20ou%20Kbis%20numérique.' target='_blank' rel='noopener'>extrait K ou K bis</a><br>⁽²⁾ découvrez comment obtenir un <a href='https://entreprendre.service-public.fr/vosdroits/R17969' target='_blank' rel='noopener'>avis de situation au répertoire SIRENE</a><br>⁽³⁾ découvrez comment télécharger votre <a href='https://www.autoentrepreneur.urssaf.fr/portail/accueil/une-question/toutes-les-fiches-pratiques/telecharger-mes-attestations.html' target='_blank' rel='noopener'>relevé de situation comptable</a><br>⁽⁴⁾ découvrez comment obtenir une <a href='https://entreprendre.service-public.fr/vosdroits/R19859' target='_blank' rel='noopener'>fiche d’immatriculation</a><br></p><p>Note : ces démarches sont totalement gratuites.</p>",
        "internship": "<p>Merci d’ajouter ici sa <strong>convention de stage</strong> en cours ou à venir. Elle doit être <strong>complète</strong> (toutes les pages) et <strong>signée</strong> par toutes les parties (le garant, le tuteur, l'employeur etc.)</p><p>Conseil : n’alourdissez pas inutilement votre dossier avec des documents annexes, la convention complète suffit.</p>",
        "public": "Merci d’ajouter ici <strong>l’un des documents suivants, au choix</strong> : <ul><li>un <strong>bulletin de salaire</strong> de moins de 3 mois</li><li>ou une <strong>attestation de l’employeur</strong> de moins de 3 mois</li><li>ou un <strong>arrêté de nomination</strong> en cours ou à venir</li></ul><p>Conseil : si vous disposez de plusieurs documents de votre garant (bulletins de salaire et contrat de travail par exemple), répartissez-les entre cette rubrique <i>Situation professionnelle</i> et la suivante <i>Justificatif de ressources</i> afin de ne pas surcharger votre dossier.</p>",
        "retired": "<p>Merci d’ajouter ici <strong>l’un des documents suivants, au choix</strong> :</p><ul><li>un <strong>bulletin de pension</strong> de moins de 2 ans</li><li>ou son <strong>titre de pension de retraite</strong></li><li>ou son <strong>dernier ou avant-dernier avis d'imposition</strong> complet (toutes les pages)</li></ul><p>Conseil : le bulletin de pension est le document le plus adapté ici. N’ajoutez votre avis d’imposition que si vous n’avez aucun autre document de la liste.</p>",
        "unemployed": "<p>Merci d’ajouter ici <strong>l’un des documents suivants, au choix</strong> :</p><ul><li>une <strong>attestation de paiement Pôle emploi</strong> de moins de 3 mois</li><li>ou un <strong>avis de situation Pôle emploi</strong>⁽¹⁾ de moins de 3 mois</li></ul><p>⁽¹⁾ découvrez <a href='https://www.pole-emploi.fr/candidat/vos-services-en-ligne/les-pas-a-pas-les-videos-qui-vou/comment-obtenir-une-attestation.html' target='_blank' rel='noopener'>comment obtenir un avis de situation</a> sur le site de Pôle emploi.</p>",
        "student": "<p>Merci d’ajouter ici <strong>l’un des documents suivants, au choix</strong> :</p><ul><li>sa <strong>carte d’étudiant·e</strong></li><li>ou son <strong>certificat de scolarité</strong></li><li>ou son <strong>attestation d’inscription Parcoursup</strong></li></ul><p>Le document doit mentionner son nom, le nom de l’établissement et l’année scolaire en cours ou à venir.</p>",
        "intermittent": "<p>Merci d’ajouter ici un <strong>avis de situation Pôle emploi</strong>⁽¹⁾ de moins de 3 mois, ainsi que son <strong>contrat de travail en cours</strong> si votre garant en a un.</p><p>⁽¹⁾ découvrez <a href='https://www.pole-emploi.fr/candidat/vos-services-en-ligne/les-pas-a-pas-les-videos-qui-vou/comment-obtenir-une-attestation.html' target='_blank' rel='noopener'>comment obtenir son avis de situation</a> sur le site de Pôle emploi.</p><p>Conseil : vous pouvez également ajouter ici ses contrats de ces 3 derniers mois. Cela permettra à un propriétaire de s’assurer que ses missions sont régulières et récurrentes. Attention toutefois à ne pas surcharger votre dossier : si votre garant a 30 contrats d’un jour ou deux, il vaut mieux ajouter un document récapitulatif plutôt que ces 30 contrats.</p>",
        "stay-at-home-parent": "<p>Si votre garant touche le RSA, merci d’ajouter ici une <strong>attestation de paiement</strong>⁽¹⁾ de la CAF de moins de 3 mois.</p><p>⁽¹⁾ découvrez comment obtenir une <a href='https://www.caf.fr/allocataires/caf-de-la-haute-garonne/actualites-departementales/comment-telecharger-son-attestation-de-paiement-en-2-clics' target='_blank' rel='noopener'>attestation de paiement</a> auprès de la CAF.</p><p>Si votre garant n’a pas de revenus, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique sa situation de père·mère au foyer.</p><p>Conseil : vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
        "no-activity": "<p>Si votre garant est en situation de handicap, vous pouvez ajouter ici l’un des documents suivants :</p><ul><li>une <strong>attestation de paiement de pension d'invalidité</strong>⁽¹⁾ de moins de 3 mois</li><li>une <strong>notification de décision MDPH</strong></li><li>sa <strong>carte CMI</strong></li></ul><p>⁽¹⁾ découvrez comment obtenir une <a href='https://www.ameli.fr/roubaix-tourcoing/assure/adresses-et-contacts/l-obtention-d-un-document/obtenir-une-attestation/obtenir-une-attestation-de-paiement-de-pension-d-invalidite-ou-de-rente' target='_blank' rel='noopener'>attestation de paiement de pension d’invalidité</a>.</p><p>Si votre garant est sans emploi et ne touche aucun revenus, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique sa situation.</p><p>Conseil : vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
        "artist": "<p>Merci d’ajouter ici l’un des documents suivants : </p><ul><li>un <strong>avis de situation au répertoire SIRENE</strong>⁽¹⁾ de moins de 3 mois</li><li>une <strong>attestation d’affiliation</strong></li></ul><p>⁽¹⁾ découvrez comment obtenir un <a href='https://entreprendre.service-public.fr/vosdroits/R17969' target='_blank' rel='noopener'>avis de situation au répertoire SIRENE</a>.</p>"
      },
      "guarantor_salary": "<p>Merci d’ajouter <strong>l’un des documents suivants, au choix</strong> :</p><div>Pour les salariés:</div><ul><li>ses <strong>3 derniers bulletins de salaire</strong></li></ul><div>Pour les indépendants:<div><ul><li>son dernier ou avant-dernier <strong>avis d'imposition</strong></li><li> une <strong>synthèse de bilan comptable</strong>⁽¹⁾ de moins de deux ans</li></ul><p>⁽¹⁾ ses 2 derniers bilans comptables peuvent être acceptés ici. Veuillez toutefois noter qu’il s’agit de documents longs et complexes: des documents de synthèse sont à privilégier.</p><p>Conseil : si vous disposez de plusieurs documents de votre garant (bulletins de salaire et contrat de travail par exemple), répartissez-les entre cette rubrique <em>Revenus</em> et la précédente <em>Situation professionnelle</em> afin de ne pas surcharger votre dossier.</p>",
      "guest": "Merci d'ajouter ici <strong>une attestation sur l'honneur</strong> d'hébergement à titre gratuit de moins de 3 mois (nous vous recommandons d'utiliser le modèle d'attestation disponible sur le site <a target='_blank' rel='noopener' title='site pour générer une attestation' href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement'>service-public.fr</a>).",
      "guest-organism": "<p><b>Logement de fonction :</b><br>Merci d'ajouter ici <b>une attestation de votre employeur</b> indiquant que vous bénéficiez d'un logement de fonction.<br>Si vous n'en avez pas, vous pouvez ajouter un bulletin de salaire sur lequel le logement de fonction est visible ou un contrat de travail sur l'honneur expliquant votre situation (nous vous recommandons d'utiliser le modèle d'attestation disponible sur le site <a href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement' target='_blank' rel='noopener'>service-public.fr</a>).</p><p><b>Hébergé par un organisme :</b><br> Merci d’ajouter ici une <a href='https://www.service-public.fr/simulateur/calcul/16030' target='_blank' rel='noopener'>attestation d’élection de domicile</a> de moins de 3 mois datée et signée par l’organisme d’hébergement (hébergement d’urgence, placement...)</p>",
      "short-term-rental": "Merci d’ajouter ici les <b>factures réglées</b> (de l’organisme, de l’hôtel, du camping etc.) de ces 3 derniers mois.",
      "other-residency": "",
      "identity-card": "Veillez à ajouter sa pièce <b>recto-verso</b>.",
      "passport": "Veillez à ajouter les <b>pages 2 et 3</b> de son passeport, car la signature doit apparaître.",
      "permit": "Veillez à ajouter sa pièce <b>recto-verso</b>.",
      "drivers-license": "Veillez à ajouter son permis de conduire <b>recto-verso</b>.",
      "france-identite": "Merci d'ajouter ici un justificatif d'identité en cours de validité généré via le service <a href='https://france-identite.gouv.fr/' target='_blank' rel='noopener'>France Identité</a>.",
      "other": "",
      "less-than-year": "J’ai renseigné l’ensemble des pièces de mon garant",
      "my-name": "<p>Merci d’ajouter ici <strong>l’avis d’imposition le plus récent</strong>⁽¹⁾ de votre garant, qu'il soit imposable ou non. Attention à bien fournir toutes les pages du document.</p><p>⁽¹⁾ les avis d’imposition sont disponibles dans l’espace particulier du site <a href='https://www.impots.gouv.fr/accueil' target='_blank' rel='noopener'>impots.gouv.fr</a>. N’hésitez pas à consulter <a href='https://www.impots.gouv.fr/particulier/questions/jai-perdu-mon-avis-dimpot-sur-le-revenu-puis-je-en-obtenir-une-copie' target='_blank' rel='noopener'>cette page</a> pour plus d’informations.</p><p>Note :  les avis des années précédentes peuvent également être ajoutés si vous le souhaitez, mais ils ne sont pas suffisants.</p>",
      "other-tax": "Afin d’améliorer mon dossier, j’explique ci-dessous pourquoi je n'ai pas d’avis d’imposition au nom de mon garant. Cette explication sera ajoutée à mon dossier :",
      "pension": "<p>Merci d’ajouter ici <strong>l’un des documents suivants, au choix</strong> :</p><ul><li>un <strong>bulletin de pension</strong> de moins de 2 ans</li><li>ou son <strong>titre de pension de retraite</strong></li><li>ou son <strong>dernier ou avant-dernier avis d'imposition</strong> complet (toutes les pages)</li></ul>",
      "rent": "<p>Merci d’ajouter ici un justificatif de paiement de rente ou un avis d’imposition mentionnant clairement le montant de la rente. S’il s’agit d’une rente de type immobilière, vous pouvez ajouter ici la ou les dernières quittances des locataires.</p><p>Conseil : si vous n’avez pas de justificatif à fournir ici, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique la situation de votre garant. Vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "salary": "J’ajoute un bulletin de salaire de mon garant de moins de 3 mois, une attestation de son employeur, un justificatif de versement de ses indemnités de stage, un bilan comptable ou un avis d’imposition à son nom, complet et de moins de 2 ans.",
      "scholarship": "<p>Merci d’ajouter ici son <strong>dernier avis d’attribution de bourse</strong>.</p><p>Conseil : si vous n’avez pas de justificatif à fournir ici, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique la situation de votre garant. Vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "social-service": "Merci d’ajouter ici un <strong>justificatif de versement de prestations sociales</strong> de moins de 3 mois (ARE, CAF...)",
      "owner": "Merci d’ajouter ici le <b>dernier avis de taxe foncière</b> de votre garant.",
      "tenant": "Merci d’ajouter ici un <b>justificatif de domicile de moins de 3 mois</b> au nom de votre garant : facture de téléphone, d'énergie (gaz, électricité…), d’eau ou attestation d’assurance habitation par exemple.",
      "noDocument-social": "Je ne peux pas fournir de justificatifs de versement de prestations sociales",
      "noDocument-salary": "Je ne peux pas fournir les trois derniers bulletins de salaire ou un bilan comptable de mon garant",
      "noDocument-pension": "Je ne peux pas fournir de justificatifs de versement de pension",
      "noDocument-rent": "Je ne peux pas fournir de justificatifs de versement de rente",
      "noDocument-scholarship": "Je ne peux pas fournir de justificatifs d'attribution de bourse",
      "customText-social": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de mon garant :",
      "customText-salary": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les trois derniers bulletins de salaire ou un bilan comptable de mon garant :",
      "customText-pension": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de mon garant :",
      "customText-rent": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de mon garant :",
      "customText-scholarship": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de mon garant :",
      "will-delete-files": "Attention, un changement de situation entraînera la suppression des justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à la situation de votre garant."
    },
    "cotenant-guarantor": {
      "professional": {
        "alternation": "J’ajoute un bulletin de salaire de moins de 3 mois de son garant ou son contrat d’alternance complet et signé.",
        "cdd": "J’ajoute un bulletin de salaire de moins de 3 mois de son garant, son contrat de travail complet et signé, une attestation employeur de moins de 3 mois.",
        "cdi": "J’ajoute un bulletin de salaire de moins de 3 mois de son garant, son contrat de travail complet et signé, une attestation employeur de moins de 3 mois.",
        "cdi-trial": "J’ajoute un bulletin de salaire de moins de 3 mois de son garant, son contrat de travail complet et signé, une attestation employeur de moins de 3 mois.",
        "ctt": "J’ajoute un bulletin de salaire de moins de 3 mois de son garant, son contrat de travail complet et signé, une attestation employeur de moins de 3 mois.",
        "independent": "J’ajoute un justificatif d’activité professionnelle indépendante de son garant.",
        "internship": "J’ajoute la convention de stage complète et signée de son garant.",
        "other": "",
        "public": "J’ajoute un bulletin de salaire de moins de 3 mois de son garant, une attestation employeur de moins de 3 mois, son arrêté de nomination.",
        "retired": "J’ajoute un bulletin une attestation ou titre de pension retraite de son garant ou encore son avis d'imposition complet de moins de 2 ans.",
        "student": "J’ajoute une copie de la carte d’étudiant de son garant ou son certificat de scolarité pour l’année en cours.",
        "unemployed": "J’ajoute une attestation d’ouverture de droits à l’ARE de son garant ou son avis de situation Pôle Emploi de moins de 3 mois.",
        "intermittent": "<p>Merci d’ajouter ici un <strong>avis de situation Pôle emploi</strong>⁽¹⁾ de moins de 3 mois, ainsi que son <strong>contrat de travail en cours</strong> si son garant en a un.</p><p>⁽¹⁾ découvrez <a href='https://www.pole-emploi.fr/candidat/vos-services-en-ligne/les-pas-a-pas-les-videos-qui-vou/comment-obtenir-une-attestation.html' target='_blank' rel='noopener'>comment obtenir son avis de situation</a> sur le site de Pôle emploi.</p><p>Conseil : vous pouvez également ajouter ici ses contrats de ces 3 derniers mois. Cela permettra à un propriétaire de s’assurer que ses missions sont régulières et récurrentes. Attention toutefois à ne pas surcharger votre dossier : si son garant a 30 contrats d’un jour ou deux, il vaut mieux ajouter un document récapitulatif plutôt que ces 30 contrats.</p>",
        "stay-at-home-parent": "<p>Si son garant touche le RSA, merci d’ajouter ici une <strong>attestation de paiement</strong>⁽¹⁾ de la CAF de moins de 3 mois.</p><p>⁽¹⁾ découvrez comment obtenir une <a href='https://www.caf.fr/allocataires/caf-de-la-haute-garonne/actualites-departementales/comment-telecharger-son-attestation-de-paiement-en-2-clics' target='_blank' rel='noopener'>attestation de paiement</a> auprès de la CAF.</p><p>Si son garant n’a pas de revenus, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique sa situation de père·mère au foyer.</p><p>Conseil : vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
        "no-activity": "<p>Si son garant est en situation de handicap, vous pouvez ajouter ici l’un des documents suivants :</p><ul><li>une <strong>attestation de paiement de pension d'invalidité</strong>⁽¹⁾ de moins de 3 mois</li><li>une <strong>notification de décision MDPH</strong></li><li>sa <strong>carte CMI</strong></li></ul><p>⁽¹⁾ découvrez comment obtenir une <a href='https://www.ameli.fr/roubaix-tourcoing/assure/adresses-et-contacts/l-obtention-d-un-document/obtenir-une-attestation/obtenir-une-attestation-de-paiement-de-pension-d-invalidite-ou-de-rente' target='_blank' rel='noopener'>attestation de paiement de pension d’invalidité</a>.</p><p>Si son garant est sans emploi et ne touche aucun revenus, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique sa situation.</p><p>Conseil : vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
        "artist": "<p>Merci d’ajouter ici l’un des documents suivants : </p><ul><li>un <strong>avis de situation au répertoire SIRENE</strong>⁽¹⁾ de moins de 3 mois</li><li>une <strong>attestation d’affiliation</strong></li></ul><p>⁽¹⁾ découvrez comment obtenir un <a href='https://entreprendre.service-public.fr/vosdroits/R17969' target='_blank' rel='noopener'>avis de situation au répertoire SIRENE</a>.</p>"
      },
      "guarantor_salary": "<p>Merci d’ajouter <strong>l’un des documents suivants, au choix</strong> :</p><div>Pour les salariés:</div><ul><li>ses <strong>3 derniers bulletins de salaire</strong></li></ul><div>Pour les indépendants:<div><ul><li>son dernier ou avant-dernier <strong>avis d'imposition</strong></li><li> une <strong>synthèse de bilan comptable</strong>⁽¹⁾ de moins de deux ans</li></ul><p>⁽¹⁾ ses 2 derniers bilans comptables peuvent être acceptés ici. Veuillez toutefois noter qu’il s’agit de documents longs et complexes: des documents de synthèse sont à privilégier.</p><p>Conseil : si vous disposez de plusieurs documents de votre garant (bulletins de salaire et contrat de travail par exemple), répartissez-les entre cette rubrique <em>Revenus</em> et la précédente <em>Situation professionnelle</em> afin de ne pas surcharger votre dossier.</p>",
      "guest": "Merci d'ajouter ici une <b>attestation sur l’honneur d’hébergement de son garant à titre gratuit.</b> Vous pouvez utiliser un modèle en ligne sur le site <a target='_blank' href='//www.service-public.fr'>www.service-public.fr</a>",
      "guest-company": "<p>Merci d'ajouter ici <b>une attestation de votre employeur</b> indiquant que vous bénéficiez d’un logement de fonction.<br>Si vous n’en avez pas, vous pouvez ajouter un bulletin de salaire sur lequel le logement de fonction est visible ou un contrat de travail qui mentionne votre logement de fonction, ainsi qu’une attestation sur l’honneur expliquant votre situation (nous vous recommandons d'utiliser le modèle d'attestation disponible sur le site <a href='https://www.service-public.fr/simulateur/calcul/AttestationHebergement' target='_blank' rel='noopener'>service-public.fr</a>).</p>",
      "guest-organism": "<p>Merci d’ajouter ici une <a href='https://www.service-public.fr/simulateur/calcul/16030' target='_blank' rel='noopener'>attestation d’élection de domicile</a> de moins de 3 mois datée et signée par l’organisme d’hébergement (hébergement d’urgence, placement...)</p>",
      "short-term-rental": "Merci d’ajouter ici les <b>factures réglées</b> (de l’organisme, de l’hôtel, du camping etc.) de ces 3 derniers mois.",
      "other-residency": "",
      "identity-card": "Veillez à ajouter sa pièce <b>recto-verso</b>.",
      "passport": "Veillez à ajouter les <b>pages 2 et 3</b> de son passeport, car la signature doit apparaître.",
      "permit": "Veillez à ajouter sa pièce <b>recto-verso</b>.",
      "drivers-license": "Veillez à ajouter son permis de conduire <b>recto-verso</b>.",
      "france-identite": "Merci d'ajouter ici un justificatif d'identité en cours de validité généré via le service <a href='https://france-identite.gouv.fr/' target='_blank' rel='noopener'>France Identité</a>.",
      "other": "",
      "less-than-year": "J’ai renseigné l’ensemble des pièces de son garant",
      "my-name": "<p>Merci d’ajouter ici <strong>l’avis d’imposition le plus récent</strong>⁽¹⁾ de son garant, qu'il soit imposable ou non. Attention à bien fournir toutes les pages du document.</p><p>⁽¹⁾ les avis d’imposition sont disponibles dans l’espace particulier du site <a href='https://www.impots.gouv.fr/accueil' target='_blank' rel='noopener'>impots.gouv.fr</a>. N’hésitez pas à consulter <a href='https://www.impots.gouv.fr/particulier/questions/jai-perdu-mon-avis-dimpot-sur-le-revenu-puis-je-en-obtenir-une-copie' target='_blank' rel='noopener'>cette page</a> pour plus d’informations.</p><p>Note :  les avis des années précédentes peuvent également être ajoutés si vous le souhaitez, mais ils ne sont pas suffisants.</p>",
      "other-tax": "Afin d’améliorer mon dossier, j’explique ci-dessous pourquoi je n'ai pas d’avis d’imposition au nom de mon garant. Cette explication sera ajoutée à mon dossier :",
      "owner": "J’ajoute un <b>justificatif de domicile de son garant</b> datant de <b>moins de trois mois.</b>",
      "pension": "<p>Merci d’ajouter ici <strong>l’un des documents suivants, au choix</strong> :</p><ul><li>un <strong>bulletin de pension</strong> de moins de 2 ans</li><li>ou son <strong>titre de pension de retraite</strong></li><li>ou son <strong>dernier ou avant-dernier avis d'imposition</strong> complet (toutes les pages)</li></ul>",
      "rent": "<p>Merci d’ajouter ici un justificatif de paiement de rente ou un avis d’imposition mentionnant clairement le montant de la rente. S’il s’agit d’une rente de type immobilière, vous pouvez ajouter ici la ou les dernières quittances des locataires.</p><p>Conseil : si vous n’avez pas de justificatif à fournir ici, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique la situation de son garant. Vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "salary": "J’ajoute un bulletin de salaire de son garant de moins de 3 mois, une attestation de son employeur, un justificatif de versement de ses indemnités de stage, un bilan comptable ou un avis d’imposition à son nom, complet et de moins de 2 ans.",
      "scholarship": "<p>Merci d’ajouter ici son <strong>dernier avis d’attribution de bourse</strong>.</p><p>Conseil : si vous n’avez pas de justificatif à fournir ici, vous pouvez ajouter ici une <strong>attestation sur l’honneur</strong> qui explique la situation de son garant. Vous pouvez utiliser ce <a href='https://www.service-public.fr/simulateur/calcul/AttestationHonneur' target='_blank' rel='noopener'>modèle d’attestation sur l’honneur</a>.</p>",
      "social-service": "Merci d’ajouter ici un <strong>justificatif de versement de prestations sociales</strong> de moins de 3 mois (ARE, CAF...)",
      "tenant": "J’ajoute un <b>justificatif de domicile de son garant</b> datant de <b>moins de trois mois.</b>",
      "noDocument-social": "Je ne peux pas fournir de justificatifs de versement de prestations sociales",
      "noDocument-salary": "Je ne peux pas fournir les trois derniers bulletins de salaire ou un bilan comptable de son garant",
      "noDocument-pension": "Je ne peux pas fournir de justificatifs de versement de pension",
      "noDocument-rent": "Je ne peux pas fournir de justificatifs de versement de rente",
      "noDocument-scholarship": "Je ne peux pas fournir de justificatifs d'attribution de bourse",
      "customText-social": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de son garant :",
      "customText-salary": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les trois derniers bulletins de salaire ou un bilan comptable de son garant :",
      "customText-pension": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de son garant :",
      "customText-rent": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de son garant :",
      "customText-scholarship": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de son garant :",
      "will-delete-files": "Attention, un changement de situation entraînera la suppression des justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à la situation de son garant."
    }
  },
  "account": {
    "title": {
      "dashboard": "Tableau de bord"
    },
    "content-title": "Vos documents",
    "share-file-button": "Partager votre dossier",
    "share-file-view-button": "Voir votre dossier",
    "last-update": "Dernière mise à jour du dossier le {0}",
    "file-update-title": "Mise à jour de votre dossier",
    "file-update-text": "Vous avez mis à jour votre dossier, pour la dernière fois le {0}.<br> Afin qu'il reste convaincant, il est important de maintenir à jour vos justificatifs.",
    "update-file-btn": "Mettre à jour mes documents",
    "copy-link": "Copier mon lien dossier",
    "share-by-mail": "Partager par mail",
    "identification": "Pièce d'identité",
    "residency": "Justificatif de situation d'hébergement",
    "professional": "Justificatif de situation professionnelle",
    "financial": "Justificatif de ressources",
    "tax": "Avis d’imposition",
    "denied": "Documents non vérifiés",
    "download-not-validated-title": "Télécharger mes documents non vérifiés",
    "download-not-validated-text": "Afin de candidater pendant la vérification de votre dossier, vous pouvez télécharger vos documents non vérifiés et filigranés.",
    "download-zip": "Télécharger",
    "TO_PROCESS": "est en cours de traitement",
    "VALIDATED": "est validé",
    "DECLINED": "nécessite des modifications",
    "INCOMPLETE": "est incomplet",
    "delete-bloc": {
      "title": "Suppression de mon compte",
      "content": "Vous pouvez supprimer votre compte DossierFacile à tout moment. La suppression de votre compte entrainera automatiquement l’effacement définitif de vos pièces justificatives.",
      "button": "Supprimer mon compte"
    },
    "opinion-bloc": {
      "title": "Racontez-nous votre expérience DossierFacile",
      "content": "Nous cherchons constamment à améliorer la qualité de notre service. Dans le cadre de cette démarche d’amélioration, nous cherchons à obtenir votre retour d’expérience. En cliquant sur le bouton ci-dessous, vous pourrez évaluer DossierFacile. Vos commentaires nous sont précieux ! Merci.",
      "warning": "Attention : ce formulaire ne nous permet pas de vous répondre. Si vous rencontrez un problème avec votre dossier, contactez plutôt notre équipe de support à cette adresse : <a href=\"mailto:contact{'@'}dossierfacile.logement.gouv.fr\">contact{'@'}dossierfacile.logement.gouv.fr</a> ou en utilisant <a href=\"/contact\">ce formulaire de contact</a>."
    },
    "share-file": "Partager votre dossier",
    "share-file-description": "Copiez votre lien-dossier pour le partager ! À vous de l'envoyer aux propriétaires ou bailleurs de votre choix (par mail, SMS, etc.)",
    "file-resume": "Partager votre dossier de synthèse <br>(sans pièce justificative)",
    "file-full": "Partager votre dossier complet<br>(avec pièces justificatives)",
    "copy": "Copier",
    "field-required": "Ce champ est requis",
    "CDI": "en CDI",
    "CDI_TRIAL": "en CDI (période d’essai)",
    "CDD": "en CDD",
    "ALTERNATION": "en alternance",
    "INTERNSHIP": "en stage",
    "STUDENT": "étudiant·e",
    "PUBLIC": "dans la fonction publique",
    "CTT": "en CTT (intérimaire)",
    "RETIRED": "retraite",
    "UNEMPLOYED": "au chômage",
    "INDEPENDENT": "indépendant",
    "OTHER": "Autre",
    "no-income": "ne pas avoir de revenu",
    "income": "avoir un revenu net mensuel de {0}€",
    "copied": "Copié !",
    "ALONE": "seul·e",
    "couple-with": "en couple avec {0}",
    "group-with": "en colocation avec {0}",
    "group-with-someone": "en colocation",
    "someone": " quelqu'un",
    "amendment-required-title": "Modifications demandées",
    "amendment-required-text": "Après examen de votre dossier, des modifications vous sont demandées. <br>Consultez votre messagerie pour en connaître le détail.",
    "messaging": "Consulter ma messagerie",
    "congratulations-title": "Votre DossierFacile est disponible",
    "congratulations-text-1": "Rendez-vous sur la page <a href=\"/applications\">Partager votre dossier</a> pour envoyer votre DossierFacile par e-mail ou par lien, avec ou sans justificatif.",
    "congratulations-text-2": "Pour plus d’informations, consultez la page <a href=\"https://aide.dossierfacile.logement.gouv.fr/fr/article/comment-visualiser-et-partager-son-dossier-valide-1orckmm/\">Visualiser et partager son dossier validé</a> de notre <a href=\"https://aide.dossierfacile.logement.gouv.fr\">aide en ligne</a>.",
    "full-link-copied": "Le lien de mon dossier complet est copié !",
    "public-link-copied": "Le lien de mon dossier de synthèse est copié !",
    "dossier": {
      "status": {
        "TO_PROCESS": "En cours de traitement",
        "VALIDATED": "Vérifié",
        "DECLINED": "Modification demandée",
        "INCOMPLETE": "Incomplet"
      }
    },
    "processing-bloc": {
      "title": "Délai de traitement",
      "text": "Votre dossier est actuellement en cours de traitement. Vous recevrez un e-mail lorsque notre équipe l’aura traité.",
      "badge": "Temps estimé : Entre {0}h et {1}h",
      "badge-undefined": "Temps estimé non disponible",
      "delay": "Votre dossier sera traité le {0} entre {1} et {2}",
      "delay-on-2days": "Votre dossier sera traité entre le {0} et le {1}",
      "delay-undefined": "Votre horaire de passage est en cours de calcul. Le délai de traitement moyen est de moins de 24h.",
      "last-update": "Date de dernière modification : {0}"
    }
  },
  "app": {
    "home": "Home"
  },
  "backnext": {
    "back": "Retour",
    "back-aria-label": "Revenir à l'étape précédente",
    "continue": "Continuer",
    "continue-aria-label": "Enregistrer les informations et continuer"
  },
  "changepasswordpage": {
    "error": "Erreur",
    "password-update": "Le mot de passe a été mis à jour",
    "token-expired": "Le lien a expiré, veuillez lancer la procédure de mot de passe oublié"
  },
  "confirmaccount": {
    "action-confirmed": "Votre action a bien été prise en compte.",
    "token-err": "Une erreur est survenue, si vous avez déjà confirmé votre compte vous pouvez vous connecter, sinon vous pouvez lancer la procédure de <a href='/forgotten-password'>changement de mot de passe</a>"
  },
  "corporationidentification": {
    "organism-name": "Nom de la personne morale",
    "organism-name-placeholder": "Nom de la personne morale",
    "kbis-label": "J’ajoute un extrait K bis de la société, ou toute autre pièce justifiant de l'existence légale de la personne.",
    "kbis": "Extrait K bis original de la société",
    "status": "Statuts de la personne morale",
    "all-accepted": "Toute autre pièce justifiant de l'existance légale de la personne, prouvant qu'une déclaration a été effectuée auprès d'une administration, une juridiction ou un organisme professionnel.",
    "balance-sheet": "Bilan comptable",
    "urssaf": "Attestation cotisation Urssaf",
    "all-other": "Toute autre pièce",
    "register": "Enregistrer la pièce",
    "field-required": "Ce champ est requis"
  },
  "cotenantfinancialform": {
    "title": "Revenus de votre conjoint",
    "tenant": "Locataire",
    "owner": "Propriétaire",
    "guest": "Hébergé·e gratuitement",
    "guest-parents": "Chez ses parents",
    "files": "Documents",
    "will-delete-files": "Attention, un changement de situation entraînera la suppression de vos justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à sa situation.",
    "salary": "Revenus professionnels",
    "social-service": "Prestations sociales",
    "rent": "Rentes",
    "pension": "Pensions",
    "scholarship": "Bourses",
    "no-income": "Pas de revenus",
    "monthlySum": {
      "placeholder": "Montant en euros",
      "label": "Indiquez le montant de ses revenus mensuels nets (arrondi à l'euro)",
      "label-tax": "avant prélèvement à la source"
    },
    "noDocument-social-service": "Je ne peux pas fournir de justificatifs de versement de prestations sociales",
    "noDocument-salary": "Je ne peux pas fournir ses trois derniers bulletins de salaire ",
    "noDocument-pension": "Je ne peux pas fournir de justificatifs de versement de pension",
    "noDocument-rent": "Je ne peux pas fournir de justificatifs de versement de rente",
    "noDocument-scholarship": "Je ne peux pas fournir de justificatifs d'attribution de bourse",
    "customText-social-service": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de mon conjoint :",
    "customText-salary": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les trois derniers bulletins de salaire ou un bilan comptable de mon conjoint :",
    "customText-pension": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de mon conjoint :",
    "customText-rent": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de mon conjoint :",
    "customText-scholarship": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs de mon conjoint :",
    "customText-undefined": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir les justificatifs",
    "high-salary": "Vous avez saisi un salaire supérieur à 10 000€ êtes-vous sûr d'avoir saisi le salaire mensuel ?",
    "low-salary": "Vous avez saisi un salaire égal à 0€ êtes-vous sûr d'avoir saisi le salaire mensuel ?",
    "has-no-income": "Vous avez indiqué que votre conjoint n'a pas de revenus. Vous pouvez ajouter une explication sur sa situation ici si vous le souhaitez :",
    "number-not-valid": "Valeur incorrecte - entrez un chiffre sans virgule",
    "delete-financial": "Supprimer ce revenu",
    "field-required": "Ce champ est requis",
    "register": "Enregistrer",
    "missing-file": "Vous devez ajouter des fichiers pour sauvegarder ce revenu.",
    "warning-no-income-and-file": "Vous ne pouvez pas avoir des fichiers et indiquer ne pas pouvoir fournir les fichiers. Veuillez décocher la case ou supprimer vos fichiers."
  },
  "cotenantfinanciallist": {
    "delete-financial": "Supprimer ce revenu",
    "register": "Enregistrer",
    "i-have-no-income": "Je n'ai pas de revenu",
    "title": "Récapitulatif des revenus de votre conjoint",
    "subtitle": "Voici la liste des revenus que vous avez déclarés. Vous pouvez, à tout moment ajouter de nouveaux revenus, si cela était nécessaire.",
    "monthly": " € net mensuel ",
    "net-monthly": "Net à payer mensuel",
    "add-income": "Ajouter un nouveau revenu ?"
  },
  "cotenantidentification": {
    "title": "La pièce d'identité de votre conjoint",
    "description": "Veillez à ajouter le recto et le verso.",
    "identity-card": "Carte d’identité française",
    "passport": "Passeport français",
    "permit": "Titre de séjour français",
    "other": "Autre"
  },
  "cotenantname": {
    "lastname": "Nom de naissance",
    "firstname": "Prénom",
    "preferredname": "Nom d'usage",
    "email": "E-mail",
    "email-not-valid": "Email non valide",
    "lastname-placeholder": "ex: Dupont",
    "firstname-placeholder": "ex: Jean",
    "field-required": "Ce champ est requis",
    "title": "Je renseigne les informations personnelles de mon conjoint",
    "subtitle": "Veuillez renseigner les informations de la personne dont le nom figurera sur le bail de location.",
    "franceconnect-account": "Ce compte est identifié avec FranceConnect, les noms et prénoms ne sont pas éditables."
  },
  "cotenantprofessional": {
    "title": "La situation professionnelle actuelle de votre conjoint",
    "cdi": "CDI",
    "cdi-trial": "CDI (période d’essai)",
    "cdd": "CDD",
    "alternation": "Alternance",
    "internship": "Stage",
    "student": "Études",
    "public": "Fonction publique",
    "ctt": "CTT (intérimaire)",
    "retired": "retraite",
    "unemployed": "Chômage",
    "independent": "Indépendant",
    "other": "Autre"
  },
  "cotenantresidency": {
    "title": "La situation d’hébergement actuelle de votre conjoint",
    "tenant": "Locataire",
    "owner": "Propriétaire",
    "guest": "Hébergé·e gratuitement",
    "guest-parents": "Chez ses parents"
  },
  "cotenanttax": {
    "title": "L'avis d'imposition de votre conjoint",
    "my-name": "Votre conjoint a un avis d’imposition à son nom",
    "my-parents": "Votre conjoint est rattaché fiscalement à ses parents",
    "less-than-year": "Votre conjoint est en France depuis moins d'un an",
    "other-tax": "Autre situation",
    "accept-verification": "J'accepte que DossierFacile procède à une vérification automatisée de la fiche d'imposition de mon garant auprès des services des impôts",
    "custom-text": "Merci d’expliquer ici la raison pour laquelle vous ne pouvez pas fournir d’avis d’imposition. Votre explication sera ajoutée à votre dossier :",
    "situation": "Quelle est sa situation fiscale ?",
    "warning-no-accepted-doc": "Attention, l'avis de situation déclarative n'est pas accepté.",
    "goto-documentation": "Consulter la documentation",
    "custom-text-required": "Veuillez remplir le champs d'explication"
  },
  "coupleinformationhelp": {
    "paragraph1": "En renseignant l’adresse email de votre conjoint, celui-ci sera invité à contribuer à la constitution de votre dossier commun. Avec ses propres identifiants email et mot de passe, il aura la possibilité de se connecter à votre dossier commun et d'ajouter, modifier ou supprimer toute pièce du dossier, ses pièces personnelles comme les vôtres.",
    "paragraph2": "Attention ! Vos pièces personnelles et ses pièces personnelles seront traitées séparément par DossierFacile. Vous pouvez ainsi les déposer et les faire valider indépendamment. Néanmoins, votre dossier commun ne sera validé dans son ensemble que lorsque vos pièces et ses pièces auront été validées par DossierFacile. Vous disposerez alors d'un unique lien de partage pour l'ensemble de votre dossier commun.",
    "paragraph3": "Concrètement, votre conjoint recevra une invitation par mail qu’il ou elle devra valider dans les 48 heures. Il n'aura qu'à cliquer sur le lien, définir son mot de passe, et il sera alors connecté à votre dossier commun."
  },
  "coupleinformation": {
    "spouseFirstName": "Prénom",
    "spouseLastName": "Nom",
    "spouseEmail": "L’adresse email de votre conjoint·e (facultatif)",
    "acceptAuthor": "J’accepte que mon partenaire ait accès à mes documents ainsi qu’à ceux de mon garant le cas échéant une fois que nos deux dossiers auront été validés.<br/>Je consens aussi au fait que mon partenaire puisse partager les pièces de notre dossier à des propriétaires, des bailleurs ou des services immobiliers partenaires de DossierFacile.",
    "field-required": "Ce champ est requis",
    "partner-name-title": "Qui sera votre conjoint·e ?",
    "partner-email-title": "L'inviter à remplir son dossier ?",
    "add-a-spouse": "Ajouter votre conjoint·e",
    "email-exists": "Vous ne pouvez pas associer deux comptes à une même adresse email ! <br>Renseignez une adresse email différente.",
    "more-information": "Comment ça marche ?"
  },
  "deleteaccount": {
    "validate": "Supprimer mon compte",
    "validate-mobile": "Supprimer",
    "cancel": "Annuler",
    "title": "Suppression de compte",
    "confirm-delete": "Veuillez confirmer la suppression complète du compte",
    "try-again": "Une erreur est survenue, veuillez réessayer plus tard."
  },
  "documentdownloader": {
    "files": "Documents",
    "will-delete-files": "Attention, un changement de situation entraînera la suppression des justificatifs. Vous devrez charger de nouveau les justificatifs.",
    "register": "Enregistrer la pièce",
    "validate": "Valider",
    "cancel": "Annuler",
    "field-required": "Ce champ est requis",
    "warning-no-document-and-files": "Vous ne pouvez pas avoir des fichiers et indiquer ne pas pouvoir fournir tous les fichiers. Veuillez décocher la case ou supprimer vos fichiers.",
    "noDocument-default": "Je ne peux pas fournir les justificatifs demandés"
  },
  "documenthelp": {
    "title": "Aide",
    "paragraph1": "Si votre pièce est trop lourde, nous vous conseillons de scanner votre pièce (avec une application gratuite pour smartphone par exemple).<br> Si cela ne suffit pas, contactez-nous par mail à l’adresse <a href='mailto:contact{'@'}dossierfacile.logement.gouv.fr'>contact{'@'}dossierfacile.logement.gouv.fr</a>, nous nous efforcerons de répondre au plus vite !"
  },
  "documentinsert": {},
  "fileerrors": {
    "IDENTIFICATION": "Pièce d'identité",
    "RESIDENCY": "Justificatif de situation d'hébergement",
    "PROFESSIONAL": "Justificatif de situation professionnelle",
    "FINANCIAL": "Justificatif de ressources",
    "TAX": "Avis d'imposition",
    "file-not-valid": "Votre dossier n'est pas valide, veuillez compléter les documents manquants ou modifier les documents refusés pour soumettre votre dossier",
    "error-title": "Dossier invalide",
    "error-title-guarantor": "Dossier garant invalide",
    "his-missing-documents": "Ses documents invalides :",
    "update": "Mettre à jour",
    "organism-cert": "Attestation de garantie",
    "representative-identification": "Identité du représentant de la personne morale",
    "corporation-identification": "Identité de la personne morale",
    "my-invalid-document": "Mes documents invalides :",
    "my-invalid-names": " Mes informations manquantes",
    "my-invalid-document-guarantor": "Les documents invalides de mon garant :",
    "my-invalid-names-guarantor": "Le nom ou prénom de mon garant est manquant:",
    "tenant-invalid-document": "Les documents invalides de mon conjoint :",
    "tenant-invalid-names": "Le nom ou prénom de mon conjoint est manquant:",
    "tenant-invalid-document-guarantor": "Les documents invalides du garant de mon conjoint :",
    "tenant-invalid-names-guarantor": "Le informations du garant de mon conjoint sont invalides:",
    "user-names": "Informations personnelles"
  },
  "fakeannouncement": {
    "fake-announce-title": "Signaler une fausse annonce !",
    "content": "Si vous pensez avoir affaire à une escroquerie à la petite annonce, {nodocument}, et faites un signalement sur {masecurite}, site du ministère de l'Intérieur.",
    "nodocument": "n'envoyez aucun document",
    "masecurite": "Ma Sécurité",
    "link-title": "Aller sur le site masecurite.fr (nouvelle fenêtre)",
    "signal-button": "Signaler une annonce"
  },
  "file": {
    "title": "Dossier locataire de {0}",
    "description": "{0} {1}",
    "guarant": "Pièces du(des) garant(s)",
    "personnal-file": "Pièces du candidat",
    "identification": "Pièce d’identité",
    "residency": "Justificatif de situation d'hébergement",
    "professional": "Justificatif de situation professionnelle",
    "financial": "Justificatif de ressources",
    "tax": "Avis d’imposition",
    "tax-verified": "Revenu fiscal certifié auprès des services des impôts",
    "download-all": "Télécharger le dossier complet (.pdf)",
    "download-all-inprogress": "Téléchargement en cours...",
    "ALONE": "Seul",
    "COUPLE": "En couple",
    "GROUP": "En colocation",
    "no-income": "sans revenu",
    "income": "avec un revenu net mensuel de {0}€",
    "organism": "Certificat de l'organisme",
    "identification-legal-person": "Identification de la personne morale",
    "and": " et ",
    "download-failed": "Echec du téléchargement. Ressayez dans quelques minutes.",
    "download-disabled-title": "Le dossier pourra être téléchargé lorsque celui-ci aura été entièrement vérifié par DossierFacile.",
    "not-found": {
      "title": "Dossier introuvable",
      "line1": "Ce dossier de location est introuvable.",
      "line2": "Le lien est erroné ou il a été supprimé par le candidat."
    },
    "disclaimer": "Le service fourni par DossierFacile ne saurait être assimilé à une garantie apportée par DossierFacile sur les dossiers ayant fait l’objet d’une labellisation. DossierFacile ne saurait être tenu responsable ni être engagé directement ou indirectement dans le cadre d'un litige entre un locataire et son bailleur ou tout autre intermédiaire."
  },
  "filereinsurance": {
    "documents-VALIDATED": "Le dossier contient les pièces requises",
    "documents-TO_PROCESS": "Les pièces du dossier sont en cours de vérification",
    "documents-DECLINED": "Le dossier ne contient pas encore les pièces requises",
    "documents-INCOMPLETE": "Le dossier ne contient pas encore les pièces requises",
    "tax-to_process": "L'avis d'imposition est en cours de vérification",
    "tax-nok": "L'avis d'imposition n'a pas pu être vérifié",
    "file-VALIDATED": "Les pièces sont cohérentes et ont été revues manuellement par nos agents",
    "file-TO_PROCESS": "Nos agents s'assurent de la cohérence de l'ensemble des pièces justificatives",
    "file-DECLINED": "Les pièces seront revues manuellement par nos agents une fois le dossier complété",
    "file-INCOMPLETE": "Les pièces seront revues manuellement par nos agents une fois le dossier complété",
    "title-dossier-VALIDATED": "Ce dossier a été vérifié par DossierFacile !",
    "title-dossier-DECLINED": "Ce dossier est, pour l'heure, refusé !",
    "title-dossier-TO_PROCESS": "Ce dossier est en cours de vérification par DossierFacile !",
    "title-dossier-INCOMPLETE": "Ce dossier est, pour l'heure, incomplet !",
    "know-more": "En savoir plus sur DossierFacile",
    "france-connect-user": "L'identité du candidat a été certifiée via une authentification FranceConnect.",
    "france-connect-user-all": "L'identité des candidats a été certifiée via une authentification FranceConnect.",
    "france-connect-user-partial": "L'identité de {0} candidat(s) a été certifiée via une authentification FranceConnect.",
    "tax-checked": "Revenu fiscal certifié auprès des services des impôts"
  },
  "filerowlistitem": {
    "edit": "Mettre à jour",
    "see": "Voir"
  },
  "fileupload": {
    "drag-and-drop-files": "Glissez et déposez vos documents",
    "files-format": "JPG, PNG, heic ou PDF",
    "or": "ou",
    "browse-files": "Parcourez vos fichiers",
    "uploading-files": "Envoi des fichiers...",
    "send-problem": "Problème d'envoi.",
    "file-too-big": "La taille d'un fichier ne doit pas dépasser {0}Mo",
    "pages": "{0} pages maximum",
    "size": " {0}Mo maximum par fichier"
  },
  "financialdocumentform": {
    "title": "Vos revenus",
    "will-delete-files": "Attention, un changement de situation entraînera la suppression de vos justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à votre situation.",
    "monthlySum": {
      "placeholder": "Montant en euros",
      "label": "Indiquez le montant de vos revenus mensuels nets",
      "label-tax": "avant prélèvement à la source (arrondi à l'euro)"
    },
    "delete-financial": "Supprimer ce revenu",
    "customText-social-service": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir mes justificatifs :",
    "customText-salary": "Merci de bien vouloir nous expliquer pourquoi vous ne pouvez pas fournir de document justifiant votre revenu. Le message sera transmis à notre équipe de validation, qui pourra revenir vers vous à ce sujet :",
    "customText-pension": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir mes justificatifs :",
    "customText-rent": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir mes justificatifs :",
    "customText-scholarship": "Afin d'améliorer mon dossier, j'explique pourquoi je ne peux pas fournir mes justificatifs :",
    "income-zero": "Si vous n'avez pas de revenus, veuillez sélectionner la catégorie 'Pas de revenus'",
    "noDocument-social": "Je ne peux pas fournir de justificatifs de versement de prestations sociales",
    "noDocument-salary": "Je ne peux pas fournir de document permettant de justifier ces revenus ",
    "noDocument-pension": "Je ne peux pas fournir de justificatifs de versement de pension",
    "noDocument-rent": "Je ne peux pas fournir de justificatifs de versement de rente",
    "noDocument-scholarship": "Je ne peux pas fournir de justificatifs d'attribution de bourse",
    "missing-file": "Vous devez ajouter des fichiers pour sauvegarder ce revenu.",
    "warning-no-income-and-file": "Vous ne pouvez pas avoir des fichiers et indiquer ne pas pouvoir fournir tous les fichiers. Veuillez décocher la case ou supprimer vos fichiers.",
    "high-salary": "Vous avez saisi un salaire supérieur à 10 000€ êtes-vous sûr d'avoir saisi votre salaire mensuel ?",
    "low-salary": "Vous avez saisi un salaire égal à 0€ êtes-vous sûr d'avoir saisi votre salaire mensuel ?",
    "has-no-income": "Vous avez indiqué ne pas avoir de revenus. Vous pouvez ajouter une explication sur votre situation ici si vous le souhaitez :",
    "number-not-valid": "Valeur incorrecte - entrez un chiffre sans virgule"
  },
  "financial-page": {
    "delete-financial": "Supprimer ce revenu",
    "register": "Enregistrer",
    "i-have-no-income": "Je n'ai pas de revenu",
    "title": "Récapitulatif de vos revenus",
    "subtitle": "Voici la liste des revenus que vous avez déclarés. Vous pouvez, à tout moment ajouter de nouveaux revenus, si cela était nécessaire.",
    "monthly": " € net mensuel ",
    "net-monthly": "Net à payer mensuel",
    "add-income": "Ajouter un nouveau revenu ?"
  },
  "forgottenpasswordpage": {
    "email-not-found": "Email non trouvé",
    "mail-sent": "Un mail vous a été envoyé à l'adresse indiquée.",
    "clic-to-confirm": "Veuillez cliquer sur le lien envoyé afin de confirmer votre adresse mail et poursuivre le changement de mot de passe."
  },
  "guarantorchoicehelp": {
    "paragraph1": "Un garant (ou caution) est une personne physique ou morale qui s’engage envers le propriétaire du logement loué à payer les dettes locatives du locataire.<br> Plus d’informations sur <a target='_blank' href='https://www.service-public.fr/particuliers/vosdroits/F31267'>https://www.service-public.fr/particuliers/vosdroits/F31267</a>",
    "paragraph2": "Certains organismes peuvent aussi constituer une garantie, obtenez gratuitement une garantie Visale sur <a target='_blank' href='https://www.visale.fr'>www.visale.fr</a> !",
    "paragraph3": "Attention : vous <b>n’êtes pas obligé‧e d’ajouter un garant.</b> Cependant, certains propriétaires peuvent en exiger un."
  },
  "guarantorchoice": {
    "add-guarantor": "Voulez-vous ajouter un garant ?",
    "representative-identification": "Identité de la personne morale",
    "corporation-identification": "Identité du représentant de la personne morale",
    "guarantor": "Garant",
    "validate": "Valider",
    "will-delete-guarantor": "Voulez-vous vraiment changer le type de garant ?",
    "validate-file": "Étape suivante - Valider le dossier",
    "natural-person": {
      "label": "Une personne",
      "description": "Exemple : un parent, un proche"
    },
    "organism": {
      "label": "Un organisme",
      "description": "Exemple : Visale"
    },
    "legal-person": {
      "label": "Une personne morale",
      "description": "Exemple : une entreprise"
    },
    "ask-guarantor": "Votre garant est :",
    "optional-guarantor": "Ajouter un garant n’est pas obligatoire. Si vous n'avez pas de garant, vous pouvez sélectionner <em>pas de garant</em> en bas de la page pour passer à l'étape suivante.",
    "two-guarantors-warning": "Si vous avez 2 garants, ajoutez-les l'un après l'autre.<br/><strong>Les documents de vos 2 garants ne doivent pas être mélangés</strong>.",
    "no-guarantor": "Vous n'avez pas de garant",
    "type-required": "Veuillez sélectionner un choix",
    "visale-title": "Connaissez-vous Visale ?",
    "visale-text": "Visale est le garant de votre futur logement si vous avez entre 18 et 30 ans OU si vous êtes salariés de + de 30 ans (soumis à conditions).",
    "visale-btn": "Découvrir Visale"
  },
  "guarantordocuments": {
    "identification": "Pièce d’identité",
    "residency": "Justificatif de situation d'hébergement",
    "professional": "Justificatif de situation professionnelle",
    "financial": "Justificatif de ressources",
    "tax": "Avis d’imposition",
    "guarantor": "Garant",
    "validate": "Valider",
    "will-delete-guarantor": "Voulez-vous vraiment changer le type de garant ?",
    "validate-file": "Étape suivante - Valider le dossier"
  },
  "guarantordocumentspage": {},
  "guarantorfinancialdocumentform": {
    "title": {
      "guarantor": "Revenus de votre garant",
      "cotenant-guarantor": "Revenus de son garant"
    },
    "monthlySum": {
      "placeholder": "Montant en euros",
      "label": "Indiquez le montant de ses revenus mensuels nets",
      "label-tax": "avant prélèvement à la source (arrondi à l'euro)"
    },
    "high-salary": "Vous avez saisi un salaire supérieur à 10 000€ êtes-vous sûr d'avoir saisi le salaire mensuel ?",
    "low-salary": "Vous avez saisi un salaire égal à 0€ êtes-vous sûr d'avoir saisi le salaire mensuel ?",
    "number-not-valid": "Valeur incorrecte - entrez un chiffre sans virgule",
    "delete-financial": "Supprimer ce revenu",
    "field-required": "Ce champ est requis",
    "register": "Enregistrer",
    "missing-file": "Vous devez ajouter des fichiers pour sauvegarder ce revenu.",
    "warning-no-income-and-file": "Vous ne pouvez pas avoir des fichiers et indiquer ne pas pouvoir fournir tous les fichiers. Veuillez décocher la case ou supprimer vos fichiers."
  },
  "guarantorfinancial": {
    "delete-financial": "Supprimer ce revenu",
    "register": "Enregistrer",
    "select-label": "Attention, veuillez renseigner uniquement vos propres revenus.",
    "i-have-no-income": "Je n'ai pas de revenu",
    "warning-no-income-and-file": "Vous ne pouvez pas avoir des fichiers et indiquer ne pas pouvoir fournir tous les fichiers. Veuillez décocher la case ou supprimer vos fichiers.",
    "title": "Récapitulatif des revenus du garant",
    "subtitle": "Voici la liste des revenus que vous avez déclarés. Vous pouvez, à tout moment ajouter de nouveaux revenus, si cela était nécessaire.",
    "monthly": " € net mensuel ",
    "net-monthly": "Net à payer mensuel",
    "add-income": "Ajouter un nouveau revenu ?"
  },
  "guarantorfooter": {},
  "guarantoridentification": {
    "identity-card": "Carte d’identité française",
    "passport": "Passeport français",
    "permit": "Titre de séjour français",
    "other": "Autre",
    "files": "Documents",
    "will-delete-files": "Attention, un changement de situation entraînera la suppression des justificatifs. Vous devrez charger de nouveau les justificatifs.",
    "register": "Enregistrer la pièce",
    "title": "J’ajoute la pièce d’identité, en cours de validité, de mon garant",
    "title-cotenant": "J’ajoute la pièce d’identité, en cours de validité, de son garant",
    "select-label": "Veuillez à ajouter le recto et le verso !",
    "validate": "Valider",
    "cancel": "Annuler",
    "field-required": "Ce champ est requis"
  },
  "guarantorlistpage": {
    "my-guarantor": "Mes garants",
    "add-new-guarantor": "Ajouter un nouveau garant ?",
    "guarantor": {
      "NATURAL_PERSON": "Mon garant",
      "LEGAL_PERSON": "Mon entreprise garante",
      "ORGANISM": "Mon organisme garant"
    },
    "remove-guarantor": "Êtes-vous sûr·e de vouloir supprimer ce garant ?"
  },
  "guarantorname": {
    "lastname": "Nom",
    "firstname": "Prénom",
    "lastname-placeholder": "ex: Dupont",
    "firstname-placeholder": "ex: Jean",
    "field-required": "Ce champ est requis",
    "title": "Identité de votre garant"
  },
  "guarantorprofessional": {
    "title": "Situation professionnelle de votre garant",
    "title-cotenant": "Situation professionnelle de son garant",
    "cdi": "CDI",
    "cdi-trial": "CDI (période d’essai)",
    "cdd": "CDD",
    "alternation": "Alternance",
    "internship": "Stage",
    "student": "Études",
    "public": "Fonction publique",
    "ctt": "CTT (intérimaire)",
    "retired": "Retraite",
    "unemployed": "Chômage",
    "independent": "Indépendant",
    "other": "Autre",
    "will-delete-files": "Attention, un changement de situation entraînera la suppression de vos justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à votre situation.",
    "register": "Enregistrer",
    "select-label-cotenant": "La situation professionnelle, actuelle, de son garant :"
  },
  "guarantorresidency": {
    "tenant": "Locataire",
    "owner": "Propriétaire",
    "guest": "Hébergé par ses parents, par un ami, par un proche",
    "guest-company": "Logement de fonction",
    "guest-organism": "Hébergé par un organisme (hébergement d’urgence, placement)",
    "short-term-rental": "À l’hôtel, au camping ou en location de tourisme (B&B)",
    "other-residency": "Dans une autre situation (sans-abri, etc.)",
    "files": "Documents",
    "will-delete-files": "Attention, un changement de situation entraînera la suppression de vos justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à votre situation.",
    "register": "Enregistrer",
    "select-label": "Quelle est la situation d’hébergement actuelle de votre garant ?"
  },
  "guarantorssection": {
    "guarantors-information": "Les justificatifs du garant",
    "my-guarantor": "Les justificatifs de",
    "identification-legal-person": "Identification de la personne morale",
    "identity-represent": "Identité représentant",
    "organism-identification": "Certificat de l'organisme",
    "delete-guarantor": "Supprimer ce garant",
    "confirm-delete-guarantor": "Êtes-vous sur de vouloir supprimer ce garant ?",
    "add-guarantor": "Ajouter un garant ?",
    "visale-title": "Connaissez-vous Visale ?",
    "visale-text": "Visale est le garant de votre futur logement si vous avez entre 18 et 30 ans OU si vous êtes salariés de + de 30 ans (soumis à conditions).",
    "go-to-visale": "Aller sur le site de « visale » (nouvelle fenêtre)",
    "identification": "Pièce d'identité",
    "residency": "Justificatif de situation d'hébergement",
    "professional": "Justificatif de situation professionnelle",
    "financial": "Justificatif de ressources",
    "tax": "Avis d’imposition",
    "TO_PROCESS": "est en cours de traitement",
    "VALIDATED": "est vérifié",
    "DECLINED": "nécessite une modification",
    "INCOMPLETE": "est non terminé"
  },
  "guarantortax": {
    "custom-text": "Merci d’expliquer ici la raison pour laquelle vous ne pouvez pas fournir d’avis d’imposition. Votre explication sera ajoutée à votre dossier :",
    "files": "Documents",
    "register": "Enregistrer",
    "field-required": "Ce champ est requis",
    "will-delete-files": "Attention, un changement de situation entraînera la suppression de vos justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à votre situation.",
    "title": "L'avis d'imposition de votre garant",
    "title-cotenant": "L'avis d'imposition de son garant",
    "situation": "Quelle est sa situation fiscale ?",
    "warning-no-accepted-doc": "Attention, l'avis de situation déclarative n'est pas accepté.",
    "goto-documentation": "Consulter la documentation",
    "my-name": "Votre garant a un avis d'imposition à son nom",
    "less-than-year": "Votre garant est en France depuis moins d'un an",
    "other-tax": "Autre situation",
    "my-name-cotenant": "Son garant a un avis d'imposition à son nom",
    "less-than-year-cotenant": "Son garant est en France depuis moins d'un an",
    "other-tax-cotenant": "Autre situation"
  },
  "identification-page": {
    "files": "Documents",
    "will-delete-files": "Attention, un changement de situation entraînera la suppression de vos justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à votre situation.",
    "select-label": "Déposez une pièce d'identité en cours de validité",
    "validate": "Valider",
    "cancel": "Annuler"
  },
  "joincouple": {
    "error": "Erreur",
    "password-update": "Le mot de passe a été mis à jour",
    "title": "Création du mot de passe",
    "already-logged": "Vous êtes déjà en mode connecté, voulez-vous vous déconnecter pour créer ce compte ?",
    "token-expired": "Le lien a expiré, veuillez lancer la procédure de mot de passe oublié"
  },
  "joingroup": {
    "error": "Erreur",
    "password-update": "Le mot de passe a été mis à jour",
    "title": "Création du mot de passe",
    "already-logged": "Vous êtes déjà en mode connecté, voulez-vous vous déconnecter pour créer ce compte ?",
    "bad-request": "Le token dans l'url fourni semble incorrect"
  },
  "listitem": {
    "FRENCH_IDENTITY_CARD": "Carte d'identité française",
    "FRENCH_PASSPORT": "Passport français",
    "FRENCH_RESIDENCE_PERMIT": "Titre de séjour français",
    "OTHER_IDENTIFICATION": "Autre",
    "remove": "Supprimer la pièce",
    "show": "Afficher la pièce",
    "see": "Voir",
    "delete": "Supprimer",
    "mb": "Mo",
    "kb": "Ko",
    "will-delete-file": "Êtes-vous sûr de vouloir supprimer ce fichier ?"
  },
  "menu": {
    "help": "Aide",
    "file": "Votre dossier",
    "partners": "Vos partages",
    "applications": "Vos candidatures",
    "account": "Mon compte",
    "messaging": "Messagerie",
    "blog": "Blog",
    "information": "Qui sommes-nous ?",
    "contact-us": "Nous contacter",
    "deleteAccount": "Supprimer mon compte"
  },
  "messagespanel": {
    "identification-legal-person": "Identité personne morale",
    "identity-represent": "Identité représentant",
    "organism": "Organisme"
  },
  "nameinformationform": {
    "confirm": "Confirmer",
    "firstname": "Prénom",
    "lastname": "Nom de naissance",
    "preferredname": "Nom d'usage",
    "add-preferredname": "Ajouter un nom d'usage",
    "delete-preferredname": "Supprimer le nom d'usage",
    "zipcode": "Code postal (uniquement si vous résidez en France)",
    "abroad-residency-checkbox": "Vous résidez actuellement à l'étranger",
    "field-required": "Ce champ est requis",
    "title": "Votre identité",
    "unlink-fc-link": "Modifier vos informations ?",
    "unlink-fc-content": "Afin d’apporter des modifications sur vos nom et prénoms, il vous faut dissocier vos comptes FranceConnect et DossierFacile.",
    "unlink-fc-content-password-exists": "Vous pourrez alors utiliser le mot de passe que vous avez précédemment défini.",
    "unlink-fc-content-password-undefined": "Vous serez ensuite redirigé sur la page de mise à jour du mot de passe.",
    "unlink-account-btn": "Dissocier mes comptes"
  },
  "nameinformationhelp": {
    "info": "Si vous remplissez le dossier pour le compte d'une autre personne, par exemple votre enfant, veuillez renseigner ses informations et non les vôtres.",
    "info-fc": "Le dossier doit être au nom du futur locataire. Si vous remplissez le dossier pour le compte d'une autre personne, par exemple votre enfant, vous devez ",
    "unlink-fc": "modifier ces informations"
  },
  "nameinformation": {
    "title": "Votre dossier de location en 5 justificatifs !",
    "join-title": "Vous n'êtes qu'à quelques étapes de rejoindre le dossier de location de {0} !",
    "roommate": "votre colocataire",
    "spouse": "votre conjoint",
    "prepare-documents": "Préparez vos pièces justificatives :",
    "identification": "Pièce d'identité",
    "residency": "Justificatif de situation d'hébergement",
    "professional": "Justificatif de situation professionnelle",
    "financial": "Justificatif de ressources",
    "tax": "Avis d’imposition",
    "same-for-guarantors": "Et la même liste pour vos garants !",
    "see-documentation": "Consultez notre documentation"
  },
  "ownerbanner": {
    "title1": "Propriétaire,",
    "title2": "Trouvez votre Perle Rare du Locataire !",
    "text1": "Espace 100% gratuit",
    "text2": "Centralisez vos candidatures",
    "text3": "Tous les dossiers sont complets !",
    "btn": "Créer mon compte propriétaire"
  },
  "ownershare": {
    "title": "Candidatez pour le logement situé au {0}",
    "subtitle": "DossierFacile est une startup d'État qui vous permet de créer votre dossier de location intelligent et de le partager avec des propriétaires",
    "login-success": "Connexion réussie",
    "login-error": "Problème de connexion",
    "accept-owner": "J'accepte que {0}, propriétaire situé au {1} ait accès aux informations de mon dossier et de ceux de mon(ma) conjoint(e) ou de mon(mes) colocataire(s) le cas échéant. Conformément au RGPD, je peux révoquer cette autorisation à tout moment.",
    "field-required": "Ce champ est requis",
    "connect-owner": "C'est parti !",
    "no-account-1": "Si vous n'avez pas de compte, vous pouvez en <a href=\"/signup\">créer un</a> et revenir ultérieurement sur cette page.",
    "no-account-2": "Notre dossier est facile à remplir (en moins de 3 minutes c'est promis) et en plus il est conforme à la loi.",
    "no-account-3": "Et réutilisable pour toutes vos autres visites !",
    "connection-success": "Votre dossier a bien été partagé au propriétaire",
    "join-account": "En tant que compte invité, vous ne pouvez pas lier votre dossier à un compte propriétaire. Veuillez demander au compte qui vous a invité de le faire.",
    "login": "Se connecter"
  },
  "partnerssection": {
    "partners-services-section": "Connaissez-vous les services suivants ?",
    "mds-title": "Êtes-vous éligible ?",
    "mds-text": "Découvrez en quelques clics les prestations sociales que vous pouvez demander",
    "mds-btn": "Simuler mes droits sociaux",
    "anil-title": "Une question logement ?",
    "anil-text": "Une information pratique sur vos droits et obligations ? \n Votre ADIL vous conseille gratuitement",
    "anil-btn": "Contacter mon ADIL",
    "go-to-anil": "Aller sur le site de l'ANIL (nouvelle fenêtre)",
    "go-to-mds": "Aller sur le site de « mes droits sociaux » (nouvelle fenêtre)",
    "go-to-signal": "Aller sur le site de « ma securité » (nouvelle fenêtre)",
    "signal-title": "Signaler une fausse annonce !",
    "link-title": "Aller sur le site masecurite.fr (nouvelle fenêtre)",
    "signal-text": "Si vous pensez avoir affaire à une escroquerie à la petite annonce, {nodocument}, et faites un signalement sur {masecurite}, site du ministère de l'Intérieur.",
    "nodocument": "n'envoyez aucun document",
    "masecurite": "Ma Sécurité",
    "signal-btn": "Signaler une annonce"
  },
  "professional-page": {
    "cdi": "CDI",
    "cdi-trial": "CDI (période d’essai)",
    "cdd": "CDD",
    "alternation": "Alternance",
    "internship": "Stage",
    "student": "Études",
    "public": "Fonction publique",
    "ctt": "CTT (intérimaire)",
    "retired": "Retraite",
    "unemployed": "Chômage",
    "independent": "Indépendant",
    "other": "Autre",
    "will-delete-files": "Attention, un changement de situation entraînera la suppression de vos justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à votre situation.",
    "select-label": "Votre situation professionnelle"
  },
  "profilefooter": {
    "back": "Retour",
    "continue": "Continuer"
  },
  "publicfile": {
    "title": "Dossier locataire de {0}",
    "description": "{0} {1}",
    "guarant": "Pièces du(des) garant(s)",
    "personnal-file": "Pièces du candidat",
    "identification": "Pièce d’identité",
    "residency": "Justificatif de situation d'hébergement",
    "professional": "Justificatif de situation professionnelle",
    "financial": "Justificatif de ressources",
    "tax": "Avis d’imposition",
    "tax-verified": "Revenu fiscal certifié auprès des services des impôts",
    "ALONE": "Seul",
    "COUPLE": "En couple",
    "GROUP": "En colocation",
    "no-income": "sans revenu",
    "income": "avec un revenu net mensuel de {0}€",
    "organism": "Certificat de l'organisme",
    "identification-legal-person": "Identification de la personne morale",
    "and": " et "
  },
  "representativeidentification": {
    "organism-name": "Nom du représentant de la personne morale",
    "organism-name-placeholder": "Nom",
    "identity-card": "Carte d’identité française",
    "passport": "Passeport français",
    "permit": "Titre de séjour français",
    "other": "Autre"
  },
  "residency-page": {
    "will-delete-files": "Attention, un changement de situation entraînera la suppression de vos justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à votre situation.",
    "select-label": "Décrivez votre situation d’hébergement actuelle",
    "warning-other-residency": "Si vous êtes locataire ou hébergé gratuitement, veuillez sélectionner l'option correspondante, sinon votre dossier sera refusé.",
    "custom-text": "Merci d’expliquer ici votre situation d'hébergement. Votre explication sera ajoutée à votre dossier",
    "warning-only-rent-receipt": "Les justificatifs de domicile (taxe d'habitation, facture EDF, facture de téléphone…) ne sont pas acceptés ici. Seules vos quittances (ou une attestation de bon paiement des loyers) permettent à un propriétaire  de s'assurer que vous payez bien vos loyers.",
    "warning-tax": "L'avis d'imposition n'est pas un document accepté ici."
  },
  "roommatesinformationhelp": {
    "paragraph1": "En renseignant l’adresse email de votre colocataire, celui-ci va pouvoir constituer son propre dossier avec ses pièces. Vos deux dossiers seront liés et votre lien-dossier sera commun.",
    "paragraph2": "Concrètement, votre colocataire recevra une invitation qu’il ou elle devra valider dans les 48 heures.",
    "paragraph3": "Dans le cas ou votre colocataire n’a pas pu confirmer son inscription dans les 48 heures, vous devrez alors recommencer !"
  },
  "roommatesinformation": {
    "roommateEmail": "Ajouter l’adresse email d'un colocataire",
    "acceptAuthor": "<div>J’accepte que les autres membres de ma colocation aient accès à mes documents ainsi qu’à ceux de mon garant le cas échéant une fois que tous les dossiers de la colocation auront été validés.<br/>Je consens aussi au fait que les autres membres de ma colocation puissent partager les pièces de mon dossier à des propriétaires, des bailleurs ou des services immobiliers partenaires de DossierFacile.<span style='color: red'> *</span></div>",
    "delete": "Supprimer",
    "field-required": "Ce champ est requis",
    "title": "Qui seront vos colocataires ?",
    "add-a-roommate": "Inviter ce colocataire",
    "invite-waiting": "Invitation en attente d'envoi",
    "invite-sent": "Invitation envoyée",
    "my-roommates": "Mes colocataires",
    "email-exists": "Vous ne pouvez pas associer deux comptes à une même adresse email ! <br>Renseignez une adresse email différente de la votre.",
    "email-exists-2": "Cette adresse email est déjà utilisée sur DossierFacile.<br>Renseignez une adresse email différente.",
    "more-information": "En quoi cette information nous est utile ?",
    "require-accept": "Vous devez accepter la déclaration"
  },
  "sharefile": {
    "title": "Partager votre dossier",
    "desc1": "Saisissez l’adresse e-mail du destinataire ci-dessous pour partager votre dossier. Chaque destinataire reçoit un lien unique que vous pouvez désactiver lorsque vous le souhaitez.",
    "desc2": "Si vous ne disposez pas de l’adresse e-mail de votre destinataire, vous pouvez envoyer <a href=\"{0}\">le lien de votre dossier</a> par messagerie ou par SMS par exemple.",
    "desc3": "Rappel : DossierFacile ne propose pas de logement.",
    "lastvisit": "Dernière visite :",
    "share-btn-email": "Partager",
    "share-btn-copy": "Copier",
    "bytype-label": "Partager votre dossier",
    "full": "Avec justificatifs",
    "resume": "Sans justificatif",
    "by-mail": "Par mail",
    "by-link": "Par lien",
    "email-label": "Email du bailleur :",
    "email-placeholder": "bailleur{'@'}exemple.com",
    "sent-success": "Votre dossier a bien été partagé",
    "view": {
      "title": "Voir votre dossier",
      "text": "Vous souhaitez voir votre dossier avant de le partager ?",
      "button": "Voir votre dossier"
    }
  },

  "showdoc": {
    "preview": "Prévisualisation du document en cours…",
    "error": "Erreur de prévisualisation",
    "number-of-pages": "Prévisualisation page 1 / {0}",
    "loading": "Chargement du fichier en cours"
  },
  "signuppage": {
    "connect": "Déjà inscrit ? Se connecter",
    "mail-sent": "Un mail vous a été envoyé à l'adresse indiquée.",
    "clic-to-confirm": "Veuillez cliquer sur le lien envoyé afin de confirmer votre adresse mail et poursuivre votre inscription.",
    "duplicate-email": "Cet email est déjà utilisé",
    "register-error": "Une erreur est survenue",
    "my-file": "Mon dossier de location avec ",
    "my-file-subtitle": "Vous êtes sur le point de compléter votre dossier de location",
    "my-file-subtitle2": "vérifié par l'État !",
    "help-you": "vous aide :"
  },
  "simulationcaf": {
    "btn-caf": "Faire une simulation",
    "simulation-caf-title": "Êtes-vous éligible ?",
    "simulation-caf-text": "Découvrez l'estimation de l'aide au logement à laquelle vous pourriez être éligible."
  },
  "taxhelp": {
    "title": "Aide",
    "paragraph1": "Vous pouvez désormais <b>télécharger un avis d’imposition sur <a target='_blank' href='https://www.impots.gouv.fr'>www.impots.gouv.fr</a></b>",
    "paragraph2": "Si votre pièce est trop lourde, nous vous conseillons de scanner votre pièce (avec une application gratuite pour smartphone par exemple).<br> Si cela ne suffit pas, contactez-nous par mail à l’adresse <a href='mailto:contact{'@'}dossierfacile.logement.gouv.fr'>contact{'@'}dossierfacile.logement.gouv.fr</a>, nous nous efforcerons de répondre au plus vite !"
  },
  "tax-page": {
    "accept-verification": "J'accepte que DossierFacile procède à une vérification automatisée de ma fiche d'imposition auprès des services des impôts",
    "custom-text": "Merci d’expliquer ici la raison pour laquelle vous ne pouvez pas fournir d’avis d’imposition. Votre explication sera ajoutée à votre dossier :",
    "files": "Documents",
    "register": "Enregistrer",
    "field-required": "Ce champ est requis",
    "will-delete-files": "Attention, un changement de situation entraînera la suppression de vos justificatifs. Vous devrez charger de nouveau les justificatifs correspondant à votre situation.",
    "title": "Votre avis d'imposition",
    "warning-no-accepted-doc": "<p><strong>L’avis de situation déclarative n’est pas un avis d’imposition, il sera donc systématiquement refusé.</strong></p><p>Regardez bien le titre du document que vous transmettez ! Il s’agit d’une <a href='https://aide.dossierfacile.logement.gouv.fr/fr/article/5-avis-dimposition-eg82wt/' target='_blank' rel='noopener'>erreur très courante</a>.</p>",
    "avis-detected": "Avis de situation déclarative détecté",
    "avis-text1": "Vous avez fourni un avis de situation déclarative (voir titre du document). Ce document n’est pas valide. Merci de le remplacer par votre avis d’imposition.",
    "avis-btn": "Déposer votre avis d’imposition",
    "avis-link-to-doc": "Besoin d'aide ? Consultez notre aide en ligne"
  },
  "tenantdocument": {},
  "tenantguarantorchoice": {
    "identification": "Pièce d’identité",
    "residency": "Justificatif de situation d'hébergement",
    "professional": "Justificatif de situation professionnelle",
    "financial": "Justificatif de ressources",
    "tax": "Avis d’imposition",
    "representative-identification": "Identité de la personne morale",
    "corporation-identification": "Identité du représentant de la personne morale",
    "guarantor": "Garant",
    "validate": "Valider",
    "will-delete-guarantor": "Voulez-vous vraiment changer le type de garant ?",
    "validate-file": "Étape suivante - Valider le dossier",
    "ask-guarantor": "Son garant est :",
    "optional-guarantor": "Ajouter un garant n’est pas obligatoire. Si votre conjoint n'a pas de garant, vous pouvez sélectionner <em>pas de garant</em> en bas de la page pour passer à l'étape suivante.",
    "two-guarantors-warning": "Si votre conjoint a 2 garants, ajoutez-les l'un après l'autre. <strong>Les documents de ses 2 garants ne doivent pas être mélangés</strong>.",
    "no-guarantor": "Votre conjoint n'a pas de garant",
    "type-required": "Veuillez sélectionner un choix",
    "visale-title": "Connaissez-vous Visale ?",
    "visale-text": "Visale est le garant de votre futur logement si vous avez entre 18 et 30 ans OU si vous êtes salariés de + de 30 ans (soumis à conditions).",
    "visale-btn": "Découvrir Visale"
  },
  "tenantguarantordocuments": {
    "identification": "Pièce d’identité",
    "residency": "Justificatif de situation d'hébergement",
    "professional": "Justificatif de situation professionnelle",
    "financial": "Justificatif de ressources",
    "tax": "Avis d’imposition",
    "guarantor": "Garant",
    "validate": "Valider",
    "will-delete-guarantor": "Voulez-vous vraiment changer le type de garant ?",
    "validate-file": "Étape suivante - Valider le dossier"
  },
  "tenantguarantordocumentspage": {},
  "tenantguarantorlist": {
    "my-guarantor": "Les garants de mon conjoint",
    "add-new-guarantor": "Ajouter un nouveau garant ?",
    "guarantor": {
      "NATURAL_PERSON": "Son garant",
      "LEGAL_PERSON": "Son entreprise garante",
      "ORGANISM": "Son organisme garant"
    },
    "EMPTY": "Absent",
    "TO_PROCESS": "En cours de traitement",
    "VALIDATED": "Vérifié",
    "DECLINED": "Modification demandée",
    "INCOMPLETE": "Non terminé",
    "remove-guarantor": "Êtes-vous sûr·e de vouloir supprimer ce garant ?"
  },
  "tenantguarantorname": {
    "lastname": "Nom",
    "firstname": "Prénom",
    "lastname-placeholder": "ex: Dupont",
    "firstname-placeholder": "ex: Jean",
    "field-required": "Ce champ est requis",
    "title": "Identité de son garant"
  },
  "tenantguarantorspage": {},
  "tenantinformationform": {
    "title": "Constituez-vous un dossier de location afin d'habiter :",
    "confirm": "Confirmer",
    "firstname": "Prénom du locataire",
    "lastname": "Nom du locataire",
    "zipcode": "Code postal",
    "tenantPresentation": "Le locataire sera {firstname} {lastname}. Vous désirez louer un logement :",
    "alone": "Seul",
    "couple": "En couple",
    "roommate": "En colocation",
    "remove-roommates": "Attention, cela aura pour effet de dissocier votre dossier de vos colocataires",
    "remove-couple": "Attention, cela aura pour effet de dissocier votre dossier de celui de votre conjoint·e",
    "error": "Une erreur est survenue",
    "acceptAuthorSpouse": "J’accepte que mon partenaire ait accès à mes documents ainsi qu’à ceux de mon garant le cas échéant une fois que nos deux dossiers auront été validés.<br/>Je consens aussi au fait que mon partenaire puisse partager les pièces de notre dossier à des propriétaires, des bailleurs ou des services immobiliers partenaires de DossierFacile.",
    "acceptAuthorCoTenant": "<div>J’accepte que les autres membres de ma colocation aient accès à mes documents ainsi qu’à ceux de mon garant le cas échéant une fois que tous les dossiers de la colocation auront été validés.<br/>Je consens aussi au fait que les autres membres de ma colocation puissent partager les pièces de mon dossier à des propriétaires, des bailleurs ou des services immobiliers partenaires de DossierFacile.<span style='color: red'> *</span></div>",
    "validate": "Valider",
    "roommates-saved": "Invitation envoyée à vos colocataires. Vos colocataires ont bien été\najoutés et une invitation de création de compte leur a été envoyée.",
    "couple-saved": "Votre conjoint·e a bien été ajouté·e.\nSi un e-mail a été fourni, une invitation de création de compte lui a été envoyée.",
    "email-exists": "Cette adresse email est déjà utilisée sur DossierFacile.\nRenseignez une adresse email différente.",
    "roommate-email-required": "Vous devez saisir l'adresse email d'au moins un colocataire.",
    "couple-email-required": "Vous devez saisir l'adresse email de votre conjoint·e",
    "will-delete-couple": "Cette action va supprimer le dossier de votre conjoint·e",
    "will-delete-roommates": "Cette action va supprimer la liaison avec le dossier de vos colocataires",
    "require-accept": "Vous devez accepter la déclaration"
  },
  "tenantpanel": {
    "title": "Bonjour {0}, votre dossier {1} !",
    "last-update": "Dernière mise à jour du dossier le {0}",
    "file-update-title": "Mise à jour de votre dossier",
    "file-update-text": "Vous avez mis à jour votre dossier, pour la dernière fois le {0}.<br> Afin qu'il reste convaincant, il est important de maintenir à jour vos justificatifs.",
    "update-file-btn": "Mettre à jour mes documents",
    "copy-link": "Copier mon lien dossier",
    "share-by-mail": "Partager par mail",
    "my-file": "Mon dossier de location",
    "my-files": "Les justificatifs de ",
    "clarification-title": "Mot du locataire",
    "identification": "Pièce d'identité",
    "residency": "Justificatif de situation d'hébergement",
    "professional": "Justificatif de situation professionnelle",
    "financial": "Justificatif de ressources",
    "tax": "Avis d’imposition",
    "TO_PROCESS": "est en cours de traitement",
    "VALIDATED": "est vérifié",
    "DECLINED": "nécessite une modification",
    "INCOMPLETE": "est non terminé",
    "delete": "Suppression de mon compte",
    "opinion": "Racontez-nous votre expérience DossierFacile.fr",
    "delete-account": "Supprimer mon compte",
    "share-file": "Partager votre dossier",
    "share-file-description": "Copiez votre lien-dossier pour le partager ! À vous de l'envoyer aux propriétaires ou bailleurs de votre choix (par mail, SMS, etc.)",
    "file-resume": "Partager votre dossier de synthèse <br>(sans pièce justificative)",
    "file-full": "Partager votre dossier complet<br>(avec pièces justificatives)",
    "copy": "Copier",
    "field-required": "Ce champ est requis",
    "CDI": "en CDI",
    "CDI_TRIAL": "en CDI (période d’essai)",
    "CDD": "en CDD",
    "ALTERNATION": "en alternance",
    "INTERNSHIP": "en stage",
    "STUDENT": "étudiant·e",
    "PUBLIC": "dans la fonction publique",
    "CTT": "en CTT (intérimaire)",
    "RETIRED": "retraite",
    "UNEMPLOYED": "au chômage",
    "INDEPENDENT": "indépendant",
    "OTHER": "Autre",
    "no-income": "ne pas avoir de revenu",
    "income": "avoir un revenu net mensuel de {0}€",
    "copied": "Copié !",
    "ALONE": "seul·e",
    "couple-with": "en couple avec {0}",
    "group-with": "en colocation avec {0}",
    "group-with-someone": "en colocation",
    "someone": " quelqu'un",
    "spouse-cannot-copy-link": "Votre lien est inactif car le dossier de votre conjoint·e n'est pas encore validé",
    "cotenant-cannot-copy-link": "Votre lien est inactif car le dossier de votre(vos) colocataire(s) n'est pas encore validé",
    "amendment-required-title": "Modifications demandées",
    "amendment-required-text": "Après examen de votre dossier, des modifications vous sont demandées. <br>Consultez votre messagerie pour en connaître le détail.",
    "messaging": "Consulter ma messagerie",
    "congratulations-title": "🎉 Félicitations ! Votre DossierFacile est disponible !",
    "congratulations-text-1": "Afin de candidater au logement de vos rêves, partagez votre dossier à un propriétaire de votre choix, avec ou sans justificatifs, soit par email en renseignant son adresse mail dans la section \"Partager mon dossier\" ci-dessous, soit par lien en envoyant votre lien DossierFacile par email, SMS, etc. aux propriétaires, bailleurs de votre choix.",
    "congratulations-text-2": "Pour rappel, DossierFacile ne propose pas de logement.",
    "full-link-copied": "Le lien de mon dossier complet est copié !",
    "public-link-copied": "Le lien de mon dossier de synthèse est copié !"
  },
  "lefteditmenu": {
    "ALONE": "Seul",
    "COUPLE": "En couple",
    "GROUP": "En colocation",
    "identity": "Identité",
    "file-type": "Type de dossier"
  },
  "topeditmenu": {
    "i-add": "J'ajoute {0}",
    "my-guarantor": "Mon garant"
  },
  "updatecomponent": {
    "invalid": "Document invalide",
    "force-message": "Vous pensez que la vérification automatique commet une erreur. Apportez les précisions qui permettront la clarification de votre situation"
  },
  "uploaddocuments": {
    "accept-warning": "Ajouter de nouveaux documents",
    "ignore-warning": "Passer à l'étape suivante",
    "warning-need-residency-documents-tenant": "<p>Êtes-vous sûr·e d'avoir bien transmis vos 3 dernières quittances (ou une attestation de bon paiement des loyers) ?<br>Une quittance indique à un propriétaire que vous payez bien votre loyer. <strong>Un document qui ne mentionne que votre adresse (une facture EDF par exemple) ne suffit pas ici.</strong></p><p>Il s'agit d'une erreur très fréquente, et votre dossier ne pourra pas être validé sans ces 3 quittances.</p>"
  },
  "validatefile": {
    "title": "Je valide mon dossier",
    "read-no-guarantor": "Je lis et je coche la case suivante afin de valider mon dossier.",
    "declaration": "Je déclare avoir pris connaissance de l'article <a href='https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000006418753' target='_blank' rel='noopener'>441-1 du code pénal</a> qui punit le faux et l'usage de faux de trois ans d'emprisonnement et de 45000 euros d'amende.",
    "precision": "Si je le souhaite, je peux préciser certains éléments importants de ma situation à mes futurs propriétaires. Mon texte sera ajouté au début de mon dossier :",
    "placeholder": "Renseignez votre commentaire ici",
    "validate": "Valider mon dossier",
    "read": "Je lis et je coche les cases suivantes afin de valider mon dossier.",
    "declaration2": "Je déclare avoir obtenu le consentement des personnes physiques (conjoint, colocataires ou garants) à utiliser leurs justificatifs nominatifs pour les lier à mon dossier.",
    "declaration2-plural": "Je déclare sur l'honneur avoir reçu le consentement de mes garants pour que leurs données soient traitées dans le cadre du processus de location",
    "require-accept": "Vous devez accepter la déclaration",
    "validation-error-title": "Vous y êtes presque !",
    "validation-error-description": "Limitez les modifications, pensez à vérifier votre dossier dès aujourd'hui."
  },
  "modal": {
    "close": "Fermer",
    "close-alt": "Fermer la fenêtre modale"
  },
  "sharing-page": {
    "title": "Vos candidatures",
    "file-not-ready": {
      "title": "Votre dossier {0}",
      "status": {
        "TO_PROCESS": "est en cours de traitement",
        "DECLINED": "nécessite des modifications",
        "INCOMPLETE": "est incomplet",
        "VALIDATED": "global n'est pas validé"
      },
      "explanation": "Lorsque celui-ci sera validé, vous pourrez le partager et vous retrouverez ci-dessous la liste de vos partages par mail."
    },
    "shared-links": {
      "title": "Dossiers partagés par mail",
      "date": "Date",
      "contact": "Contact",
      "last-visit": "Dernière visite",
      "application-type": "Type de dossier",
      "link-status": "Statut",
      "action": "Action",
      "enabled": "Activé",
      "disabled": "Desactivé",
      "delete": "Supprimer",
      "never": "Jamais",
      "no-shared-file": "Aucun dossier partagé pour l'instant",
      "resend": "Renvoyer",
      "resend-success": "Envoi réussi.",
      "resend-failed": "Echec de l'envoi. Veuillez ré-essayer plus tard"
    }
  },
  "partners-page": {
    "title": "Votre dossier partagé",
    "accesses": {
      "title": "Les partenaires ayant accès à votre dossier",
      "date": "Date",
      "since-date": "Depuis le ",
      "default-date": "Depuis 2023",
      "partner": "Partenaire",
      "action": "Action",
      "revoke": "Révoquer",
      "no-access": "Vous n'avez donné accès a aucun partenaire"
    },
    "revoke-modal": {
      "title": "Suppression de l’accès DossierFacile",
      "p1": "La suppression de l’accès du partenaire {partner} à votre DossierFacile sera immédiate. {partner} n’aura plus accès à vos documents, ni à vos données personnelles provenant de DossierFacile.",
      "p2": "Dans le cas ou vous souhaitez rétablir cet accès, rendez-vous sur le site internet de {partner} et suivez le parcours qui vous sera proposé.",
      "validate": "Confirmer la suppression",
      "cancel": "Annuler la suppression"
    }
  },
  "404": {
    "title": "Page non trouvée",
    "subtitle": "Erreur 404",
    "caption": "La page que vous cherchez est introuvable. Veuillez nous excuser pour la gêne occasionnée.",
    "content": {
      "line1": "Si vous avez tapé l'adresse web dans le navigateur, vérifiez qu'elle est correcte. La page n’est peut-être plus disponible.",
      "line2": "Dans ce cas, pour continuer votre visite vous pouvez consulter notre page d’accueil.",
      "line3": "Sinon contactez-nous pour que l’on puisse vous rediriger vers la bonne information."
    },
    "homepage": "Page d'accueil",
    "contact-us": "Contactez-nous"
  },
  "contact": {
    "title": "Comment pouvons-nous vous aider ?"
  },
  "declined-messages": {
    "header": "Message de notre équipe de validation :"
  }
}
